import React, { useState, useEffect } from "react";
import { getEventSignups } from "../../../api/signupApis";
import { exportToCSV } from "../../utils/exportToCSV";
import { exportToPDF } from "../../utils/exportToPDF";

const SignupList = ({ eventId }) => {
  const [signupList, setSignupList] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [columns, setColumns] = useState([
    "category",
    "item",
    "signup_user_name",
    "signup_user_email",
    "signup_from_time",
    "signup_to_time",
    "quantity_measure",
  ]);
  const [headers, setHeaders] = useState([
    "Category",
    "Item",
    "Volunteer Name",
    "Email",
    "From Time",
    "To Time",
    "Quantity",
  ]);

  // Function to fetch Signups from an API or database
  const fetchSignupReqs = async () => {
    let reqkey = {
      eventId: eventId,
    };
    setError(null);
    setMessage(null);
    if (!eventId) {
      setSignupList([]);
      setMessage("Please select an event to view RSVPs");
      return;
    }
    try {
      const { data } = await getEventSignups(reqkey);
      if (data.rowCount === 0 || !data.signupResp) {
        setSignupList([]);
        setMessage("No Signups found for this event");
        return;
      }
      // console.log(data.signupResp);
      setSignupList(data.signupResp);
    } catch (error) {
      // console.log(error)
      setError(error);
    }
  };

  // Fetch users when the component mounts
  useEffect(() => {
    fetchSignupReqs();
  }, [eventId]);

  return (
    <div className="card">
      <div className="card-header d-flex">
        Signup Report
        <div className="ms-auto">
          <i
            className="fa fa-file-pdf fa-lg app-icon-style me-3"
            title="Export List to pdf"
            aria-hidden="true"
            onClick={() =>
              exportToPDF(
                "Signup List",
                columns,
                headers,
                signupList,
                "Signup_List"
              )
            }
          ></i>
          <i
            className="fa fa-file-csv fa-lg app-icon-style"
            title="Export List to csv"
            aria-hidden="true"
            onClick={() =>
              exportToCSV(signupList, columns, headers, "Signup_List")
            }
          ></i>
        </div>
      </div>
      <div className="card-body">
        <div>
          {signupList && signupList.length > 0 ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" className="row-text-sm">
                    Category
                  </th>
                  <th scope="col" className="row-text-sm">
                    Item
                  </th>
                  <th scope="col" className="row-text-sm">
                    Volunteer Name
                  </th>
                  <th scope="col" className="row-text-sm">
                    Email
                  </th>
                  <th scope="col" className="row-text-sm">
                    From Time
                  </th>
                  <th scope="col" className="row-text-sm">
                    To Time
                  </th>
                  <th scope="col" className="row-text-sm">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                {signupList.map((item) => (
                  <tr key={item.signup_resp_id} className="app-fs-8">
                    <td>{item.category}</td>
                    <td>{item.item}</td>
                    <td>{item.first_name + " " + item.last_name}</td>
                    <td>{item.signup_user_email}</td>
                    <td>{item.signup_from_time}</td>
                    <td>{item.signup_to_time}</td>
                    <td>{item.quantity + " " + item.unit_measure}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>{message}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignupList;
