import { useState } from "react";
import { uploadImage } from "../../api/authApis";

const useImageResize = () => {
  const [resizedImage, setResizedImage] = useState(null);
  const [resizedFile, setResizedFile] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [resizeError, setResizeError] = useState("");

  const resizeImage = (file, maxWidth = 1344, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      const objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = () => {
        URL.revokeObjectURL(objectUrl); // Avoid memory leak
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        // Calculate the aspect ratio and adjust width and height within constraints
        const aspectRatio = width / height;
        // console.log("Before", width, height, aspectRatio, maxWidth, maxHeight);

        if (width > maxWidth) {
          width = maxWidth;
          height = Math.round(width / aspectRatio);
        }
        if (maxHeight && height > maxHeight) {
          height = maxHeight;
          width = Math.round(height * aspectRatio);
        }

        // console.log("After", width, height, aspectRatio);

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL("image/jpeg"));
      };
      img.onerror = () => {
        URL.revokeObjectURL(objectUrl); // Clean up on error
        reject(new Error("Failed to load image for resizing"));
      };
    });
  };

  const getResizedImage = async (file, filename, maxWidth, maxHeight) => {
    try {
      const resizedDataURL = await resizeImage(file, maxWidth, maxHeight);
      setResizedImage(resizedDataURL);
      // const newFile = dataURItoBlob(resizedDataURL, filename);
      // setResizedFile(newFile);
    } catch (error) {
      setResizeError(`Error resizing image: ${error}`);
      console.error(error);
    }
  };

  const uploadResizedImage = async (file, filename, maxWidth, maxHeight) => {
    // console.log("uploadResizedImage", filename, maxWidth, maxHeight);
    try {
      // const resizedDataURL = await resizeImage(file, maxWidth, maxHeight);
      // setResizedImage(resizedDataURL);

      const formData = new FormData();
      // formData.append("image", dataURItoBlob(resizedDataURL, filename));
      formData.append("image", dataURItoBlob(file, filename));
      const response = await uploadImage(formData);
      if (response.status === 200) {
        return true; // Upload was successful
      } else {
        const errorMessage = `Failed to upload image: ${response.statusText}`;
        setUploadError(errorMessage);
        return false; // Explicitly return false on failure
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploadError(`Error uploading image: ${error}`);
      return false; // Explicitly return false on error
    }
  };

  const dataURItoBlob = (dataURI, filename) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return filename ? new File([blob], filename, { type: mimeString }) : blob;
  };

  return {
    resizedImage,
    uploadError,
    resizeError,
    getResizedImage,
    uploadResizedImage,
  };
};

export default useImageResize;
