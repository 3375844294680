import { useState } from "react";
import Layout from "../components/layout/layout";
import { addContactUs } from "../api/authApis";
import { validateContactUs } from "../components/helpers/validateContactUs";

const initializeForm = {
  name: "",
  email: "",
  category: "",
  message: "",
};

const ContactUs = () => {
  const [values, setValues] = useState(initializeForm);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(null);
    try {
      const errors = validateContactUs(values);
      // console.log(errors)
      if (Object.keys(errors).length === 0) {
        const response = await addContactUs(values);
        setStatus(
          "Thank you for contacting Us. We will get back you as soon as possible!"
        );
        setValues(initializeForm);
      } else {
        setErrors(errors);
      }
    } catch (error) {
      console.log(error.message);
      setError(error.response.data.message || error.message);
    }
  };

  // Display error state
  if (error) {
    return <div>Oops Something went wrong! We're on it.</div>;
  }

  return (
    <Layout>
      <div className="contact-us">
        <form onSubmit={handleSubmit}>
          <div className="h2">Contact Us</div>
          <div className="row contact-us-wrapper">
            <div className="col-md-12 mb-2">
              <label htmlFor="name" className="form-label fl-fs-9">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="text"
                id="name"
                name="name"
                value={values.name}
                className="form-control form-input fc-fs-8"
                maxLength="50"
                placeholder="Full Name"
                required
              />
              {errors?.name && (
                <div className="app-text-error-xs">{errors?.name}</div>
              )}
            </div>
            <div className="col-md-12 mb-2">
              <label htmlFor="email" className="form-label fl-fs-9">
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="text"
                id="email"
                name="email"
                value={values.email}
                className="form-control form-input fc-fs-8"
                maxLength="50"
                placeholder="Email"
                required
              />
              {errors?.email && (
                <div className="app-text-error-xs">{errors?.email}</div>
              )}
            </div>
            <div className="col-md-12 mb-2">
              <label htmlFor="category" className="form-label fl-fs-9">
                Category<span style={{ color: "red" }}>*</span>
              </label>
              <select
                onChange={(e) => onChange(e)}
                value={values.category}
                className="form-select form-input fc-fs-8"
                id="category"
                name="category"
                defaultValue=""
                aria-label="Default select"
                required
              >
                <option value="" disabled>
                  Select Category
                </option>
                <option value="General Question">General Question</option>
                <option value="Feedback">Feedback</option>
                <option value="Feature Request">Feature Request</option>
                <option value="Error">Error</option>
              </select>
              {errors?.category && (
                <div className="app-text-error-xs">{errors?.category}</div>
              )}
            </div>
            <div className="col-md-12 mb-2">
              <label htmlFor="message" className="form-label fl-fs-9">
                Message<span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                onChange={(e) => onChange(e)}
                rows="3"
                type="text"
                id="message"
                name="message"
                value={values.message}
                className="form-control form-input fc-fs-8"
                required
              />
              {errors?.message && (
                <div className="app-text-error-xs">{errors?.message}</div>
              )}
            </div>
          </div>
          <div className="col-md-12 text-center mb-2 action-buttons">
            <div className="app-fs-8 error-message">{error}</div>
            <div className="app-fs-8 error-message">{status}</div>
            <button className="btn app-btn-primary w-100 mt-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ContactUs;
