// resize and convert image into base64 
export default function convertToBase64(file, options = { minWidth: 200, minHeight: 200 }) {
  return new Promise((resolve, reject) => {

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      const img = new Image();
      img.src = fileReader.result;

      img.onload = () => {
        // Create a temporary canvas to draw the resized image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // console.log(options.minWidth)
        // Set the desired width and height for the resized image
        const maxWidth = img.width > options.minWidth ? options.minWidth : img.width;

        let aspect_ratio = img.width / img.height;
        // Calculate the new width and height while maintaining the aspect ratio
        let newWidth = maxWidth;
        let newHeight = Math.round(maxWidth / aspect_ratio);

        // Set the canvas size to the new dimensions
        canvas.width = newWidth;
        canvas.height = newHeight;
        // console.log(newWidth, newHeight)

        // Draw the resized image on the canvas
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Get the resized image as a data URL
        // const resizedDataURL = canvas.toDataURL(file.type, 0.9);
        const resizedDataURL = canvas.toDataURL("image/jpeg", 0.9);

        resolve(resizedDataURL)
      }
    }
    fileReader.onerror = () => {
      reject(fileReader.error)
    }
  })
}