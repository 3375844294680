import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row g-4">
          <div className="col-12 col-md-4 col-lg-6">
            {/* <!-- Brand --> */}
            <img
              src="../assets/images/logo/logo5.png"
              style={{ width: "100px" }}
              alt="..."
              className="footer-brand img-fluid"
            />
            {/* <!-- Text --> */}
            <p className="text-gray-700">A better way to manage your events</p>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            {/* <!-- Heading --> */}
            <span className="h6 fw-bold text-uppercase text-gray-700">
              Contact
            </span>

            {/* <!-- List --> */}
            <ul className="list-unstyled text-body-secondary mb-4 mb-md-8 mb-lg-0">
              <li className="mb-1">
                <Link to="/aboutUs" className="text-reset">
                  About Us
                </Link>
              </li>
              <li className="mb-1">
                <Link to="/contactUs" className="text-reset">
                  Contact Us
                </Link>
              </li>
              <li className="mb-1">
                <Link to="/faq" className="text-reset">
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0">
            {/* <!-- Heading --> */}
            <span className="h6 fw-bold text-uppercase text-gray-700">
              Connect
            </span>

            {/* <!-- List --> */}
            <ul className="list-unstyled text-body-secondary mb-0">
              <li className="mb-1">
                <a
                  href="https://twitter.com/signupqr"
                  className="text-decoration-none"
                >
                  <i
                    className="bi bi-twitter-x"
                    style={{ fontSize: "0.9rem" }}
                  ></i>
                </a>
                <span className="ms-2">Twitter</span>
              </li>
              <li className="mb-1">
                <a
                  href="https://www.facebook.com/profile.php?id=61557898805423"
                  className="text-decoration-none"
                >
                  <i
                    className="bi bi-facebook"
                    style={{ fontSize: "0.9rem" }}
                  ></i>
                </a>
                <span className="ms-2">facebook</span>
              </li>
              <li className="mb-1">
                <a
                  href="https://www.instagram.com/signupqr"
                  className="text-decoration-none"
                >
                  <i
                    className="bi bi-instagram"
                    style={{ fontSize: "0.9rem" }}
                  ></i>
                </a>
                <span className="ms-2">instagram</span>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            {/* <!-- Heading --> */}
            <span className="h6 fw-bold text-uppercase text-gray-700">
              Legal
            </span>

            {/* <!-- List --> */}
            <ul className="list-unstyled text-body-secondary mb-0">
              <li className="mb-1">
                <Link to="/privacy" className="text-reset">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-1">
                <Link to="/terms" className="text-reset">
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </footer>
  );
};

export default Footer;
