import jsPDF from "jspdf";
import "jspdf-autotable";

export const exportToPDF = (
  header,
  tableColumns,
  tableHeaders,
  tableData,
  filename_prefix
) => {
  const doc = new jsPDF();

  // Header
  doc.text(header, 20, 10);

  // Generate a unique filename for the CSV file
  const filename = `${filename_prefix}_${new Date().getTime()}.pdf`;

  // Table
  const tableRows = tableData.map((item) =>
    tableColumns.map((column) => item[column])
  );
  doc.autoTable({
    head: [tableHeaders],
    body: tableRows,
  });

  // Save the PDF
  doc.save(filename);
};
