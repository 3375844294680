export const getUTCDate = (dateString) => {
  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(5, 7)) - 1; // Adjust for 0-based month index
  const day = parseInt(dateString.substring(8));

  return new Date(year, month, day);
};

export const formatCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDate = (dateString) => {
  // console.log("dateString: ", dateString);
  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(5, 7)) - 1; // Adjust for 0-based month index
  const day = parseInt(dateString.substring(8));

  const utcDate = new Date(year, month, day);

  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return utcDate.toLocaleString("en-US", options);
  // return localDate.toISOString().split("T")[0];
};

export const formatTime = (timeStr) => {
  if (!timeStr) {
    return "";
  }
  if (timeStr === "00:00:00") {
    return "12:00 AM";
  }
  // Extract hours and minutes from the input string
  const [hours, minutes] = timeStr.split(":");

  // Convert hours to 12-hour format and determine AM/PM
  const hours12 = hours % 12 || 12; // Handle midnight (00:00)
  const amPm = hours >= 12 ? "PM" : "AM";

  // Format the output string with leading zero for minutes
  return `${hours12}:${minutes.padStart(2, "0")} ${amPm}`;
};

export const convertTime = (str) => {
  const hrs = str.split(":")[0];
  const mins = str.split(":")[1];
  const formattedTime =
    hrs > 12 ? hrs - 12 + ":" + mins + " PM" : hrs + ":" + mins + " AM";
  return formattedTime;
};

export const formatDateyyyymmdd = (d) => {
  const givenDate = new Date(d) || new Date();

  var year = givenDate.getUTCFullYear().toString();
  var month = (givenDate.getUTCMonth() + 1).toString().padStart(2, "0");
  var day = givenDate.getUTCDate().toString().padStart(2, "0");

  // const year = givenDate.getFullYear();
  // const month = String(givenDate.getMonth() + 1).padStart(2, "0");
  // const day = String(givenDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDatemmdd = (d) => {
  const givenDate = new Date(d) || new Date();
  // const month = String(givenDate.getMonth() + 1).padStart(2, '0');
  // const day = String(givenDate.getDate()).padStart(2, '0');

  var month = (givenDate.getUTCMonth() + 1).toString().padStart(2, "0");
  var day = givenDate.getUTCDate().toString().padStart(2, "0");

  // const month = String(givenDate.getMonth() + 1).padStart(2, "0");
  // const day = String(givenDate.getDate()).padStart(2, "0");
  const formattedDate = `${month}/${day}`;
  return formattedDate;
};

export const showDateTime = (dateTimeString) => {
  // Create a Date object from the input string
  const dateObject = new Date(dateTimeString);

  // Get the year, month, day, hours, minutes, and seconds
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(dateObject.getDate()).padStart(2, "0");
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  // Optionally, you can add leading zeros to the time components
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  // Add AM/PM based on 12-hour format
  const ampm = hours >= 12 ? "PM" : "AM";
  const twelveHourFormat = hours % 12 || 12;

  // Create the human-readable date and time string
  const formattedDateTime = `${year}-${month}-${day} ${twelveHourFormat}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

  return formattedDateTime;
};
