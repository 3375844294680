import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  formatDate,
  formatTime,
} from "../../../components/helpers/formatDateTime";
import { useDispatch, useSelector } from "react-redux";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../../redux/slices/dashboardSlice";
import {
  getEventSignups,
  getEventSignupSummary,
  getEventSponsors,
} from "../../../api/signupApis";
import ChatModal from "../../chat/chatModal";
import jsPDF from "jspdf";
import { exportToCSV } from "../../utils/exportToCSV";

// Extracted SignupCard component
const EventCard = ({ event, handleActionClick }) => {
  const {
    id,
    event_name,
    from_date,
    to_date,
    from_time,
    to_time,
    organized_by,
    location,
  } = event;
  return (
    <div className="card shadow mb-2" key={id}>
      <div className="card-body">
        <Link
          className="card-subheader app-link-fs-10"
          onClick={() => handleActionClick(event, "eventDetail")}
        >
          <span>{event_name}</span>
        </Link>
        <div>
          <span className="fw-bold app-fs-9 me-1">Date & Time: </span>
          <span className="app-fs-9 me-1">
            {event?.from_date === event?.to_date
              ? formatDate(event?.from_date)
              : formatDate(event?.from_date) +
                " - " +
                formatDate(event?.to_date)}
          </span>
          &nbsp;{" "}
          <span className="app-fs-9">
            {formatTime(event?.from_time) + " - " + formatTime(event?.to_time)}
          </span>
        </div>
        <div>
          <span className="fw-bold app-fs-9 mr-2">Organized by: </span>
          <span className="app-fs-9">{event?.organized_by} </span>
        </div>
        <div className="mb-2">
          <span className="fw-bold app-fs-9 mr-2">Location: </span>
          <span className="app-fs-9">{event?.location} </span>
        </div>
      </div>
    </div>
  );
};

// SignupCard component
const SignupSummaryCard = ({ signupSummary }) => {
  const groupedByCategory = signupSummary.reduce((acc, item) => {
    // If the category hasn't been seen before, initialize it.
    if (!acc[item.category]) {
      acc[item.category] = [];
    }

    // Add the item to its category group.
    acc[item.category].push(item);

    return acc;
  }, {});

  return (
    <div className="card shadow mb-2" key={signupSummary.event_id}>
      <div className="card-header">Signup Summary</div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-hover table-sm">
            <thead>
              <tr>
                <th className="app-fs-9">Item</th>
                <th className="app-fs-9">Quantity Required</th>
                <th className="app-fs-9">Quantity Taken</th>
                <th className="app-fs-9">Quantity Remaining</th>
              </tr>
            </thead>
            <tbody>
              {/* {signupSummary &&
                signupSummary.map((summary) => ( */}
              {Object.entries(groupedByCategory).map(
                ([category, items], index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td colSpan="4">
                        <span className="app-fs-7 fw-bold app-text-secondary">
                          {category}
                        </span>
                      </td>
                    </tr>
                    {items.map((summary) => (
                      <tr key={summary.req_id}>
                        <td className="app-fs-7">{summary.item}</td>
                        <td className="app-fs-7">
                          {summary.quantity} {summary.unit_measure}
                        </td>
                        <td className="app-fs-7">
                          {summary.quantity_taken} {summary.unit_measure}
                        </td>
                        <td className="app-fs-7">
                          {summary.quantity_remaining < 0
                            ? 0
                            : summary.quantity_remaining}{" "}
                          {summary.unit_measure}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// SignupCard component
const SignupCard = ({
  userEmail,
  event,
  statusMsg,
  setStatusMsg,
  openModalId,
  openModal,
  closeModal,
  signupRespTable,
  columns,
  headers,
}) => (
  <div className="card shadow mb-2" key={event["id"]}>
    <div className="d-flex card-header justify-content-between align-items-center">
      <div>Volunteer Signups for {event["event_name"]} </div>
      <div className="ms-auto">
        <i
          className="fa fa-file-pdf fa-lg app-icon-style me-3"
          title="Export List to pdf"
          aria-hidden="true"
          onClick={() => downloadPdf(signupRespTable)}
        ></i>
        <i
          className="fa fa-file-csv fa-lg app-icon-style"
          title="Export List to csv"
          aria-hidden="true"
          onClick={() =>
            exportToCSV(signupRespTable, columns, headers, "Signup_List")
          }
        ></i>
      </div>
    </div>
    <div className="card-body">
      {Object.values(event["signups"]).map((signup) => (
        <div key={signup["user"]}>
          <h6
            className={`app-fs-9 ${
              userEmail === signup["user"] ? "text-success" : ""
            }`}
          >
            {userEmail === signup["user"] ? (
              "You have signed up for the following items"
            ) : (
              <>
                <strong>{signup["first_name"]}</strong> has signed up for the
                following items
              </>
            )}
          </h6>
          {/* <strong>{signup["user"]}</strong> has signed up for the following items!</h6> */}

          {signup["Items"].map((item) => {
            if (item.category === "Volunteers") {
              return (
                <div key={item.signup_resp_id} className="align-items-center">
                  <ul>
                    <li className="app-fs-9 me-2">
                      {item.item}:&nbsp; {item.quantity} {item.unit_measure} -{" "}
                      {formatTime(item.signup_from_time)} -{" "}
                      {formatTime(item.signup_to_time)}
                    </li>
                  </ul>
                </div>
              );
            } else {
              return (
                <div key={item.signup_resp_id} className="align-items-center">
                  <ul className="list-styled">
                    <li
                      className={`app-fs-9 ${
                        item.quantity === 0 ? "strikethrough" : ""
                      }`}
                    >
                      {item.item}:&nbsp;{item.quantity} {item.unit_measure}
                    </li>
                  </ul>
                  {/* <div className={`app-fs-9 me-2 ${item.quantity === 0 ? 'strikethrough' : ''}`}>{item.item}:</div>
                <div className={`app-fs-9 me-2 ${item.quantity === 0 ? 'strikethrough' : ''}`}>{item.quantity} {item.unit_measure}</div> */}
                </div>
              );
            }
          })}
          <div
            className={`action-buttons ${
              userEmail === signup["user"] ? "hideDiv" : ""
            }`}
          >
            <button
              className="btn app-btn-primary-xs"
              onClick={() => openModal(signup["signup_user_id"])}
            >
              Message Volunteer
            </button>
            <ChatModal
              setStatusMsg={setStatusMsg}
              receiver_user_id={signup["signup_user_id"]}
              receiver_email={signup["user"]}
              receiver_firstname={signup["first_name"]}
              isOpen={openModalId === signup["signup_user_id"]}
              // isOpen={modalIsOpen}
              onClose={closeModal}
            />
            <div className="text-success app-fs-9 mt-1" role="alert">
              {statusMsg}
            </div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  </div>
);

// SignupCard component
const EventSponsorsCard = ({ eventSponsors }) => {
  return (
    <div className="card shadow mb-2" key={eventSponsors.id}>
      <div className="card-header">Event Sponsors</div>
      <div className="card-body">
        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th className="app-fs-9">Email</th>
              <th className="app-fs-9">Sponsor Name</th>
              <th className="app-fs-9">Sponsorship Level</th>
              <th className="app-fs-9">Amount</th>
            </tr>
          </thead>
          <tbody>
            {eventSponsors &&
              eventSponsors.map((sponsors) => (
                <tr key={sponsors.id}>
                  <td className="app-fs-7">{sponsors.email}</td>
                  <td className="app-fs-7">
                    {sponsors.first_name} {sponsors.last_name}
                  </td>
                  <td className="app-fs-7">{sponsors.sponsorship_level}</td>
                  <td className="app-fs-7">${sponsors.sponsorship_amt}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const downloadPdf = (signupRespTable) => {
  const doc = new jsPDF();
  const header = "Signup List for " + signupRespTable[0].event_name;
  doc.text(header, 15, 10);

  // Sort by Volunteer Name
  signupRespTable.sort(
    (a, b) =>
      a["item"].localeCompare(b["item"]) ||
      a["signup_user_name"].localeCompare(b["signup_user_name"])
  );

  // console.log(signupRespTable);
  // Group data by category
  const groupedByCategory = signupRespTable.reduce((acc, item) => {
    acc[item.category] = acc[item.category] || [];
    acc[item.category].push(item);
    return acc;
  }, {});

  const tableHeader = [
    "Category - Item",
    "Volunteer Name",
    "Email",
    "Quantity",
  ];

  // Prepare data for autoTable
  const tableData = [];
  Object.keys(groupedByCategory).forEach((category) => {
    // Add a subheading row for each category
    tableData.push([
      {
        content: category,
        colSpan: 6,
        styles: { fontStyle: "bold", fillColor: [221, 221, 221] },
      },
    ]);
    // Add the actual data rows for this category
    groupedByCategory[category].forEach((item) => {
      tableData.push([
        item.item,
        item.signup_user_name,
        item.signup_user_email,
        item.quantity_measure,
      ]);
    });
  });

  doc.autoTable({
    head: [tableHeader],
    body: tableData,
    styles: { fontSize: 10 },
    headStyles: {
      fillColor: [100, 100, 255],
      textColor: 255,
      fontStyle: "bold",
    }, // Blue background, white text
  });

  doc.save("Signup_List.pdf");
};

const EventSignups = () => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.dashboard.item);
  const email = useSelector((state) => state.auth.email);
  const eventId = event.id;

  const [signupResp, setSignupResp] = useState([]);
  const [signupRespTable, setSignupRespTable] = useState([]);
  const [signupSummary, setSignupSummary] = useState([]);
  const [eventSponsors, setEventSponsors] = useState([]);
  const [error, setError] = useState(null);
  const [statusMsg, setStatusMsg] = useState(null); // success message for form submission
  // const [modalIsOpen, setModalIsOpen] = useState();
  const [openModalId, setOpenModalId] = useState(null);
  const [headers, setHeaders] = useState([
    // Headers for the CSV file
    "Category",
    "Item",
    "Name",
    "Email",
    "From Time",
    "To Time",
    "Quantity",
  ]);
  const [columns, setColumns] = useState([
    "category",
    "item",
    "signup_user_name",
    "signup_user_email",
    "signup_from_time",
    "signup_to_time",
    "quantity_measure",
  ]);

  // const openModal = () => {
  //   setModalIsOpen(true);
  // };

  // const closeModal = () => {
  //   setModalIsOpen(false);
  // };

  // Open button handler
  const openModal = (id) => {
    setOpenModalId(id);
  };

  // close button handler
  const closeModal = () => {
    setOpenModalId(null);
  };

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const activeComponentsDashboardNew = Object.keys(
    activeComponentsDashboard
  ).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});

  // Group the responses by user
  function groupResponsesByUser(respData) {
    const signupResp = respData.reduce((acc, item) => {
      const {
        id: eventId,
        event_name: eventName,
        event_user_id,
        category,
        event_date,
        organized_by,
        location,
        address_line1,
        address_line2,
        city,
        state,
        zip_code,
        from_date,
        to_date,
        from_time,
        to_time,
        signup_user_email,
        first_name,
        last_name,
        signup_user_id,
      } = item;

      if (!acc[eventId]) {
        acc[eventId] = {
          id: eventId,
          event_name: eventName,
          event_user_id: event_user_id,
          event_date: event_date,
          organized_by: organized_by,
          location: location,
          address_line1: address_line1,
          address_line2: address_line2,
          city: city,
          state: state,
          zip_code: zip_code,
          from_date: from_date,
          to_date: to_date,
          from_time: from_time,
          to_time: to_time,
          first_name: first_name,
          last_name: last_name,
          signups: {},
          // "Categories": {},
        };
      }

      if (!acc[eventId]["signups"][signup_user_email]) {
        acc[eventId]["signups"][signup_user_email] = {
          user: signup_user_email,
          first_name: first_name,
          event_id: eventId,
          event_user_id: event_user_id,
          signup_user_id: signup_user_id,
          Items: [],
        };
      }
      // if (!acc[eventId]["Categories"][category]) {
      //   acc[eventId]["Categories"][category] = {
      //     "Category": category,
      //     "Items": [],
      //   };
      // }
      // console.log(category, item.item, item.quantity, item.unit_measure)
      acc[eventId]["signups"][signup_user_email]["Items"].push(item);

      return acc;
    }, {});
    // console.log(Object.values(signupResp));
    return Object.values(signupResp);
  }

  // console.log(eventId)
  // Get all the suppSignup requests for the event
  const fetchSignupReqs = async () => {
    let reqkey = {
      eventId: eventId,
    };
    try {
      const { data } = await getEventSignups(reqkey);
      // console.log(data.signupResp);
      setSignupRespTable(data.signupResp);
      setSignupResp(groupResponsesByUser(data.signupResp));
      // console.log(groupResponsesByUser(data.signupResp)[0].signups['admin123@gmail.com'].Items[0])
      // console.log(groupResponsesByUser(data.signupResp));
    } catch (error) {
      // console.log(error)
      setError(error);
    }
  };

  const fetchSignupSummary = async () => {
    let reqkey = {
      eventId: eventId,
    };
    try {
      const { data } = await getEventSignupSummary(reqkey);
      setSignupSummary(data.signupSummary);
      // console.log(data.signupSummary);
    } catch (error) {
      setError(error);
    }
  };

  // Get all the suppSignup requests for the event
  const fetchEventSponsors = async () => {
    let reqkey = {
      eventId: eventId,
    };
    try {
      // console.log(reqkey)
      const { data } = await getEventSponsors(reqkey);
      setEventSponsors(data.eventSponsors);
      // console.log(data.eventSponsors)
    } catch (error) {
      // console.log(error)
      setError(error);
    }
  };

  useEffect(() => {
    fetchSignupReqs();
    fetchEventSponsors();
    fetchSignupSummary();
  }, [eventId]);

  const handleActionClick = useCallback(
    (item, component) => {
      dispatch(setItem(item));
      dispatch(
        setActiveComponentsDashboard({
          ...activeComponentsDashboardNew,
          [component]: true,
        })
      );
    },
    [dispatch, activeComponentsDashboardNew]
  );

  // Display error state
  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Oops! Something went wrong. We are working on fixing this!
      </div>
    );
  }

  return (
    <div className="row g-4">
      <div className="col-sm-12 col-xl-12">
        <EventCard event={event} handleActionClick={handleActionClick} />
        <SignupSummaryCard signupSummary={signupSummary} />
        {signupResp && signupResp.length === 0 ? (
          <div className="card shadow mb-2">
            <div className="card-header">Volunteer Signups</div>
            <div className="card-body">
              <div className="alert alert-info" role="alert">
                No volunteers have signed up for this event yet!
              </div>
            </div>
          </div>
        ) : (
          signupResp.map((event) => {
            return (
              <div key={event.id}>
                <SignupCard
                  userEmail={email}
                  event={event}
                  setStatusMsg={setStatusMsg}
                  statusMsg={statusMsg}
                  openModalId={openModalId}
                  closeModal={closeModal}
                  openModal={openModal}
                  signupRespTable={signupRespTable}
                  columns={columns}
                  headers={headers}
                />
              </div>
            );
          })
        )}
        {eventSponsors && eventSponsors.length === 0 ? (
          <div className="card shadow mb-2">
            <div className="card-header">Event Sponsors</div>
            <div className="card-body">
              <div className="alert alert-info" role="alert">
                No Sponsors have signed up for this event yet!
              </div>
            </div>
          </div>
        ) : (
          <EventSponsorsCard eventSponsors={eventSponsors} />
        )}
      </div>
    </div>
  );
};

export default EventSignups;
