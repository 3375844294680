import avatar from "../../assets/images/avatars/profile.png";
import { useState, useEffect } from "react";
import { profileValidate } from "../helpers/validate";
import convertToBase64 from "../helpers/convert";
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenticated } from "../../redux/slices/authSlice";
import { getUserProfile, updateProfileAvatar } from "../../api/authApis";
import { setActiveComponentsDashboard } from "../../redux/slices/dashboardSlice";

const initializeFormData = {
  profile: '',
  about: '',
  email: ''
}

const UpdateAvatar = () => {
  const dispatch = useDispatch()
  const activeComponentsDashboard = useSelector((state) => state.dashboard.activeComponentsDashboard);

  const [user, setUser] = useState();
  const [file, setFile] = useState();
  const [error, setError] = useState(false);

  // Make all the components active = false
  const activeComponentsDashboardNew = Object.assign({},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({ [k]: false }))
  );


  const [values, setValues] = useState(initializeFormData);

  const fetchProfile = async () => {
    try {
      const { data, status } = await getUserProfile()
      setUser(data.user)
      setValues({
        profile: data.user?.profile,
        about: data.user?.about || '',
        email: data.user?.email,
      })
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('isAuth')
        dispatch(setAuthenticated(false));
      }
      console.log(error.response.status)
    }
  }

  useEffect(() => {
    fetchProfile();
  }, [])

  const userProfile = () => {
    dispatch(setActiveComponentsDashboard({ ...activeComponentsDashboardNew, "profile": true }));
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(values)
  };


  const onUpload = async (e) => {
    try {
      setError('');
      const selectedFile = e.target.files[0];
      if (selectedFile.size > 1048576) {
        setError("File size exceeds the maximum allowed limit (1 MB).");
        return;
      }
      const options = { minWidth: 200, minHeight: 200 }
      const base64 = await convertToBase64(selectedFile, options)
      setFile(base64)
      Object.assign(values, { profile: base64 || '' })
    } catch (error) {
      console.error(error);
    }
  }


  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateProfileAvatar(values)
      // console.log(response)
      setValues(initializeFormData)
      setError('');
      userProfile()
    } catch (error) {
      // console.log(error);
      setError(error.response.data.error);
    }
  };


  return (
    <div className="card">
      <div className="card-header">
        <h6 className="card-subtitle text-white">Profile Details</h6>
        <p className="mb-0 app-fs-7">Others will not be able to see your Personal and Address details!</p>
      </div>
      <form onSubmit={onSubmit} className="row g-3">
        <div className="card-body">
          <h6 className="mb-1 card-subtitle">Avatar</h6>
          <div className="row form-wrapper-gen w-75">
            <div className="mb-2 col-md-12 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="profile">
                  <img src={values.profile || avatar} className="rounded-circle profile-img mb-1" alt="avatar" />
                  <p className="mb-0 help-text-xs">Click on the Avatar to upload Picture.</p>
                  <p className="mb-0 help-text-xs">PNG or JPG no bigger than 800px wide and tall.</p>
                </label>
                <input onChange={onUpload} type="file" accept="image/*" size="1048576" id="profile" name="profile" />
              </div>
              <div className='app-fs-8 error-message'>{error}</div>
            </div>
            <div className="ms-2 col-lg-8 col-md-12 col-sm-12">
              <label className="form-label fl-fs-9" htmlFor="about">About</label>
              <textarea rows="2" cols="50" maxLength="100" onChange={(e) => onChange(e)} value={values.about} id="about" name="about" className="form-control form-input fc-fs-8"></textarea>
            </div>
            <div className="my-3 col-md-12 col-sm-12 action-buttons">
              <button type="submit" className="btn app-btn-primary-md">Update Avatar</button>
            </div>
          </div>
        </div>
      </form>
    </div>




  )
}

export default UpdateAvatar