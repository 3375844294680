import { createSlice } from "@reduxjs/toolkit";

const userAuthFromLocalStorage = () => {
  const isAuthenticated = localStorage.getItem("isAuth");
  if (isAuthenticated && JSON.parse(isAuthenticated) === true) {
    return true;
  }
  return false;
};

const initialState = {
  email: "",
  username: "",
  firstName: "",
  isAuthenticated: userAuthFromLocalStorage(),
  location: "",
  activeComponentsLogin: {
    login: true,
    passwordOTP: false,
    recoverPassword: false,
    resetPassword: false,
  },
  activeComponentsRegister: {
    register: true,
    resendOTP: false,
    verifyOTP: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
      // console.log(state.email)
    },
    setUsername: (state, action) => {
      state.username = action.payload;
      // console.log(state.username);
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
      // console.log(state.firstName);
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setActiveComponentsLogin: (state, action) => {
      state.activeComponentsLogin = action.payload;
    },
    setActiveComponentsRegister: (state, action) => {
      state.activeComponentsRegister = action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setEmail,
  setUsername,
  setFirstName,
  setAuthenticated,
  setLocation,
  setActiveComponentsLogin,
  setActiveComponentsRegister,
  reset,
} = authSlice.actions;

export default authSlice.reducer;
