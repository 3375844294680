import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSponsorshipReqs } from "../../api/authApis";
import { useNavigate } from "react-router-dom";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../redux/slices/dashboardSlice";

const SponsorshipReqs = ({ item, disableSignup }) => {
  const eventId = item.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sponsorshipReqs, setSponsorshipReqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const redirectToSignup = useCallback(() => {
    dispatch(setItem(item));
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        signup: true,
      })
    );
    navigate("/dashboard");
  }, []);

  // Get all the Sponsorship requests for the event
  const fetchSponsorshipReqs = async () => {
    // const eventId = eventId;
    let reqkey = {
      eventId: eventId,
    };
    try {
      const { data } = await getSponsorshipReqs(reqkey);
      setSponsorshipReqs(data.sponsorshipReqs);
    } catch (error) {
      console.log(error);
      setError(error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSponsorshipReqs().finally(() => setLoading(false));
  }, []);

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return <div>Oops Something went wrong! We're on it.</div>;
  }

  return (
    <>
      {sponsorshipReqs?.length > 0 && (
        <div className="card card-body shadow mt-2">
          <h5 className="card-title">
            <button
              className="btn btn-responsive w-100"
              onClick={redirectToSignup}
              disabled={disableSignup}
            >
              Sponsorship Requests
            </button>
          </h5>
          <div className="mt-1">
            <div className="app-fs-9 app-text-accent1">
              Sponsorship Opportunities
            </div>
            {sponsorshipReqs.map((req) => {
              return (
                <div key={req.id} className="row align-items-center mb-1">
                  <div className="col-8">
                    <small>{req.sponsorship_level}</small>
                  </div>
                  <div className="col-4">
                    <small>$ {req.amount} </small>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default SponsorshipReqs;
