import "../../assets/styles/login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateOTP } from "../../api/authApis";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setActiveComponentsLogin,
} from "../../redux/slices/authSlice";

const PasswordOTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = useSelector((state) => state.auth.email);
  const activeComponentsLogin = useSelector(
    (state) => state.auth.activeComponentsLogin
  );

  // Make all the components active = false
  let activeComponentsLoginNew = Object.assign(
    {},
    ...Object.entries(activeComponentsLogin).map(([k, v]) => ({ [k]: false }))
  );

  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [values, setValues] = useState({
    email: email,
    step: "recoverPassword",
  });

  const cancelRecoverPassword = () => {
    dispatch(
      setActiveComponentsLogin({ ...activeComponentsLoginNew, login: true })
    );
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data, status } = await generateOTP(values);
      // console.log(status);
      if (status === 201) {
        dispatch(setEmail(email));
        dispatch(
          setActiveComponentsLogin({
            ...activeComponentsLoginNew,
            recoverPassword: true,
          })
        );
      }
    } catch (error) {
      if (error.response.status === 404) {
        setStatus("Could not find the user. Please retry !");
      }
      if (error.response.status === 504) {
        setStatus(error.response.data.message);
      }
      if (error.response.data.error === "Unauthorized") {
        setStatus("Unauthorized");
      }
      setError(error.response.data.message);
      // console.log(error.response.status)
    }
  };

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <div className="title text-center mb-4">
          <h6>Please Enter the Registration Email</h6>
        </div>
        <form className="text-center">
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control form-input"
              onChange={(e) => onChange(e)}
              id="email"
              name="email"
              value={values.email}
              placeholder="Email"
              required
            />
          </div>
          <div className="app-fs-7 mb-2" style={{ color: "red" }}>
            {status}
          </div>
          <div className="mb-2 d-flex action-buttons">
            <button
              type="button"
              onClick={(e) => onSubmit(e)}
              className="btn app-btn-primary-md mx-2"
            >
              Recover Password
            </button>
            <button
              type="button"
              onClick={cancelRecoverPassword}
              className="btn app-btn-primary-md"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default PasswordOTP;
