// Validate Event Form - Step 1
export const validateContactUs = (values) => {
  const errors = validateName({}, values)
  validateEmail(errors, values)
  validateMessage(errors, values)
  return errors;
};

// validate name
function validateName(errors = {}, values) {
  if (!values.name) {
    errors.name = 'Name is required';
  } else if (values.name.length > 50) {
    errors.name = 'Event Name must be no more than 50 characters';
  }
  return errors;
}

// validate email
function validateEmail(error = {}, values) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!values.email) {
    error.email = "Email is required";
  } else if (values.email.includes(" ")) {
    error.email = "Invalid email";
  } else if (!emailPattern.test(values.email)) {
    error.email = "Email format is incorrect";
  }
  return error;
}

// validate message
function validateMessage(errors = {}, values) {
  if (!values.message) {
    errors.message = 'Message cannot be blank';
  } else if (values.message.length > 1000) {
    errors.message = 'Message has to be less than 1000 characters';
  }
  return errors;
}