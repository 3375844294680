import Layout from "../components/layout/layout";
import { useEffect, useState } from "react";
import "../assets/styles/events.css";
import EventCards from "../components/event/eventCards";
import {
  getEventCategories,
  getStatesList,
  searchEvents,
} from "../api/authApis";
import EventSearch from "../components/event/eventSearch";

const initializeFormData = {
  category_id: "0",
  state: "All",
  period: "All",
};

const EventsHome = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);
  const [error, setError] = useState(null);
  const [values, setValues] = useState(initializeFormData);
  const [eventCategories, setEventCategories] = useState([]);
  const [states, setStates] = useState([]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const fetchStatesList = async () => {
    // console.log('fetchStatesList')
    try {
      const { data } = await getStatesList();
      // console.log(data.states)
      setStates(data.states);
    } catch (error) {
      console.log(error);
      setError(error.message || error);
    }
  };

  const fetchEventCategories = async () => {
    try {
      const { data } = await getEventCategories();
      // console.log(data.message)
      setEventCategories(data.categories);
    } catch (error) {
      console.log(error);
      setError(error.message || error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchEventCategories();
    fetchStatesList().finally(() => setLoading(false));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSearchFlag(true);
      const { data } = await searchEvents(values);
      // console.log(data.events)
      setEvents(data.events);
      // setValues(initializeFormData)
    } catch (error) {
      console.log(error);
    }
  };

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return <div>Oops Something went wrong! We're on it.</div>;
  }

  return (
    <Layout>
      <main>
        {/* <!-- Event Search Section --> */}
        <EventSearch
          values={values}
          onChange={onChange}
          handleSubmit={handleSubmit}
          eventCategories={eventCategories}
          states={states}
        />
        {/* <!-- EventCards Section --> */}
        <EventCards eventData={events} searchFlag={searchFlag} />
      </main>
    </Layout>
  );
};

export default EventsHome;
