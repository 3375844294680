import Layout from "../components/layout/layout";
import { useSelector } from "react-redux";
import Login from "../components/login/login"
import RecoverPassword from "../components/login/recoverPassword";
import PasswordOTP from "../components/login/passwordOTP";
import ResetPassword from "../components/login/resetPassword";

const Authentication = () => {

  const { login, passwordOTP, recoverPassword, resetPassword } =
    useSelector(state => state.auth.activeComponentsLogin);

  return (
    <Layout>
      {login && (<Login />)}
      {recoverPassword && (<RecoverPassword />)}
      {passwordOTP && (<PasswordOTP />)}
      {resetPassword && <ResetPassword />}
    </Layout>
  );
};

export default Authentication;
