import authHub from "./authHub";

export async function registerUser(registrationData) {
  return await authHub.post("/register", registrationData);
}

export async function login(loginData) {
  // console.log(loginData);
  return await authHub.post("/login", loginData);
}

export async function generateOTP(email) {
  return await authHub.post("/generateOTP", email);
}

export async function verifyOTPCode(code) {
  return await authHub.put("/verifyOTP", code);
}

export async function resetPassword(password) {
  return await authHub.put("/resetPassword", password);
}

// Reset Password after Login
export async function passwordReset(password) {
  return await authHub.put("/passwordReset", password);
}

export async function updateProfile(profileData) {
  return await authHub.put("/profile", profileData);
}

export async function updatePersonalDetails(profileData) {
  return await authHub.put("/updatePersonal", profileData);
}

export async function updateAddressDetails(profileData) {
  return await authHub.put("/updateAddress", profileData);
}

export async function updateProfileAvatar(profileData) {
  return await authHub.put("/updateAvatar", profileData);
}

export async function onLogout() {
  return await authHub.post("/logout");
}

export async function fetchProtectedInfo() {
  return await authHub.get("/protected");
}

export async function getUserProfile() {
  // console.log("getUserProfile");
  return await authHub.get("/profile");
}

export async function getUserById(reqkey) {
  return await authHub.get("/user", { params: { reqkey } });
}

export async function getAllUsers() {
  return await authHub.get("/users");
}

export async function deleteUser(userId) {
  return await authHub.delete(`/user/${userId}`);
}

export async function deleteEvent(eventId) {
  return await authHub.delete(`/event/${eventId}`);
}

export async function getAllEvents() {
  return await authHub.get("/events");
}

export async function getAllUserEvents() {
  return await authHub.get("/allUserEvents");
}

export async function getDraftEvents() {
  return await authHub.get("/draftEvents");
}

export async function searchEvents(searchParams) {
  return await authHub.post("/searchEvents", searchParams);
}

export async function getWeekEvents() {
  return await authHub.get("/weekEvents");
}

export async function getLocalEvents() {
  return await authHub.get("/localEvents");
}

export async function getUserEvents() {
  return await authHub.get("/userEvents");
}

export async function getArchivedEvents() {
  return await authHub.get("/archivedEvents");
}

export async function getInterestedEvents() {
  return await authHub.get("/interestedEvents");
}

export async function getEventCategories() {
  return await authHub.get("/eventCategories");
}

export async function getStatesList() {
  return await authHub.get("/states");
}

export async function getOrgList() {
  return await authHub.get("/orgs");
}

export async function getSignupCategoryOptions(signupCategory) {
  return await authHub.get(`/categoryOptions/${signupCategory}`);
}

export async function createEvent(eventData) {
  // console.log(eventData)
  return await authHub.post("/event", eventData);
}

export async function updateEvent(eventData) {
  // console.log(eventData)
  return await authHub.put("/event", eventData);
}

export async function cancelEvent(eventId) {
  // console.log(eventId);
  return await authHub.put("/cancelEvent", { eventId });
}

export async function addEventInterest(eventId) {
  return await authHub.post("/event/interest", { eventId });
}

export async function getEventInterests() {
  return await authHub.get("/interestedEvents");
}

export async function addEventRSVP(rsvpData) {
  return await authHub.post("/event/rsvp", rsvpData);
}

export async function getEventRSVPCount(eventId) {
  return await authHub.get("/event/rsvpcount", {
    params: {
      eventId: eventId,
    },
  });
}

export async function getEventRSVPList(eventId) {
  return await authHub.get("/event/rsvpList", {
    params: {
      eventId: eventId,
    },
  });
}

export async function getRSVPCount() {
  return await authHub.get("/event/rsvp");
}

export async function updateEventViewCount(reqkey) {
  // console.log(reqkey)
  return await authHub.put("/event/views", reqkey);
}

export async function getEventViewCount(eventId) {
  return await authHub.get("/event/viewcount", {
    params: {
      eventId: eventId,
    },
  });
}

export async function getEventById(eventId) {
  // console.log(eventId);
  return await authHub.get(`/event/${eventId}`);
}

export async function uploadImage(formData) {
  // console.log(formData)
  return await authHub.post("/uploadImage", formData);
}

export async function addSignupRequest(signupRequestData) {
  // console.log(eventData)
  return await authHub.post("/signupReq", signupRequestData);
}

export async function updateSignupRequest(signupRequestData) {
  // console.log(signupRequestData)
  return await authHub.put("/signupReq", signupRequestData);
}

export async function getSignupReqsByCategory(reqkey) {
  return await authHub.get("/signupReqsByCat", {
    params: reqkey,
  });
}

export async function getSignupReqById(reqkey) {
  // console.log(reqkey)
  return await authHub.get("/signupReq", {
    params: reqkey,
  });
}

// export async function getSignupReqs(reqkey) {
//   return await authHub.get("/signupReqs", {
//     params: reqkey,
//   });
// }

export async function getSignupReqs(reqkey) {
  return await authHub.get("/signupReqs", { params: reqkey });
}

export async function deleteSignupReq(id) {
  return await authHub.delete(`/signupReq/${id}`);
}

export async function getSponsorshipReqs(reqkey) {
  return await authHub.get("/sponsorshipReqs", {
    params: reqkey,
  });
}

export async function addSponsorshipRequest(sponsorshipReqData) {
  // console.log(eventData)
  return await authHub.post("/sponsorshipReq", sponsorshipReqData);
}

export async function updateSponsorshipRequest(sponsorshipReqData) {
  // console.log(sponsorshipReqData)
  return await authHub.put("/sponsorshipReq", sponsorshipReqData);
}

export async function getSponsorshipRequests(reqkey) {
  return await authHub.get("/sponsorshipReqs", {
    params: reqkey,
  });
}

export async function addSponsorshipResp(sponsorshipRespData) {
  return await authHub.post("/sponsorshipResp", sponsorshipRespData);
}

export async function getSponsorshipReqById(reqkey) {
  // console.log(reqkey)
  return await authHub.get("/sponsorshipReq", {
    params: reqkey,
  });
}

export async function deleteSponsorshipReq(id) {
  return await authHub.delete(`/sponsorshipReq/${id}`);
}

export async function getProgramDetails(eventId) {
  // console.log(eventId)
  return await authHub.get("/event/program", {
    params: {
      eventId: eventId,
    },
  });
}

export async function updateProgramDetails(programData) {
  return await authHub.put("/event/program", programData);
}

export async function deleteProgramDetails(id) {
  console.log(id);
  return await authHub.delete("/event/program", {
    params: {
      id: id,
    },
  });
}

export async function addContactUs(contactUsData) {
  return await authHub.post("/contactUs", contactUsData);
}
