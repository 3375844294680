import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventsTabActive: {
    allEvents: true,
    myEvents: false,
    localEvents: false,
    weekEvents: false,
    interestedEvents: false,
    archivedEvents: false,
  },
};

export const eventsTabSlice = createSlice({
  name: "eventsTab",
  initialState,
  reducers: {
    setEventsTabActive: (state, action) => {
      state.eventsTabActive = action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setEventsTabActive, reset } = eventsTabSlice.actions;

export default eventsTabSlice.reducer;
