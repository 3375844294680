import { useState, useEffect } from "react";
import {
  formatDate,
  formatDatemmdd,
  formatTime,
} from "../../helpers/formatDateTime";
import {
  addSignupResponse,
  cancelSignup,
  updateSignupResponse,
} from "../../../api/signupApis";
import { getSignupReqsResByUser } from "../../../api/signupApis";

const SignupRequestsEmpty = () => (
  <div className="card card-body mt-2 mb-4">
    <div className="mt-2">
      <div className="p-3">
        <h6>There are no Signup Requests for this event!</h6>
      </div>
    </div>
  </div>
);

const SignupRequests = ({
  signupReqs,
  handleSubmit,
  values,
  handleChange,
  handleCancelSignup,
}) => (
  <div>
    {signupReqs &&
      signupReqs.map((dayDetails) => (
        <div key={dayDetails.event_date} className="row align-items-center">
          <h6 className="mt-1">
            {" "}
            Signup Requests for{" "}
            <span className="fw-bold highlight">
              {formatDate(dayDetails.event_date)}
            </span>
          </h6>
          {dayDetails.Categories.map((cat) => (
            <Category
              key={cat.category}
              category={cat}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              handleCancelSignup={handleCancelSignup}
            />
          ))}
        </div>
      ))}
  </div>
);

const Category = ({
  category,
  handleSubmit,
  values,
  handleChange,
  handleCancelSignup,
}) => {
  return (
    <div className="row align-items-center mb-2">
      <div className="d-flex my-1">
        <span className="fw-bold app-text-primary">{category.category}</span>
      </div>
      {category.Items.map((req, index) => {
        return (
          <div key={req.id} className="card mt-1 mb-2">
            <form onSubmit={(e) => handleSubmit(e, req.id)}>
              <div
                key={req.id}
                className="row form-wrapper-gen align-items-center g-2"
              >
                <div className="col-md-12">
                  <div className="row">
                    <div>
                      <span className={showSignupQuantity(req.signup_quantity)}>
                        You have signed up for {req.signup_quantity}{" "}
                        {req.unit_measure}
                      </span>
                    </div>
                  </div>
                  <div className="row my-1">
                    <div className="d-sm-flex flex-sm-row flex-column justify-content-between">
                      <div>
                        {/* <span className="app-fs-9">Item Name: </span>{" "} */}
                        <span className="fw-bold app-fs-9 app-text-accent1">
                          {req.item}
                        </span>
                        <span className="app-fs-8">
                          {" "}
                          {req.category === "Volunteers"
                            ? `(${formatDatemmdd(req.event_date)} ${formatTime(
                                req.from_time
                              )} - ${formatTime(req.to_time)})`
                            : ""}
                        </span>
                      </div>
                      <div>
                        <span className="app-fs-9">Required: </span>{" "}
                        <span className="fw-bold app-fs-9 app-text-accent1">
                          {" "}
                          {req.quantity} {req.unit_measure}{" "}
                        </span>
                      </div>
                      <div>
                        <span className="app-fs-9">Remaining: </span>{" "}
                        <span className="fw-bold app-fs-9 app-text-accent1">
                          {req.quantity_remaining < 0
                            ? 0
                            : req.quantity_remaining}{" "}
                          {req.unit_measure}
                        </span>{" "}
                      </div>
                      <div>
                        <span className="app-fs-9 app-text-accent5">
                          {Number(req.signup_user_count) === 0
                            ? "No users have signed up"
                            : Number(req.signup_user_count) === 1
                            ? `${req.signup_user_count} user has signed up`
                            : `${req.signup_user_count} users have signed up`}
                        </span>
                      </div>
                    </div>
                  </div>

                  {req.special_instructions && (
                    <div className="row my-1">
                      <div className="col-md-12">
                        {/* <span className="fw-bold app-fs-9">Comments:</span>{" "} */}
                        <span className="app-fs-9">
                          {req.special_instructions}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="row mb-1">
                    <div className="col-md-3 form-group">
                      <label
                        htmlFor="quantity"
                        className="form-label fw-bold fl-fs-8"
                      >
                        {req.category === "Volunteers"
                          ? "#Volunteers"
                          : "Signup Quantity"}
                      </label>
                      <input
                        type="number"
                        min="1"
                        max={Math.ceil(parseFloat(req.quantity * 1.25))}
                        onChange={(e) =>
                          handleChange(req.id, "quantity", e.target.value)
                        }
                        value={values[req.id]?.quantity}
                        className="form-control form-input fc-fs-8 w-50"
                        id={`quantity${req.id}`}
                        name="quantity"
                        required
                      />
                    </div>
                    <div className="col-md-9 form-group">
                      <label
                        htmlFor="comments"
                        className="form-label fw-bold fl-fs-8"
                      >
                        Comments
                      </label>
                      <textarea
                        id={`comments${req.id}`}
                        name="comments"
                        rows="2"
                        cols="50"
                        onChange={(e) =>
                          handleChange(req.id, "comments", e.target.value)
                        }
                        value={values[req.id]?.comments}
                        className="form-control form-input fc-fs-8 hide-placeholder"
                        placeholder="Enter any comments here"
                      ></textarea>
                    </div>
                  </div>
                </div>
                {!req.signup_quantity ? (
                  <div className="d-flex col-md-12 action-buttons mb-2">
                    <button
                      type="submit"
                      className="btn app-btn-primary-sm me-3"
                    >
                      Signup for {req.item}{" "}
                    </button>
                  </div>
                ) : (
                  req.signup_quantity > 0 && (
                    <div className="d-flex col-md-12 action-buttons mb-2">
                      <button
                        type="submit"
                        className="btn app-btn-primary-sm me-3"
                        disabled={values[req.id]?.touched ? false : true}
                      >
                        {req.category === "Volunteers"
                          ? "Change Count"
                          : "Change Quantity"}
                      </button>
                      <button
                        type="button"
                        className={`btn app-btn-primary-sm ${
                          req.signup_quantity === 0 ? "hideBtn" : ""
                        }`}
                        title="Will cancel your signup for this item"
                        onClick={() => handleCancelSignup(req.id)}
                      >
                        Cancel Signup
                      </button>
                    </div>
                  )
                )}
              </div>
            </form>
          </div>
        );
      })}
    </div>
  );
};

const showSignupQuantity = (qty) => {
  const classes = "fw-bold-500 app-text-accent5";
  if (qty > 0) {
    return classes + " ";
  } else {
    return classes + " hideDiv";
  }
};

const SignupResp = ({ eventId }) => {
  const [signupReqs, setSignupReqs] = useState([]);
  const [refreshCount, setRefreshCount] = useState(false);
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  function separateLists(signupReqs) {
    // Sort the data based on event_date and category
    signupReqs.sort(
      (a, b) =>
        a.event_date.localeCompare(b.event_date) ||
        a.category.localeCompare(b.category) ||
        b.remaining_perc - a.remaining_perc ||
        a.item.localeCompare(b.item)
    );

    // The result will be an array of objects where each object has an "event_date"
    // property and a "data" property containing the items for that date

    let currentEventDate = null;
    let currentCategory = null;
    let result = [];

    signupReqs.forEach((item) => {
      // we compare the current item's "event_date" with the currentEventDate. If it's different,
      // we update currentEventDate to the new event date and add a new object to the result array with
      // the "Event Date" and an empty array for "Categories."
      if (item.event_date !== currentEventDate) {
        currentEventDate = item.event_date;
        result.push({ event_date: currentEventDate, Categories: [] });
        currentCategory = null;
      }

      // we compare the current item's "category" with the currentCategory. If it's different,
      // we update currentCategory to the new category and add a new object to the "Categories"
      // array with the "Category" and an empty array for "Items."
      if (item.category !== currentCategory) {
        currentCategory = item.category;
        result[result.length - 1]["Categories"].push({
          category: currentCategory,
          Items: [],
        });
      }
      // For each item, we add it to the "Items" array of the current category.
      // result[result.length - 1] is used to access the last element in the result array
      result[result.length - 1]["Categories"][
        result[result.length - 1]["Categories"].length - 1
      ]["Items"].push(item);
    });

    // Now 'result' contains the desired structure
    // console.log(result);
    return result;

    // Printing the separated data
    // separatedData.forEach((dateGroup, index) => {
    //   console.log(`Data for ${dateGroup[0].event_date}:`);
    //   dateGroup.forEach(item => {
    //     console.log(item);
    //   });
    // });
  }

  const handleChange = (id, field, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [id]: { ...prevValues[id], [field]: value, ["touched"]: true },
    }));
    // console.log(values);
  };

  const handleCancelSignup = async (id) => {
    try {
      const signupResponseData = {
        event_id: eventId,
        id: id,
        req_qty: values[id].req_qty,
        resp_id: values[id].resp_id,
        quantity: 0,
        comments: values[id].comments,
      };
      // console.log(signupResponseData);
      // await updateSignupResponse(signupResponseData);
      await cancelSignup(signupResponseData);
      // consolle.log(response);
      setRefreshCount(!refreshCount);
    } catch (error) {
      console.log(error);
      setError(error.response.data.errors[0].msg);
    }
  };

  const fetchSignupReqs = async () => {
    let reqkey = {
      eventId: eventId,
    };
    try {
      const { data } = await getSignupReqsResByUser(reqkey);
      setSignupReqs(separateLists(data.signupReqs));
      const newValues = { ...values };
      data.signupReqs.forEach((item) => {
        newValues[item.id] = {
          resp_id: item.resp_id,
          req_qty: item.quantity,
          quantity: item.signup_quantity > 0 ? item.signup_quantity : "",
          comments: item.signup_quantity > 0 ? item.comments : "",
        };
      });
      setValues(newValues);
    } catch (error) {
      console.log(error);
      setError(error.response.data.errors[0].msg);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSignupReqs().finally(() => setLoading(false));
  }, [refreshCount]);

  // Add the signup req to the db and display message
  const handleSubmit = async (e, id) => {
    e.preventDefault();
    const signupResponseData = {
      event_id: eventId,
      id: id,
      req_qty: values[id].req_qty,
      resp_id: values[id].resp_id,
      quantity: values[id].quantity,
      comments: values[id].comments,
    };
    // console.log(signupResponseData);
    try {
      const response = await addSignupResponse(signupResponseData);
      // console.log(response);
      // Need to optimize this to pull only the updated signup reqs
      setRefreshCount(!refreshCount);
    } catch (error) {
      console.log(error);
      // setError(error.response.data.errors[0].msg)
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-0">
      {signupReqs && signupReqs?.length === 0 ? (
        <SignupRequestsEmpty />
      ) : (
        <SignupRequests
          signupReqs={signupReqs}
          handleSubmit={handleSubmit}
          values={values}
          handleChange={handleChange}
          handleCancelSignup={handleCancelSignup}
        />
      )}
    </div>
  );
};

export default SignupResp;
