import { useState } from "react";
import { showDateTime } from "../helpers/formatDateTime";
import ChatReply from "./chatReply";

const ChatUserProfile = ({ selectedUser }) => {
  return (
    <div className="d-sm-flex justify-content-between align-items-center">
      <div className="d-flex mb-2 mb-sm-0">
        <div className="flex-shrink-0 avatar me-2">
          <img
            className="avatar-img rounded-circle"
            src={selectedUser?.profile}
            alt=""
          />
        </div>
        <div className="d-block flex-grow-1">
          <h6 className="mb-0 mt-1">{selectedUser?.username}</h6>
          <div className="small text-secondary">
            <i className="fa-solid fa-circle text-danger me-1"></i>
            {selectedUser?.last_login}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatConversation = ({ loggedInUser, messages }) => {
  return (
    <div className="conversation">
      {messages.map((message) => (
        <div
          key={message.id}
          className="chat-conversation-content overflow-auto"
        >
          <div
            className={`d-flex mb-1 ${
              message.sender_user_id === loggedInUser?.id
                ? "justify-content-endtext-end"
                : ""
            }`}
          >
            <div className="flex-grow-1">
              <div className="w-100">
                <div
                  className={`d-flex flex-column ${
                    message.sender_user_id === loggedInUser?.id
                      ? "align-items-end"
                      : "align-items-start"
                  }`}
                >
                  <div
                    className={`p-1 px-3 app-fs-9 rounded-2 ${
                      message.sender_user_id === loggedInUser?.id
                        ? "app-bg-skyblue text-dark"
                        : "bg-light app-text-primary"
                    }`}
                  >
                    {message.message_text}
                  </div>
                  <div className="p-1 px-3 rounded-2 app-text-accent3-xs">
                    {showDateTime(message.create_date)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const ChatMessages = ({
  loggedInUser,
  messages,
  selectedUser,
  setRefreshMsgs,
}) => {
  // console.log("ChatMessages", messages);

  // Add loading state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [chatMessages, setChatMessages] = useState([]);
  const [msgFound, setMsgFound] = useState(false);

  // Display loading state
  if (!messages || messages.length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <div>There are no messages at this time</div>
        </div>
      </div>
    );
  }

  // Display error state
  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Oops! Something went wrong. We are working on fixing this!
      </div>
    );
  }

  return (
    <div className="row gx-0">
      <div className="card card-chat rounded-start-lg-0 border-start-lg-0">
        <div className="card-body h-100">
          {selectedUser && <ChatUserProfile selectedUser={selectedUser} />}
          <hr />
          {/* <!-- Chat conversation START --> */}
          <div className="col-lg-12 col-xxl-12">
            <ChatConversation loggedInUser={loggedInUser} messages={messages} />
          </div>
          {/* <!-- Chat conversation END --> */}
          <hr />
          {selectedUser && (
            <ChatReply
              selectedUser={selectedUser}
              setRefreshMsgs={setRefreshMsgs}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessages;
