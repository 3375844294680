import avatar from "../../assets/images/avatars/profile.png";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthenticated } from "../../redux/slices/authSlice";
import { getUserProfile } from "../../api/authApis";
import { setActiveComponentsDashboard } from "../../redux/slices/dashboardSlice";

const AvatarCard = ({ user, updateImage }) => {
  return (
    <div className="card mb-2">
      <div className="card-header justify-content-between align-items-center">
        Profile Details
        <p className="mb-0 app-fs-7">
          Personal and Address details are private!
        </p>
      </div>
      <div className="card-body">
        <div className="row mb-2 mb-lg-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="bd-clipboard">
              <button
                type="button"
                onClick={updateImage}
                className="app-btn-clipboard"
                title="Update Details"
                data-bs-original-title="Update Details"
              >
                Update
              </button>
            </div>
            <div>
              <img
                src={user?.profile || avatar}
                className="rounded-circle profile-img mb-1"
                alt="avatar"
              />
              <p>{user?.about}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PersonalCard = ({ user, updatePersonal }) => {
  return (
    <div className="card mb-2">
      <div
        className="card-header justify-content-between align-items-center"
        title="Only used to send you confirmation emails"
      >
        Personal Details
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="bd-clipboard">
              <button
                type="button"
                onClick={updatePersonal}
                className="app-btn-clipboard"
                title="Update Details"
                data-bs-original-title="Update Details"
              >
                Update
              </button>
            </div>
            <div>
              <div>
                <ul className="list-unstyled gap-3">
                  <li>
                    <span>
                      <strong>Name: </strong>
                      {user?.first_name} {user?.last_name}
                    </span>
                  </li>
                  <li>
                    <strong>Username: </strong>
                    {user?.username}
                  </li>
                  <li>
                    <span>
                      <strong>Email: </strong>
                      {user?.email}
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>Phone: </strong>
                      {user?.mobile_phone}
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>Organization: </strong>
                      {user?.org_name}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddressCard = ({ user, updateAddress }) => {
  return (
    <div className="card mb-2">
      <div
        className="card-header justify-content-between align-items-center"
        title="Only used to show relevant Events near you"
      >
        Address Details
      </div>
      <div className="card-body">
        <div className="row">
          <div className="mb-1 col-md-12 col-sm-12">
            <div className="bd-clipboard">
              <button
                type="button"
                onClick={updateAddress}
                className="app-btn-clipboard"
                title="Update Details"
                data-bs-original-title="Update Details"
              >
                Update
              </button>
            </div>
            <div>
              <div>
                <ul className="list-unstyled">
                  <li>
                    <span>
                      <strong>Address: </strong>
                      {user?.address_line1}, {user?.address_line2}
                    </span>
                  </li>
                  <li>
                    <strong>City: </strong>
                    {user?.city}
                  </li>
                  <li>
                    <span>
                      <strong>State: </strong>
                      {user?.state}
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>Zip Code: </strong>
                      {user?.zipcode}
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>Country: </strong>
                      {user?.country}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Profile = () => {
  const dispatch = useDispatch();
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const [user, setUser] = useState();
  const [error, setError] = useState(false);

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const fetchProfile = async () => {
    try {
      const { data, status } = await getUserProfile();
      setUser(data.user);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("isAuth");
        dispatch(setAuthenticated(false));
      }
      console.log(error.response.status);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const updatePersonal = () => {
    // console.log(activeComponentsDashboardNew)
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        updatePersonal: true,
      })
    );
  };

  const updateAddress = () => {
    // console.log(activeComponentsDashboardNew)
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        updateAddress: true,
      })
    );
  };

  const updateImage = () => {
    // console.log(activeComponentsDashboardNew)
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        updateAvatar: true,
      })
    );
  };

  return (
    <>
      <AvatarCard user={user} updateImage={updateImage} />
      <PersonalCard user={user} updatePersonal={updatePersonal} />
      <AddressCard user={user} updateAddress={updateAddress} />
    </>
  );
};

export default Profile;
