import { formatDatemmdd } from "./formatDateTime";

export const dateRangeList = (fromdate, todate) => {
  // Initialize an empty array to store the event date range
  const date_list = [];

  // Parse the input date string into a JavaScript Date object
  let current_date = new Date(fromdate);
  let future_date = new Date(todate);
  let dayCount = 1;
  // console.log(current_date, future_date);

  const options = {
    // year: "numeric",
    month: "short",
    day: "numeric",
  };

  // Create a loop to generate objects in the specified format
  while (current_date <= future_date) {
    var year = current_date.getUTCFullYear().toString();
    var month = (current_date.getUTCMonth() + 1).toString().padStart(2, "0");
    var day = current_date.getUTCDate().toString().padStart(2, "0");
    var monthName = current_date.toLocaleString("default", { month: "short" });
    const formattedDate = `${month}/${day}`;
    const utcDate = new Date(year, month, day);

    const date_object = {
      id: dayCount,
      event_day: `Day-${dayCount}`,
      // event_date: formattedDate,
      event_date: new Date(current_date.getTime()),
      event_date_display: formattedDate,
    };

    date_list.push(date_object);

    // Increment the date by one day
    current_date.setUTCDate(current_date.getUTCDate() + 1);
    // current_date.setDate(current_date.getUTCDate() + 1);
    dayCount++;
  }

  return date_list;
};
