const EventDetailsPrint = ({ event_type, event_details }) => {
  const { meeting_id, meeting_passcode, meeting_link } = event_details || {};

  return (
    <>
      {event_type === "Virtual" && (
        <div className="row mt-2">
          <div>
            <div className="me-2 font-responsive" />
            <h5>Virtual Event Details</h5>
            <div>
              <span className="fw-bold app-fs-9 mr-2">Meeting ID: </span>
              <span className="app-fs-9">{meeting_id}</span>
            </div>

            <div>
              <span className="fw-bold app-fs-9 mr-2">Meeting Passcode: </span>
              <span className="app-fs-9">{meeting_passcode}</span>
            </div>

            <div>
              <span className="fw-bold app-fs-9 mr-2">Meeting Link: </span>
              <span className="app-fs-9">{meeting_link}</span>
            </div>

            {/* <div id="eventDesc" className="me-2" /> */}
          </div>
        </div>
      )}
      {/* <!-- Main content END --> */}
    </>
  );
};

export default EventDetailsPrint;
