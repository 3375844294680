import { useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import QRCodeGenerator from "../../utils/qrCodeGenerator";
import EventHeaderPrint from "./eventHeaderPrint";
import { setActiveComponentsDashboard } from "../../../redux/slices/dashboardSlice";
import EventProgramPrint from "./eventProgramPrint";
import EventDescriptionPrint from "./eventDescriptionPrint";
import EventDetailsPrint from "./eventDetailsPrint";

// EventFlyerLink
export const EventFlyerLink = ({ eventId }) => (
  <Link
    to={`/eventFlyer/${eventId}`}
    target="_blank"
    className="btn btn-responsive me-3 d-none d-lg-inline"
  >
    View Event Flyer
  </Link>
);

const EventPrint = () => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.dashboard.item);
  const { description, event_type, event_details, contact_details } = event;
  const eventId = event.id;
  const qrCodeRef = useRef(null);

  // const url = `https://signupqr.com/event/${eventId}`;
  const url = `${process.env.REACT_APP_EVENT_URL}${eventId}`;

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const handleBackButtonClick = () => {
    // console.log('handleBackButtonClick')
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        eventList: true,
      })
    );
  };

  const printDocument = async () => {
    try {
      const content = document.getElementById("divToPrint");
      const doc = new jsPDF("p", "pt", "a4");
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      // Increase the font size until the content fits the page
      let fontSize = 26;
      let contentHeight = content.offsetHeight;
      // console.log(contentHeight, pageHeight);

      while (contentHeight < pageHeight) {
        fontSize += 2;
        content.style.fontSize = `${fontSize}px`;
        contentHeight = content.offsetHeight;
      }
      // console.log(contentHeight, pageHeight, fontSize);
      // Render the HTML content to canvas
      html2canvas(content, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
        const width = imgWidth * ratio;
        const height = imgHeight * ratio;

        const x = (pageWidth - width) / 2;
        const y = (pageHeight - height) / 2;

        doc.addImage(imgData, "jpeg", x, 0, width, height);
        doc.save("event_flyer.pdf");
      });
    } catch (error) {
      console.error("Error printing document:", error);
    }
  };

  // const printDocument = async () => {
  //   try {
  //     const content = document.getElementById("divToPrint");
  //     const canvas = await html2canvas(content, { scale: 2 });
  //     // const imgData = canvas.toDataURL("image/png");
  //     // const imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     const imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     const img = new Image();
  //     img.onload = function () {
  //       var doc = new jsPDF("p", "mm", "a4");
  //       // var doc = new jsPDF("p", "pt", "letter");
  //       const width = doc.internal.pageSize.getWidth();
  //       const height = doc.internal.pageSize.getHeight();

  //       let imgWidth = this.width;
  //       let imgHeight = this.height;
  //       const ratio = imgWidth / imgHeight;

  //       if (ratio > width / height) {
  //         imgWidth = width;
  //         imgHeight = imgWidth / ratio;
  //       } else {
  //         imgHeight = height;
  //         imgWidth = imgHeight * ratio;
  //       }
  //       // Set the font
  //       // doc.setFont("helvetica", "normal");
  //       doc.setFont("helvetica", "normal");
  //       doc.setFontSize(12);
  //       doc.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
  //       // doc.addImage(imgData, "JPEG", 0, 0, width, height);
  //       // doc.addImage(imgData, "PNG", 0, 0, 210, imgHeight);
  //       doc.save("event_flyer.pdf");
  //     };
  //     img.src = imgData;
  //   } catch (error) {
  //     console.error("Error printing document:", error);
  //   }
  // };

  return (
    <div>
      <div id="divToPrint" className="w-75">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <EventHeaderPrint item={event} />
          </div>
        </div>
        <div className="row mx-1 my-2 justify-content-center align-items-end">
          <div className="col-md-12">
            <EventDescriptionPrint description={description} />
          </div>
        </div>
        <div className="row mx-1 justify-content-center align-items-end">
          <div className="col-md-12">
            <EventDetailsPrint
              event_type={event_type}
              event_details={event_details}
            />
          </div>
        </div>
        <div className="row mx-1 my-2 rounded app-bg-skyblue justify-content-center align-items-center">
          <div className="col-lg-9">
            <EventProgramPrint eventId={eventId} />
          </div>
          <div className="col-lg-3">
            <QRCodeGenerator url={url} />
          </div>
        </div>
        <div className="row mx-1 my-3 justify-content-center">
          <div className="col-md-12">
            <span className="font-responsive">{contact_details}</span>
          </div>
        </div>
      </div>
      <div className="d-flex action-buttons">
        <button
          type="button"
          className="btn btn-responsive me-3"
          onClick={handleBackButtonClick}
        >
          Back to My Events
        </button>
        <button
          type="button"
          className="btn btn-responsive"
          onClick={printDocument}
        >
          Print to PDF
        </button>
        {/* <EventFlyerLink eventId={eventId} /> */}
      </div>
    </div>
  );
};

export default EventPrint;
