import React, { useState, useEffect } from "react";
import { getAllUserEvents } from "../../../api/authApis";
import RSVPList from "./rsvpList";
import SignupList from "./signupList";
import SponsorshipList from "./sponsorshipList";

const RenderReport = ({ selectedReport, selectedEvent }) => {
  // console.log(selectedReport, selectedEvent);

  switch (selectedReport) {
    case "rsvp":
      return <RSVPList eventId={selectedEvent} />;
    case "signup":
      return <SignupList eventId={selectedEvent} />;
    case "sponsorship":
      return <SponsorshipList eventId={selectedEvent} />;
    default:
      return null;
  }
};

const ReportsMain = () => {
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);

  const handleReportChange = (e) => {
    setSelectedReport(e.target.value);
  };

  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
  };

  const fetchtUserEvents = async () => {
    try {
      const { data } = await getAllUserEvents();
      //   console.log(data.events);
      setEvents(data.events);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchtUserEvents();
  }, []);

  return (
    <div>
      <section className="card border-0 shadow mb-2 app-bg-grey">
        <div className="mx-auto text-center">
          <h2>Event Reports</h2>
          <p className="text-muted">
            Please select the event and the report from the dropdown.
          </p>
        </div>
        <div className="d-flex  mb-3">
          <div className="me-2">
            <select
              id="eventSelect"
              className="form-select form-input fc-fs-8"
              value={selectedEvent}
              onChange={handleEventChange}
            >
              <option value="">Select Event</option>
              {events &&
                events.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.event_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="ms-2">
            <select
              id="reportSelect"
              className="form-select form-input fc-fs-8"
              value={selectedReport}
              onChange={handleReportChange}
            >
              <option value="">Select Report</option>
              <option value="rsvp">RSVP</option>
              <option value="signup">Signup</option>
              <option value="sponsorship">Sponsorship</option>
              {/* Add other report options here */}
            </select>
          </div>
        </div>
      </section>
      <RenderReport
        selectedReport={selectedReport}
        selectedEvent={selectedEvent}
      />
    </div>
  );
};

export default ReportsMain;
