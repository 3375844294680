import React, { useState, useEffect } from "react";
import { getSignupReqs } from "../../../api/authApis";
import {
  formatDate,
  formatTime,
} from "../../../components/helpers/formatDateTime";
import { useDispatch, useSelector } from "react-redux";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../../redux/slices/dashboardSlice";

const SignupReqs = ({ event }) => {
  const eventId = event.id;
  const is_over = event.is_over;

  const dispatch = useDispatch();
  const [signupReqs, setSignupReqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(is_over);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const activeComponentsDashboardNew = Object.keys(
    activeComponentsDashboard
  ).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});

  function separateLists(signupReqs) {
    // Initialize objects to store data for different dates
    const groupedData = [];

    // Sort the data based on event_date and category
    signupReqs.sort(
      (a, b) =>
        a.event_date.localeCompare(b.event_date) ||
        a.category.localeCompare(b.category)
    );

    // The result will be an array of objects where each object has an "event_date"
    // property and a "data" property containing the items for that date

    let currentEventDate = null;
    let currentCategory = null;
    let result = [];

    signupReqs.forEach((item) => {
      // we compare the current item's "event_date" with the currentEventDate. If it's different,
      // we update currentEventDate to the new event date and add a new object to the result array with
      // the "Event Date" and an empty array for "Categories."
      if (item.event_date !== currentEventDate) {
        currentEventDate = item.event_date;
        result.push({ event_date: currentEventDate, Categories: [] });
        currentCategory = null;
      }

      // we compare the current item's "category" with the currentCategory. If it's different,
      // we update currentCategory to the new category and add a new object to the "Categories"
      // array with the "Category" and an empty array for "Items."
      if (item.category !== currentCategory) {
        currentCategory = item.category;
        result[result.length - 1]["Categories"].push({
          category: currentCategory,
          Items: [],
        });
      }
      // For each item, we add it to the "Items" array of the current category.
      // result[result.length - 1] is used to access the last element in the result array
      result[result.length - 1]["Categories"][
        result[result.length - 1]["Categories"].length - 1
      ]["Items"].push(item);
    });

    // Now 'result' contains the desired structure
    // console.log(result);
    return result;

    // Printing the separated data
    // separatedData.forEach((dateGroup, index) => {
    //   console.log(`Data for ${dateGroup[0].event_date}:`);
    //   dateGroup.forEach(item => {
    //     console.log(item);
    //   });
    // });
  }

  // console.log(eventId)
  // Get all the suppSignup requests for the event
  const fetchSignupReqs = async () => {
    // const eventId = "acef1608-b81f-4a3e-b983-b5d97ad106b6";
    let reqkey = {
      eventId: eventId,
    };
    try {
      const { data } = await getSignupReqs(reqkey);
      // setSignupReqs(data.signupReqs)
      setSignupReqs(separateLists(data.signupReqs));
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSignupReqs().finally(() => setLoading(false));
  }, [eventId]);

  const handleActionClick = (item, component) => {
    dispatch(setItem(item));
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        [component]: true,
      })
    );
  };

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display the error message if there is an error
  if (error) {
    return (
      <div className="row g-4">
        <div className="col-sm-12 col-xl-12">
          <h6 className="text-danger">
            Oops! Something went wrong. We are working on fixing this!
          </h6>
        </div>
      </div>
    );
  }

  if (signupReqs.length > 0) {
    return (
      <div className="mt-2">
        <div className="card-header d-flex justify-content-between align-items-center action-buttons">
          Signup Requests
          {!show ? (
            <i
              className="fa fa-hand-paper-o fa-lg app-icon-style"
              title="Signup to Help"
              aria-hidden="true"
              aria-label="Signup to Help"
              onClick={() => {
                handleActionClick(event, "signup");
              }}
            ></i>
          ) : (
            ""
          )}
        </div>
        <div className="px-1 mt-2">
          {signupReqs &&
            signupReqs.map((dayDetails) => {
              return (
                <React.Fragment key={dayDetails.event_date}>
                  <div className="row align-items-center">
                    <div className="app-card-subtitle">
                      {formatDate(dayDetails.event_date)}
                    </div>
                    {dayDetails.Categories.map((cat) => {
                      return (
                        <div
                          key={cat.category}
                          className="row align-items-center"
                        >
                          <h6 className="mt-2 fw-bold-600 app-text-secondary">
                            {cat.category}
                          </h6>
                          {cat.Items.map((req) => {
                            if (req.category === "Volunteers") {
                              return (
                                <div
                                  key={req.id}
                                  className="d-flex align-items-center mb-1"
                                >
                                  <div className="font-responsive me-2">
                                    {req.item}:
                                  </div>
                                  <div className="font-responsive me-2">
                                    {req.quantity} {req.unit_measure}
                                  </div>
                                  <div className="font-responsive">
                                    {formatTime(req.from_time)} -{" "}
                                    {formatTime(req.to_time)}
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={req.id}
                                  className="d-flex align-items-center"
                                >
                                  <div className="font-responsive me-2">
                                    {req.item}:
                                  </div>
                                  <div className="font-responsive">
                                    {req.quantity} {req.unit_measure}
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      );
                    })}
                  </div>
                  <hr className="app-border-secondary" />
                </React.Fragment>
              );
            })}
        </div>
      </div>
    );
  }
};

export default SignupReqs;
