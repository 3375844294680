// Validate Food Request Form
export const validateFoodReq = (values) => {
  const errors = validateItemName({}, values);
  validateSpecialInstr(errors, values);
  return errors;
};

// Validate Supplies Request Form
export const validateSuppliesReq = (values) => {
  const errors = validateItemName({}, values);
  validateSpecialInstr(errors, values);
  return errors;
};

// Validate Volunteer Request Form
export const validateVolunteerReq = (values) => {
  const errors = validateServiceArea({}, values);
  // validateSpecialInstrEmpty(errors, values);
  validateSpecialInstr(errors, values);
  return errors;
};

// validate Service Area
function validateItemName(errors = {}, values) {
  if (!values.item) {
    errors.item = "Please enter an Item Name";
  } else if (values.item.length > 50) {
    errors.item = "Item Name must be less than 50 characters";
  }
  return errors;
}

// validate Service Area
function validateServiceArea(errors = {}, values) {
  if (!values.item) {
    errors.item = "Service Area is Required";
  } else if (values.item.length > 50) {
    errors.item = "Service Area must be less than 50 characters";
  }
  return errors;
}

// validate Special Instructions Empty
function validateSpecialInstrEmpty(errors = {}, values) {
  if (!values.specialInstructions) {
    errors.specialInstructions = "Special Instructions cannot be blank";
  }
  return errors;
}

// validate Special Instructions
function validateSpecialInstr(errors = {}, values) {
  if (values.specialInstructions.length > 500) {
    errors.specialInstructions =
      "Special Instructions has to be less than 500 characters";
  }
  return errors;
}
