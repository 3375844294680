const FormTextArea = ({
  id,
  name,
  rows,
  cols,
  maxLength,
  value,
  label,
  onChange,
  error,
  colSpan,
  required,
}) => {
  return (
    <div className={`${colSpan} mb-2`}>
      <label htmlFor={id} className="form-label fl-fs-9">
        {label}
        {required ? <span style={{ color: "red" }}>*</span> : null}
      </label>

      <textarea
        onChange={onChange}
        id={id}
        name={name}
        value={value}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        className="form-control form-input fc-fs-8"
        required
      ></textarea>

      {error && <div className="app-text-error-xs">{error}</div>}
    </div>
  );
};

export default FormTextArea;
