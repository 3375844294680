import { useState, useEffect } from "react";
import { BsTrash, BsPencil } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  getProgramDetails,
  updateProgramDetails,
  deleteProgramDetails,
} from "../../../api/authApis";
import { dateRangeList } from "../../helpers/dateRangeList";
import EventHeaderShort from "./eventHeaderShort";
import { setActiveComponentsDashboard } from "../../../redux/slices/dashboardSlice";
import {
  formatDatemmdd,
  formatDateyyyymmdd,
} from "../../helpers/formatDateTime";
import { v4 as uuidv4 } from "uuid";

const ProgramDetailsForm = ({
  eventId,
  rows,
  handleAddRow,
  handleEdit,
  handleDeleteRow,
  handleUpdate,
  dateList,
  formatDatemmdd,
  archived,
  editedRows,
  backToList,
}) => {
  return (
    <div key={eventId} className="card card-body border-0 mt-1 mb-4">
      <div className="card-header d-flex justify-content-between align-items-center action-buttons mb-3">
        Event Schedule
        <i
          className="fa fa-plus-square app-icon-style"
          title="Signup to Help"
          disabled={archived}
          aria-hidden="true"
          onClick={handleAddRow}
        >
          <span className="app-fs-10 mx-2">Add</span>
        </i>
      </div>
      <div className="row gx-3 gy-1">
        <div className="col-md-2 app-fs-8 fw-bold">Date</div>
        <div className="col-md-2 app-fs-8 fw-bold">From Time</div>
        <div className="col-md-2 app-fs-8 fw-bold">To Time</div>
        <div className="col-md-5 col app-fs-8 fw-bold">Description</div>
        <div className="col-md-1 app-fs-8 fw-bold">Action</div>
      </div>
      {rows &&
        rows.map((row, index) => (
          <div
            key={index}
            className="row form-wrapper-gen mb-2 align-items-top gx-3 gy-1"
          >
            <div className="col-md-2">
              <select
                className="form-select form-input fc-fs-7"
                onChange={(e) =>
                  handleEdit(row.id, "event_day", e.target.value)
                }
                id="event_day"
                name="event_day"
                aria-label="Default select"
                value={row.event_day ? row.event_day : ""}
                required
              >
                <option value="" disabled>
                  Select Event Date
                </option>
                {dateList.length > 0 ? (
                  dateList.map((dt) => (
                    <option key={dt.id} value={dt.event_day}>
                      {dt.event_date_display}
                    </option>
                  ))
                ) : (
                  <option disabled>No dates available</option>
                )}
              </select>
            </div>
            <div className="col-md-2">
              <input
                className="form-control form-input fc-fs-7"
                type="time"
                id="fromTime"
                name="fromTime"
                required
                value={row.from_time}
                onChange={(e) =>
                  handleEdit(row.id, "from_time", e.target.value)
                }
              />
            </div>
            <div className="col-md-2">
              <input
                className="form-control form-input fc-fs-7"
                type="time"
                id="toTime"
                name="toTime"
                required
                value={row.to_time}
                onChange={(e) => handleEdit(row.id, "to_time", e.target.value)}
              />
            </div>
            <div className="col-md-5">
              <textarea
                required
                rows="2"
                className="form-control form-input fc-fs-7"
                id="description"
                name="description"
                maxLength="255"
                title="Maximum 255 chars"
                value={row.description}
                onChange={(e) =>
                  handleEdit(row.id, "description", e.target.value)
                }
              />
            </div>
            <div className="col-md-1">
              <button
                disabled={archived}
                title="Delete Row"
                className="del-icon me-2"
                onClick={() => handleDeleteRow(row.id)}
              >
                {/* <i className="fa-solid fa-delete-left react-icons" data-toggle="tool-tip" title="Delete Row" aria-hidden="true"></i> */}
                <BsTrash />
              </button>
            </div>
          </div>
        ))}
      <div className="d-flex action-buttons mt-2">
        {/* <button
          type="button"
          className="btn btn-responsive me-2"
          onClick={backToList}
        >
          Back to My Events
        </button> */}
        <button
          type="button"
          className="btn btn-responsive"
          onClick={handleUpdate}
          disabled={editedRows.length === 0 || archived}
        >
          Update
        </button>
      </div>
    </div>
  );
};

const EventProgramAdd = ({ item }) => {
  const dispatch = useDispatch();
  const { id, from_date, to_date, from_time, to_time } = item;
  const eventId = id;
  // console.log("from_date", from_date, new Date(from_date));

  const init_date = new Date(from_date);
  // const init_date = getUTCDate(from_date);
  // console.log("init_date", init_date);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const initialRow = [
    {
      id: "New-" + uuidv4(),
      event_id: eventId,
      event_day: "Day-1",
      event_date: init_date,
      from_time: from_time,
      to_time: to_time,
      description: "",
    },
  ];

  console.log("Program Add initialRow: ", initialRow);

  const [editedRows, setEditedRows] = useState([]);
  const [rows, setRows] = useState([initialRow]);
  const [eventProgram, setEventProgram] = useState([]);
  const [archived, setArchived] = useState(false);
  const [error, setError] = useState(null);
  // Add loading state
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [dateList, setDateList] = useState([]);

  let current_date = new Date(from_date);
  let future_date = new Date(to_date);
  // Initialize an empty array to store the event date range
  // console.log(current_date, future_date);
  const newList = [];

  useEffect(() => {
    // function returns a list of event date range
    setDateList(dateRangeList(from_date, to_date));
    console.log(dateRangeList(from_date, to_date));
  }, []);

  // useEffect(() => {
  //   // Parse the input date string into a JavaScript Date object

  //   // console.log(init_date, current_date, future_date);
  //   // Format the date in the desired format "YYYY-MM-DD - No need of this anymore since the data
  //   // coming back from the db is mm-dd-yyy format
  //   // const formattedFromDate = from_date.toISOString().split('T')[0];

  //   let dayCount = 1;

  //   // Create a loop to generate objects in the specified format
  //   while (current_date <= future_date) {
  //     // console.log(current_date);

  //     const date_object = {
  //       id: dayCount,
  //       event_day: `Day-${dayCount}`,
  //       // event_date: new Date(current_date).toUTCString(),
  //       // event_date: formatDateyyyymmdd(current_date),
  //       event_date: new Date(current_date.getTime()),
  //       event_date_display: formatDatemmdd(current_date),
  //     };

  //     newList.push(date_object);

  //     // Increment the date by one day
  //     // current_date.setDate(current_date.getDate() + 1);
  //     current_date.setUTCDate(current_date.getUTCDate() + 1);
  //     console.log(current_date);
  //     dayCount++;
  //   }

  //   // Now, newList contains a list of objects in the specified format
  //   console.log("Program Add date list: ", newList);
  //   setDateList(newList);
  // }, []);

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const iconStyles = {
    border: "none !important",
    color: "red",
    fontSize: "1.5em",
    background: "transparent",
  };

  const backToList = () => {
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        eventList: true,
      })
    );
  };

  // Get all the Sponsorship requests for the event
  const fetchProgramDetails = async () => {
    try {
      const { data } = await getProgramDetails(eventId);
      // console.log(data.programDetails.length);
      if (data.programDetails.length === 0) {
        setRows(initialRow);
      } else {
        setRows(data.programDetails);
        // setRows(
        //   data.programDetails.map((row) => ({
        //     ...row,
        //     event_date: formatDateyyyymmdd(row.event_date),
        //   }))
        // );
        // console.log(data.programDetails);
      }
    } catch (error) {
      console.log(error.response);
      setError(error.response.data.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProgramDetails().finally(() => setLoading(false));
  }, []);

  // Delete a request from the database
  const removeRowDB = async (id) => {
    try {
      const response = await deleteProgramDetails(id);
      // console.log(response)
      setRows(
        rows.filter((row) => {
          return row.id !== id;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (id, field, value) => {
    // console.log(field, value);
    if (field === "event_day") {
      // let event_dt = dateList.filter((d) => d.event_day === value)[0]
      //   .event_date;
      let event_dt = new Date(
        Date.UTC(
          dateList
            .filter((d) => d.event_day === value)[0]
            .event_date.getUTCFullYear(),
          dateList
            .filter((d) => d.event_day === value)[0]
            .event_date.getUTCMonth(),
          dateList
            .filter((d) => d.event_day === value)[0]
            .event_date.getUTCDate()
        )
      );

      const updatedRows = rows.map((row) =>
        row.id === id
          ? { ...row, ["event_day"]: value, ["event_date"]: event_dt }
          : row
      );
      // console.log(updatedRows)
      setRows(updatedRows);
    } else {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      setRows(updatedRows);
    }

    if (!editedRows.some((row) => row.id === id)) {
      setEditedRows((prevEditedRows) => [...prevEditedRows, { id, field }]);
    }
    // console.log(editedRows)
  };

  const handleDeleteRow = (id) => {
    // Filter out the row to be deleted and remove it from both rows and editedRows
    // console.log('handleDeleteRow -' + id)
    if (!id.includes("New-")) {
      removeRowDB(id);
    }
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);

    const updatedEditedRows = editedRows.filter((row) => row.id !== id);
    setEditedRows(updatedEditedRows);
  };

  // const handleSelectEventDt = (eventDt) => {
  //   Object.assign(values, { description: editorContent })
  // };

  // const handleAddRow = () => {
  //   setRows((prevRows) => [
  //     ...prevRows.map((row) => ({
  //       ...row,
  //       event_date: formatDateyyyymmdd(row.event_date),
  //     })),
  //     {
  //       id: "New-" + uuidv4(),
  //       event_id: eventId,
  //       event_day: "Day-1",
  //       event_date: formatDateyyyymmdd(init_date),
  //       from_time: "",
  //       to_time: "",
  //       description: "",
  //     },
  //   ]);
  //   console.log("Program Add New Row: ", rows);
  // };

  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        // id: "New-" + Date.now(),
        id: "New-" + uuidv4(),
        event_id: eventId,
        event_day: "Day-1",
        event_date: init_date,
        from_time: from_time,
        to_time: to_time,
        description: "",
      },
    ]);
    console.log("Program Add New Row: ", rows);
  };

  const handleUpdate = async () => {
    try {
      // console.log("Program Add handleUpdate: ", rows);
      const { data } = await updateProgramDetails(rows);
      // setRefreshData(true);
      fetchProgramDetails();
      // await axios.post('/update', editedRows);
      // console.log('Data updated successfully');
      setEditedRows([]); // Clear the editedRows array
    } catch (error) {
      console.error("Error updating data:", error);
      setError(error); // Set error state
    }
  };

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Oops! Something went wrong. We are working on fixing this!
      </div>
    );
  }

  return (
    <div className="card card-body mt-2 mb-4">
      <EventHeaderShort key={eventId} item={item} backToList={backToList} />
      <div>
        <ProgramDetailsForm
          key={eventId}
          eventId={eventId}
          rows={rows}
          handleAddRow={handleAddRow}
          handleEdit={handleEdit}
          handleDeleteRow={handleDeleteRow}
          handleUpdate={handleUpdate}
          dateList={dateList}
          formatDatemmdd={formatDatemmdd}
          archived={archived}
          editedRows={editedRows}
          backToList={backToList}
        />
      </div>
    </div>
  );
};

export default EventProgramAdd;
