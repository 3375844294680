import { useState, useEffect } from "react";
import { getProgramDetails } from "../../api/authApis";
import { formatDate, formatTime } from "../helpers/formatDateTime";

const EventSchedule = ({ eventId }) => {
  const [programDetails, setProgramDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Add the new request to the array to be displayed
  const splitDays = (programData) => {
    const sortedData = programData.sort(
      (a, b) => new Date(a.event_date) - new Date(b.event_date)
    );

    // const groupedArrays = [];

    // sortedData.forEach(item => {
    //   if (!groupedArrays[item.event_date]) {
    //     groupedArrays[item.event_date] = [];
    //   }
    //   // let newitem = { "event_date": item.event_date, "event_day": item.event_day, "details": item }
    //   groupedArrays[item.event_date].push(newitem);
    // });

    // Now, groupedArrays will hold the arrays grouped by location
    // return groupedArrays;
    let i = 0;
    const separateArrays = sortedData.reduce((acc, item) => {
      if (!acc[item.event_date]) {
        i = i + 1;
        acc[item.event_date] = {
          id: i,
          eventDate: formatDate(item.event_date), // Store the date as the heading
          eventDay: item.event_day, // Store the date as the heading
          items: [],
        };
      }
      acc[item.event_date].items.push(item);
      return acc;
    }, {});

    return Object.values(separateArrays);
  };

  // const first = (result[0]);

  // Get the Program Details
  const fetchProgramDetails = async () => {
    try {
      const { data } = await getProgramDetails(eventId);
      const result = splitDays(data.programDetails);
      setProgramDetails(result);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProgramDetails().finally(() => setLoading(false));
  }, []);

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Oops! Something went wrong. We are working on fixing this!
      </div>
    );
  }

  return (
    <div className="mt-2">
      {programDetails.length > 0 && (
        <h5 className="mt-1 mb-2"> Event Schedule</h5>
      )}

      {programDetails &&
        programDetails.map((dayDetails) => {
          return (
            <section key={dayDetails.id} className="pt-0 pb-2">
              <div
                className="accordion accordion-flush accordion-icon"
                id="accordionExample2"
              >
                {/* <!-- Item --> */}
                <div className="accordion-item mb-3">
                  <h6
                    className="accordion-header"
                    id={`heading-${dayDetails.id}`}
                  >
                    <button
                      className="accordion-button app-bg-secondary fw-bold rounded d-sm-flex d-inline-block collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${dayDetails.id}`}
                      aria-expanded="true"
                      aria-controls={`collapse-${dayDetails.id}`}
                    >
                      {dayDetails.eventDay}
                      <span className="fw-normal app-fs-10 ms-0 ms-sm-2">
                        ({dayDetails.eventDate})
                      </span>
                    </button>
                  </h6>
                  <div
                    id={`collapse-${dayDetails.id}`}
                    className="accordion-collapse collapse show"
                    aria-labelledby={`heading-${dayDetails.id}`}
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      {/* <!-- Item --> */}
                      {dayDetails.items.map((item) => {
                        return (
                          <div key={item.id} className="row mt-1">
                            <div className="col-md-4">
                              <h6 className="fw-normal app-fs-10">
                                <span>
                                  {formatTime(item.from_time)} -{" "}
                                  {formatTime(item.to_time)}{" "}
                                </span>
                              </h6>
                            </div>
                            <div className="col-md-8">
                              <h6 className="fw-normal app-fs-10">
                                {item.description}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
    </div>
  );
};
export default EventSchedule;
