import { combineReducers } from "redux";
import authReducer from "./slices/authSlice";
import dashboardReducer from "./slices/dashboardSlice";
import eventsTabSlice from "./slices/eventsTabSlice";
import eventCreateSlice from "./slices/eventCreateSlice";
import chatSlice from "./slices/chatSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  eventsTab: eventsTabSlice,
  eventCreate: eventCreateSlice,
  chatHome: chatSlice,
  // Add other reducers as needed
});

export default rootReducer;
