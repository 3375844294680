import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: "",
  activeComponentsDashboard: {
    eventList: true,
    eventCreate: false,
    eventEdit: false,
    eventDetail: false,
    eventDrafts: false,
    eventPrint: false,
    requestSignup: false,
    signup: false,
    program: false,
    profile: false,
    updatePersonal: false,
    updateAddress: false,
    updateAvatar: false,
    updatePassword: false,
    mySignups: false,
    mySponsorships: false,
    eventSignups: false,
    chatMessages: false,
    rsvpList: false,
    reportsMain: false,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setItem: (state, action) => {
      state.item = action.payload;
      // console.log(state.item)
    },
    setActiveComponentsDashboard: (state, action) => {
      state.activeComponentsDashboard = action.payload;
      // console.log(state.activeComponentsDashboard);
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setItem, setActiveComponentsDashboard, reset } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
