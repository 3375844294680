import { useState, useEffect } from "react";
import { getProgramDetails } from "../../../api/authApis";
import { formatDate, formatTime } from "../../helpers/formatDateTime";

const EventProgramPrint = ({ eventId }) => {
  const [programDetails, setProgramDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  // Add the new request to the array to be displayed
  const splitDays = (programData) => {
    const sortedData = programData.sort(
      (a, b) => new Date(a.event_date) - new Date(b.event_date)
    );

    // const groupedArrays = [];

    // sortedData.forEach(item => {
    //   if (!groupedArrays[item.event_date]) {
    //     groupedArrays[item.event_date] = [];
    //   }
    //   // let newitem = { "event_date": item.event_date, "event_day": item.event_day, "details": item }
    //   groupedArrays[item.event_date].push(newitem);
    // });

    // Now, groupedArrays will hold the arrays grouped by location
    // return groupedArrays;
    let i = 0;
    const separateArrays = sortedData.reduce((acc, item) => {
      if (!acc[item.event_date]) {
        i = i + 1;
        acc[item.event_date] = {
          id: i,
          eventDate: formatDate(item.event_date), // Store the date as the heading
          eventDay: item.event_day, // Store the date as the heading
          items: [],
        };
      }
      acc[item.event_date].items.push(item);
      return acc;
    }, {});

    return Object.values(separateArrays);
  };

  // const first = (result[0]);

  // Get the Program Details
  const fetchProgramDetails = async () => {
    try {
      const { data } = await getProgramDetails(eventId);
      const result = splitDays(data.programDetails);
      setProgramDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProgramDetails().finally(() => setLoading(false));
  }, [eventId]);

  return (
    <div className="mt-2">
      {programDetails.length === 0 && (
        <h6 className="fw-normal">
          {" "}
          Detailed Program Agenda will be published Soon
        </h6>
      )}
      {programDetails.length > 0 && <h5> Program Details</h5>}
      {programDetails &&
        programDetails.map((dayDetails) => {
          return (
            <div key={dayDetails.id} className="mt-2">
              <div className="h5-responsive">
                {dayDetails.eventDay} ({dayDetails.eventDate})
              </div>
              {dayDetails.items.map((item) => {
                return (
                  <div key={item.id} className="row">
                    <div className="col-md-4 my-1">
                      <div className="font-responsive">
                        <span>
                          {formatTime(item.from_time)} -{" "}
                          {formatTime(item.to_time)}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="font-responsive">{item.description}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};
export default EventProgramPrint;
