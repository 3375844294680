import { useState, useEffect } from "react";
import { addSponsorshipResp, getSponsorshipReqs } from "../../../api/authApis";
import {
  getSponsorshipResp,
  deleteSponsorshipResp,
} from "../../../api/signupApis";

const initializeFormValues = {
  eventId: "",
  sponsorship_level: "",
  sponsorship_amt: "",
  comments: "",
};

const SponsorshipForm = ({
  handleSubmit,
  onChange,
  values,
  sponsorshipReqs,
}) => {
  return (
    <div className="row align-items-center mb-2">
      <span className="fw-bold my-2 accent3">Become a Sponsor! </span>
      <div className="col-md-12">
        <form onSubmit={handleSubmit}>
          <div className="row align-items-top form-wrapper-gen">
            <div className="col-md-3 form-group">
              <label
                htmlFor="sponsorship_level"
                className="form-label fw-bold fl-fs-8"
              >
                Level
              </label>
              <select
                id="sponsorship_level"
                name="sponsorship_level"
                className="form-control form-input fc-fs-8 w-50"
                onChange={(e) => onChange(e)}
                value={values.sponsorship_level}
                required
              >
                <option value="" disabled>
                  Select Level
                </option>
                {sponsorshipReqs &&
                  sponsorshipReqs?.map((req) => {
                    return (
                      <option key={req.id} value={req.sponsorship_level}>
                        {req.sponsorship_level}
                      </option>
                    );
                  })}
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="col-md-3 form-group">
              <label
                htmlFor="sponsorship_amt"
                className="form-label fw-bold fl-fs-8"
              >
                Amount
              </label>
              <input
                type="number"
                min="1"
                max="100000"
                disabled={values.sponsorship_level !== "Other"}
                value={values.sponsorship_amt}
                onChange={onChange}
                className="form-control form-input fc-fs-8 w-50"
                id="sponsorship_amt"
                name="sponsorship_amt"
                required
              />
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="comments" className="form-label fw-bold fl-fs-8">
                Comments
              </label>
              <textarea
                id="comments"
                name="comments"
                rows="2"
                cols="50"
                value={values.comments}
                onChange={onChange}
                className="form-control form-input fc-fs-8"
                placeholder="Enter any comments"
              ></textarea>
            </div>
            <div className="col-md-12 action-buttons">
              <button type="submit" className="btn app-btn-primary-sm me-3">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const SponsorshipTable = ({ sponsorshipResp, handleCancelSponsorship }) => (
  <div className="mt-4">
    <div className="mt-1">
      <span className="h6 fw-bold app-fs-9 mt-2 highlight">
        Thank you for signing up to be a Sponsor!
      </span>
      <table className="table table-striped table-hover table-sm">
        <thead>
          <tr>
            <th scope="col" className="row-text-sm">
              Sponsorship Level
            </th>
            <th scope="col" className="row-text-sm">
              Amount
            </th>
            <th scope="col" className="row-text-sm">
              Comments
            </th>
            <th scope="col" className="row-text-sm">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {sponsorshipResp.map((resp, index) => (
            <tr key={index} className="table-row">
              <td className="align-middle row-text-sm">
                {resp.sponsorship_level}
              </td>
              <td className="align-middle row-text-sm">
                ${resp.sponsorship_amt}
              </td>
              <td className="align-middle row-text-sm">{resp.comments}</td>
              <td
                className="align-middle row-text-sm"
                onClick={() => handleCancelSponsorship(resp.id)}
              >
                <i
                  className="fa fa-trash-o app-icon-delete"
                  data-toggle="tool-tip"
                  title="Delete Row"
                  aria-hidden="true"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr className="app-border-secondary" />
    </div>
  </div>
);

const SponsorshipResp = ({ eventId }) => {
  const [sponsorshipReqs, setSponsorshipReqs] = useState([]);
  const [values, setValues] = useState(initializeFormValues);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sponsorshipResp, setSponsorshipResp] = useState([]);
  const [sponsorshipRespMsg, setSponsorshipRespMsg] = useState("");

  // Get all the Sponsorship requests for the event
  const fetchSponsorshipReqs = async () => {
    let reqkey = {
      eventId: eventId,
    };
    try {
      const { data } = await getSponsorshipReqs(reqkey);
      setSponsorshipReqs(data.sponsorshipReqs);
    } catch (error) {
      console.log(error);
    }
  };

  // Get all the Sponsorship responses for the user
  const fetchSponsorshipResp = async () => {
    let reqkey = {
      eventId: eventId,
    };
    try {
      const { data } = await getSponsorshipResp(reqkey);
      setSponsorshipResp(data.sponsorshipResp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSponsorshipReqs().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchSponsorshipResp().finally(() => setSponsorshipRespMsg(""));
  }, [sponsorshipRespMsg]);

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "sponsorship_level") {
      let sponsorshipAmt =
        value === "Other"
          ? 20
          : sponsorshipReqs?.find((req) => req.sponsorship_level === value)
              ?.amount;
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        sponsorship_amt: sponsorshipAmt,
      }));
    } else {
      setValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(values);
    values.eventId = eventId;
    try {
      const { data } = await addSponsorshipResp(values);
      setSponsorshipRespMsg(data.message);
      // console.log(data);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  // Cancel a signup request - update the quantity to 0
  const handleCancelSponsorship = async (id) => {
    try {
      const response = await deleteSponsorshipResp(id);
      setSponsorshipRespMsg(response.message);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Oops Something went wrong! We're on it.</div>;
  }

  return (
    <div className="p-2">
      <div>
        <SponsorshipForm
          handleSubmit={handleSubmit}
          onChange={onChange}
          values={values}
          sponsorshipReqs={sponsorshipReqs}
        />
      </div>
      <hr className="app-border-secondary" />
      {sponsorshipResp.length > 0 ? (
        <SponsorshipTable
          sponsorshipResp={sponsorshipResp}
          handleCancelSponsorship={handleCancelSponsorship}
        />
      ) : null}
    </div>
  );
};

export default SponsorshipResp;
