const FormSelect = ({
  id,
  name,
  value,
  label,
  onChange,
  options,
  error,
  colSpan,
  required,
}) => {
  return (
    <div className={`${colSpan} mb-2`}>
      <label htmlFor={id} className="form-label fl-fs-9">
        {label}
        <span style={{ color: "red" }}>*</span>
      </label>
      <select
        onChange={onChange}
        value={value}
        className="form-select form-input fc-fs-8"
        id={id}
        name={name}
        aria-label="Default select"
        required={required}
      >
        <option value="" disabled>
          Select {label}
        </option>
        {options &&
          options.map((option) => {
            return (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            );
          })}
      </select>
      {error && <div className="app-text-error-xs">{error}</div>}
    </div>
  );
};

export default FormSelect;
