// Validate Event Form - Step 1
export const validateRSVP = (values) => {
  const errors = validateFirstName({}, values);
  validateLastName(errors, values);
  validateEmail(errors, values);
  validateRSVPCount(errors, values);
  return errors;
};

// validate First name
function validateFirstName(errors = {}, values) {
  if (!values.firstName) {
    errors.firstName = "First Name is required";
  } else if (values.firstName.length > 30) {
    errors.firstName = "First Name must be no more than 30 characters";
  }
  return errors;
}

// validate Last name
function validateLastName(errors = {}, values) {
  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  } else if (values.lastName.length > 30) {
    errors.lastName = "Last Name must be no more than 30 characters";
  }
  return errors;
}

// validate email
function validateEmail(error = {}, values) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!values.email) {
    error.email = "Email is required";
  } else if (values.email.includes(" ")) {
    error.email = "Invalid email";
  } else if (!emailPattern.test(values.email)) {
    error.email = "Email format is incorrect";
  }
  return error;
}

// validate RSVP Count
function validateRSVPCount(error = {}, values) {
  if (!values.adultCount && !values.childrenCount) {
    error.adultCount = "Both Adult and Children count cannot be empty";
  }
  return error;
}
