import {
  addSignupRequest,
  updateSignupRequest,
  getSignupReqsByCategory,
  deleteSignupReq,
  getSignupReqById,
  getSignupCategoryOptions,
} from "../../api/authApis";
import { useState, useEffect, useRef } from "react";

import { dateRangeList } from "../helpers/dateRangeList";
import { validateVolunteerReq } from "../helpers/validateSignupReq";
import ItemAutoComplete from "./itemAutoComplete";
import SignupRequestsTable from "./signupRequestsTable";

const VolunteerRequestForm = ({
  dropdownRef,
  handleSubmit,
  onChange,
  values,
  showOptions,
  filteredOptions,
  handleSelectOption,
  dateList,
  error,
  errors,
  success,
  updateItem,
  handleCancelUpdate,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <h6 className="card-title">Request Volunteers</h6>
        <form onSubmit={handleSubmit}>
          <div className="row form-wrapper-gen g-2">
            <div className="col-md-12">
              <div className="form-group" ref={dropdownRef}>
                <label htmlFor="item" className="col-form-label fl-fs-8">
                  Service Area
                </label>
                <input
                  type="text"
                  onChange={(e) => onChange(e)}
                  value={values.item}
                  className="form-control form-input fc-fs-7 hide-placeholder"
                  id="item"
                  name="item"
                  placeholder="eg: Registration Desk"
                  required
                />
                <ItemAutoComplete
                  showOptions={showOptions}
                  filteredOptions={filteredOptions}
                  handleSelectOption={handleSelectOption}
                />
              </div>
              {errors?.item && (
                <div className="app-text-error-xs">{errors?.item}</div>
              )}
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label
                  htmlFor="specialInstructions"
                  className="col-form-label fl-fs-8"
                >
                  Brief Description of Tasks
                </label>
                <textarea
                  onChange={(e) => onChange(e)}
                  value={values.specialInstructions}
                  id="specialInstructions"
                  name="specialInstructions"
                  rows="2"
                  className="form-control form-input fc-fs-7 hide-placeholder"
                  placeholder="eg: Volunteers are needed at the registration desk to help with registering guests and answer any questions"
                ></textarea>
                {errors?.specialInstructions && (
                  <div className="app-text-error-xs">
                    {errors?.specialInstructions}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-1">
                <label htmlFor="eventDate" className="col-form-label fl-fs-8">
                  Event Date
                </label>
                <select
                  className="form-select form-input fc-fs-7"
                  onChange={(e) => onChange(e)}
                  value={values.eventDate}
                  id="eventDate"
                  name="eventDate"
                  aria-label="Default select"
                  required
                >
                  <option value="" disabled>
                    Please Select
                  </option>
                  {dateList &&
                    dateList.map((dt) => {
                      return (
                        <option key={dt.id} value={dt.event_date}>
                          {dt.event_date_display}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="quantity" className="col-form-label fl-fs-8">
                  # of Volunteers
                </label>
                <input
                  type="number"
                  onChange={(e) => onChange(e)}
                  value={values.quantity}
                  className="form-control form-input fc-fs-7"
                  id="quantity"
                  name="quantity"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-1">
                <label htmlFor="fromTime" className="col-form-label fl-fs-8">
                  From Time
                </label>
                <input
                  type="time"
                  onChange={(e) => onChange(e)}
                  value={values.fromTime}
                  className="form-control form-input fc-fs-7"
                  id="fromTime"
                  name="fromTime"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="toTime" className="col-form-label fl-fs-8">
                  To Time
                </label>
                <input
                  type="time"
                  onChange={(e) => onChange(e)}
                  value={values.toTime}
                  className="form-control form-input fc-fs-7"
                  id="toTime"
                  name="toTime"
                  required
                />
              </div>
            </div>
            <div>
              <span className="app-fs-8 text-danger">{error}</span>
              <span className="app-fs-8 text-success">{success}</span>
            </div>
            <div className="col-md-12 mt-1 action-buttons d-flex">
              <button type="submit" className="btn btn-responsive">
                {updateItem ? "Update" : "Create"}
              </button>
              <button
                type="button"
                className="btn btn-responsive mx-2"
                hidden={!updateItem}
                onClick={handleCancelUpdate}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const VolunteerSignupReq = ({ item, dateList }) => {
  const { id, from_date, to_date } = item;
  const eventId = id;
  const [error, setError] = useState("");
  const [signupReqs, setSignupReqs] = useState([]);
  const [success, setSuccess] = useState("");
  const [updateItem, setUpdateItem] = useState(false);

  const initializeForm = {
    id: "",
    category: "Volunteers",
    eventId: eventId,
    item: "",
    quantity: "",
    unitMeasure: "Volunteers",
    eventDate: new Date(from_date),
    fromTime: "",
    toTime: "",
    specialInstructions: "",
  };

  const [values, setValues] = useState(initializeForm);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [refreshOptions, setRefreshOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Add the new request to the array to be displayed
  const addSignupReq = (signupReq) => {
    if (signupReqs.indexOf(signupReq) === -1) {
      setSignupReqs([...signupReqs, signupReq]);
    }
  };

  // Update the  request to the array to be displayed
  const updateSignupReq = (signupReq) => {
    const updatedItems = signupReqs.map((item) => {
      if (item.id === signupReq.id) {
        return { ...item, item: signupReq.item, quantity: signupReq.quantity };
      }
      return item;
    });

    setSignupReqs(updatedItems);
  };

  // Load the selected request from the database
  const loadSignupReq = async (id) => {
    setError("");
    setSuccess("");
    try {
      let reqkey = {
        id: id,
      };
      const response = await getSignupReqById(reqkey);
      // console.log(response.data.signupReq)
      const signupReq = response.data.signupReq;
      // const formattedEventDate = new Date(signupReq.event_date).toDateString();
      const formattedEventDate = new Date(signupReq.event_date);
      // let event_date = new Date(signupReq.eventdate).toDateString();
      setValues({
        id: signupReq.id,
        category: signupReq.category,
        eventId: signupReq.event_id,
        item: signupReq.item,
        quantity: signupReq.quantity,
        unitMeasure: signupReq.unit_measure,
        eventDate: formattedEventDate,
        fromTime: signupReq.from_time,
        toTime: signupReq.to_time,
        specialInstructions: signupReq.special_instructions,
      });
      setUpdateItem(true);
    } catch (error) {
      console.log(error);
    }
  };

  // Delete a request from the database
  const removeSignupReq = async (id) => {
    try {
      const response = await deleteSignupReq(id);
      // console.log(response)
      setValues(initializeForm);
      setUpdateItem(false);
      setSignupReqs(
        signupReqs.filter((req) => {
          return req.id !== id;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Cancel Update Item
  const handleCancelUpdate = () => {
    setUpdateItem(false);
    setValues(initializeForm);
  };

  // Get all the supplies request for the event
  const fetchData = async () => {
    let reqkey = {
      category: "Volunteers",
      eventId: eventId,
    };
    try {
      // console.log(reqkey)
      const { data } = await getSignupReqsByCategory(reqkey);
      // console.log(data)
      setSignupReqs(data.signupReqs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData().finally(() => setLoading(false));
  }, []);

  const fetchCategoryOptions = async () => {
    const signupCategory = "Volunteers";
    try {
      const { data } = await getSignupCategoryOptions(signupCategory);
      setCategoryOptions(data.options);
      // console.log(data.options)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategoryOptions();
  }, [refreshOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef = useRef(null);

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "eventDate") {
      setValues({ ...values, [name]: new Date(value) });
    } else {
      setValues({ ...values, [name]: value });
    }
    if (name === "item") {
      setSelectedOption(null); // Reset selected option on input change
      setShowOptions(value.length >= 3);
    }
  };

  const handleSelectOption = (name) => {
    setValues((prevValues) => ({ ...prevValues, item: name }));
    setSelectedOption(name);
    setShowOptions(false);
  };

  const filteredOptions = categoryOptions.filter((item) =>
    item.option.toString().toLowerCase().startsWith(values.item.toLowerCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(values);
    setErrors({});
    setError("");
    setSuccess("");
    const errors = validateVolunteerReq(values);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      console.log(errors);
      return;
    }
    setShowOptions(false);
    try {
      if (values.id > 0) {
        const { data } = await updateSignupRequest(values);
        updateSignupReq(data.signupReq);
        setSuccess(data.message);
        setUpdateItem(false);
        setRefreshOptions(data.newOption);
      } else {
        const { data } = await addSignupRequest(values);
        addSignupReq(data.signupReq);
        setSuccess(data.message);
        setRefreshOptions(data.newOption);
      }
      setValues(initializeForm);
      setError("");
    } catch (error) {
      console.log(error);
      setSuccess("");
      setError(
        "Oops Something went wrong! Signup Request could not be created."
      );
    }
  };

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display Error state
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="row mt-3">
      <div className="col-lg-7">
        {/* <!-- Left column content --> */}
        <VolunteerRequestForm
          dropdownRef={dropdownRef}
          handleSubmit={handleSubmit}
          onChange={onChange}
          values={values}
          showOptions={showOptions}
          filteredOptions={filteredOptions}
          handleSelectOption={handleSelectOption}
          dateList={dateList}
          error={error}
          errors={errors}
          success={success}
          updateItem={updateItem}
          handleCancelUpdate={handleCancelUpdate}
        />
      </div>
      <div className="col-lg-5">
        {/* <!-- Right column content --> */}
        <SignupRequestsTable
          sectionHeading="Volunteer Requests"
          columnHeaders={["Area", "#", "Action"]}
          signupReqs={signupReqs}
          removeSignupReq={removeSignupReq}
          loadSignupReq={loadSignupReq}
        />
      </div>
    </div>
  );
};

export default VolunteerSignupReq;
