import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { verifyOTPCode } from "../../api/authApis";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveComponentsLogin,
  setActiveComponentsRegister,
} from "../../redux/slices/authSlice";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.email);

  const activeComponentsRegister = useSelector(
    (state) => state.auth.activeComponentsRegister
  );
  const activeComponentsLogin = useSelector(
    (state) => state.auth.activeComponentsLogin
  );

  // Make all the components active = false
  let activeComponentsRegisterNew = Object.assign(
    {},
    ...Object.entries(activeComponentsRegister).map(([k, v]) => ({
      [k]: false,
    }))
  );

  let activeComponentsLoginNew = Object.assign(
    {},
    ...Object.entries(activeComponentsLogin).map(([k, v]) => ({ [k]: false }))
  );

  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [values, setValues] = useState({
    code: "",
    step: "registration",
  });

  const resendOTP = () => {
    dispatch(
      setActiveComponentsRegister({
        ...activeComponentsRegisterNew,
        resendOTP: true,
      })
    );
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(values);
    try {
      const { data, status } = await verifyOTPCode(values);
      // console.log(status)
      if (status === 204) {
        setStatus("");
        dispatch(
          setActiveComponentsLogin({ ...activeComponentsLoginNew, login: true })
        );
        navigate("/login");
      }
    } catch (error) {
      // console.log(error.response.data)
      if (error.response.data.message === "Invalid OTP Code") {
        setStatus("Invalid OTP Code");
      }
      if (error.response.data.error === "Unauthorized") {
        setStatus("Unauthorized");
      }
      setError(error.response.data.error);
      // console.log(error.response.data)
    }
  };

  if (error) {
    return <h1>Oops Something went wrong!!</h1>;
  }

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <div className="title text-center">
          <h6>Enter the verification code we sent to</h6>
          <h6>{email}</h6>
        </div>
        <form onSubmit={(e) => onSubmit(e)} className="text-center">
          <div className="form-group textbox mb-2">
            <input
              type="text"
              onChange={(e) => onChange(e)}
              id="code"
              name="code"
              value={values.code}
              className="form-control form-input"
              placeholder="OTP Code"
              required
            />
          </div>
          <div className="mb-2">
            <div className="app-fs-8" style={{ color: "red" }}>
              {status}
            </div>
            <button className="btn app-btn-primary w-100" type="submit">
              Confirm Registration
            </button>
          </div>
          <div className="mb-2">
            <span className="app-fs-8">
              Did not receive OTP? <Link onClick={resendOTP}>Resend</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};
export default VerifyOTP;
