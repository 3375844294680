import { BsCalendarFill, BsGeoAltFill } from "react-icons/bs";
import { formatDate, formatTime } from "../../helpers/formatDateTime";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponentsDashboard } from "../../../redux/slices/dashboardSlice";

const EventHeaderShort = ({ item }) => {
  const dispatch = useDispatch();
  const {
    id,
    event_name,
    from_date,
    to_date,
    from_time,
    to_time,
    pic_caption,
    organized_by,
    organizer_initials,
    location,
    description,
  } = item;

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const backToList = () => {
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        eventList: true,
      })
    );
  };

  function displayDate() {
    return from_date === to_date
      ? formatDate(from_date)
      : formatDate(from_date) + " - " + formatDate(to_date);
  }

  function displayTime() {
    return formatTime(from_time) + " - " + formatTime(to_time);
  }

  return (
    <div>
      {item && (
        <section className="pt-0">
          {/* Row Start */}
          <div className="row g-4 g-lg-5">
            {/* <!-- Main content START --> */}
            <div className="col-lg-12">
              <div className="w-100 mt-auto">
                <div className="row p-0 p-sm-3">
                  <div className="col-12">
                    <div className="d-flex pb-2 justify-content-between align-items-center">
                      <h3>{event_name}</h3>
                      <i
                        className="fa fa-arrow-circle-left fa-2x btn-back"
                        aria-hidden="true"
                        onClick={backToList}
                      ></i>
                    </div>
                    {/* <!-- Event Name --> */}
                    <p className="mb-0">{pic_caption}</p>
                  </div>
                </div>
              </div>
              {/* <!-- Organization and time --> */}
              <div className="bg-light rounded-3 p-3 mb-3">
                <div className="row g-4">
                  {/* <!-- Organization --> */}
                  <div className="col-md-4">
                    <h6 className="small">Organized by:</h6>
                    <div className="d-flex align-items-center">
                      {/* <!-- Avatar --> */}
                      <div className="avatar align-middle">
                        <div className="avatar-img rounded-1 bg-danger">
                          <span className="text-white position-absolute top-50 start-50 translate-middle fw-bold app-fs-8">
                            {organizer_initials}
                          </span>
                        </div>
                      </div>
                      {/* <!-- Info --> */}
                      <div className="ms-2">
                        <h6 className="mb-0">{organized_by}</h6>
                        <small></small>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Location --> */}
                  <div className="col-md-4">
                    <h6 className="small">Location:</h6>
                    <div className="d-flex align-items-center">
                      {/* <!-- Avatar --> */}
                      <div className="avatar align-middle flex-shrink-0">
                        <div className="avatar-img rounded-2 bg-danger">
                          <span className="position-absolute top-50 start-50 translate-middle text-white">
                            <BsGeoAltFill />
                          </span>
                        </div>
                      </div>
                      <h6 className="fw-normal mb-0 ms-2">{location}</h6>
                    </div>
                  </div>

                  {/* <!-- Date & Time --> */}
                  <div className="col-md-4">
                    <h6 className="small">Date &amp; Time:</h6>
                    <div className="d-flex align-items-center">
                      {/* <!-- Avatar --> */}
                      <div className="avatar align-middle flex-shrink-0">
                        <div className="avatar-img rounded-2 bg-danger">
                          <span className="position-absolute top-50 start-50 translate-middle text-white">
                            <BsCalendarFill />
                          </span>
                        </div>
                      </div>
                      <div className="ms-2">
                        <h6 className="fw-normal app-fs-7 mb-0">
                          {displayDate()}
                        </h6>
                        <small className="app-fs-7">{displayTime()}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content --> */}
              {/* <h4>About this event</h4>
                <p>{item.description}</p> */}
            </div>
            {/* <!-- Main content END --> */}
          </div>
          {/* Row End */}
        </section>
      )}
      {/* <hr className="app-border-secondary" /> */}
    </div>
  );
};

export default EventHeaderShort;
