import Layout from "../components/layout/layout";
import {
  BsCalendarFill,
  BsGeoAltFill,
  BsGlobe,
  BsPersonPlus,
  BsPeople,
} from "react-icons/bs";
import SignupReqs from "../components/event/signupReqs";
import SponsorshipReqs from "../components/event/sponsorshipReqs";
import {
  getEventById,
  updateEventViewCount,
  getEventRSVPCount,
} from "../api/authApis";
import { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router";
import ModalRSVP from "../components/event/modalRSVP";
import { formatDate, formatTime } from "../components/helpers/formatDateTime";
import EventSchedule from "../components/event/eventSchedule";
import DOMPurify from "dompurify";
import { decode } from "html-entities";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../redux/slices/dashboardSlice";

// EventHeader
export const EventHeader = ({ eventData, eventImage }) => {
  const linkToCopy = `${process.env.REACT_APP_EVENT_URL}${eventData.id}`;
  const buttonRef = useRef();

  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      button.addEventListener("click", async () => {
        try {
          await navigator.clipboard.writeText(linkToCopy);
          alert(
            "Event Link is copied to the clipboard! You can now share it with others."
          );
        } catch (err) {
          alert("Failed to copy text");
        }
      });
    }
    return () => {
      if (button) {
        button.removeEventListener("click", () => {
          navigator.clipboard.writeText(linkToCopy);
        });
      }
    };
  }, []);

  const { event_name, pic_caption, city, state, zip_code } = eventData;
  return (
    <section className="pt-0 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <!-- Card item START --> */}
            <div className="card">
              {/* <img className="overflow-hidden h-400px rounded-circle" src={eventDefaultImage} alt="" /> */}
              <img
                className="overflow-hidden h-450px"
                src={eventImage}
                alt=""
              />
              {/* <!-- Background dark overlay --> */}
              <div className="bg-overlay bg-dark opacity-6"></div>

              {/* <!-- Card image overlay --> */}
              <div className="card-img-overlay d-flex align-items-start flex-column">
                {/* <!-- Card overlay Top --> */}
                <div className="w-100 mb-auto d-flex justify-content-end">
                  <button ref={buttonRef} className="btn app-btn-white mb-0">
                    <i className="bi bi-share"></i> Share
                  </button>
                  {/* <button className="btn btn-sm app-btn-white mb-0">
                    <i className="bi bi-share "></i> Share
                  </button> */}
                </div>
                {/* <!-- Card overlay bottom --> */}
                <div className="w-100 mt-auto">
                  <div className="row p-0 p-sm-3">
                    <div className="col-11 col-lg-7">
                      {/* <!-- Title --> */}
                      <h1 className="text-white">{event_name}</h1>
                      <p className="text-white mb-0">{pic_caption}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Card item END --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

// EventLogistics
export const EventLogistics = ({ eventData, displayDate, displayTime }) => (
  <div className="bg-light rounded-3 p-3 mb-4">
    <div className="row g-4">
      {/* <!-- Organization --> */}
      <div className="col-md-4">
        <h6>Organized by:</h6>
        <div className="d-flex align-items-center">
          {/* <!-- Avatar --> */}
          <div className="avatar align-middle">
            <div className="avatar-img rounded-1 bg-danger">
              <span className="text-white position-absolute top-50 start-50 translate-middle fw-bold app-fs-8">
                {eventData?.organizer_initials}
              </span>
            </div>
          </div>
          {/* <!-- Info --> */}
          <div className="ms-2">
            <h6 className="mb-0">{eventData.organized_by}</h6>
            <small></small>
          </div>
        </div>
      </div>

      {/* <!-- Location --> */}
      <div className="col-md-4">
        <h6>Location:</h6>
        <div className="d-flex align-items-center">
          {/* <!-- Avatar --> */}
          <div className="avatar align-middle flex-shrink-0">
            <div className="avatar-img rounded-2 bg-danger">
              <span className="position-absolute top-50 start-50 translate-middle text-white">
                <BsGeoAltFill />
              </span>
            </div>
          </div>
          <h6 className="fw-normal mb-0 ms-2">{eventData.location}</h6>
        </div>
      </div>

      {/* <!-- Date & Time --> */}
      <div className="col-md-4">
        <h6>Date &amp; Time:</h6>
        <div className="d-flex align-items-center">
          {/* <!-- Avatar --> */}
          <div className="avatar align-middle flex-shrink-0">
            <div className="avatar-img rounded-2 bg-danger">
              <span className="position-absolute top-50 start-50 translate-middle text-white">
                <BsCalendarFill />
              </span>
            </div>
          </div>
          <div className="ms-2">
            <div className="small mb-0">{displayDate}</div>
            <small>{displayTime}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const RSVPButton = ({ disableRsvp, eventId, setRSVPMsg }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <button
        className="btn btn-responsive w-100"
        onClick={openModal}
        disabled={disableRsvp}
      >
        RSVP Here
      </button>
      <ModalRSVP
        isOpen={modalIsOpen}
        onClose={closeModal}
        eventId={eventId}
        setRSVPMsg={setRSVPMsg}
      />
    </>
  );
};

export const EventArchived = () => (
  <div className="alert alert-danger" role="alert">
    This event is archived and no longer available for RSVP.
  </div>
);

export const EventCancelled = () => (
  <div className="alert alert-danger" role="alert">
    This event is cancelled and no longer available for RSVP.
  </div>
);

// Signup Buttons
export const SignupButtons = ({
  eventId,
  setRSVPMsg,
  disableRsvp,
  disableSignup,
  redirectToSignup,
}) => (
  <div className="d-flex justify-content-between mb-4">
    <div className="w-50 me-3">
      <RSVPButton
        disableRsvp={disableRsvp}
        eventId={eventId}
        setRSVPMsg={setRSVPMsg}
      />
    </div>
    <div className="w-50 me-3">
      <button
        className="btn btn-responsive w-100"
        onClick={redirectToSignup}
        disabled={disableSignup}
      >
        Signup Here
      </button>
    </div>
  </div>
);

// EventDescription
export const EventDescription = ({ eventData }) => (
  <div
    className="mb-4"
    id="eventDescription"
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(decode(eventData.description)),
    }}
  />
);

// EventCounter
export const EventCounter = ({ viewCount, rsvpCount, attendanceCount }) => (
  <div className="bg-light rounded-3 p-3 mb-4">
    <div className="row g-2">
      <div className="col-sm-4">
        {/* <!-- Visitors --> */}
        <div className="d-flex">
          <BsGlobe className="fs-4 app-text-primary" />
          <div className="ms-3">
            <h5 className="mb-0">{viewCount}</h5>
            <p className="mb-0">Visitors</p>
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        {/* <!-- Registered --> */}
        <div className="d-flex">
          <BsPersonPlus className="fs-4" />
          <div className="ms-3">
            <h5 className="mb-0">{rsvpCount}</h5>
            <p className="mb-0">RSVP</p>
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        {/* <!-- Attendance --> */}
        <div className="d-flex">
          <BsPeople className="fs-4" />
          <div className="ms-3">
            <h5 className="mb-0">{attendanceCount}</h5>
            <p className="mb-0">Attendance</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// EventFlyerLink
export const EventFlyerLink = ({ eventId }) => (
  <Link
    to={`/eventFlyer/${eventId}`}
    target="_blank"
    className="btn btn-responsive me-3 d-none d-lg-inline"
  >
    View Event Flyer
  </Link>
);

const EventAddress = ({ eventData }) => {
  const { address_line1, address_line2, city, state, zip_code, event_details } =
    eventData;

  let fullAddress = `${address_line1 || ""}${
    address_line2 ? ", " + address_line2 : ""
  }${city ? ", " + city : ""}${state ? ", " + state : ""}${
    zip_code ? ", " + zip_code : ""
  }`;

  return (
    <div className="mt-3">
      <ul className="list-unstyled mb-4">
        <li className="d-flex justify-content-between mb-3">
          <span className="w-150px">Address:</span>
          <h6 className="text-end mb-0">{fullAddress}</h6>
        </li>
      </ul>
      <LocationMap address={fullAddress} />
    </div>
  );
};

// EventRSVP.jsx
export const EventRSVP = ({
  eventData,
  disableRsvp,
  eventId,
  setRSVPMsg,
  RSVPMsg,
}) => {
  return (
    <div className="card card-body shadow">
      <h5 className="card-title">
        <RSVPButton
          disableRsvp={disableRsvp}
          eventId={eventId}
          setRSVPMsg={setRSVPMsg}
        />
      </h5>
      <div id="rsvpmsg" className="app-text-success">
        {RSVPMsg}
      </div>
      <EventAddress eventData={eventData} />
    </div>
  );
};

const LocationMap = ({ address }) => {
  // const address = `${props.winery.address} ${props.winery.city}, ${props.winery.state} ${props.winery.zip}`;
  // const address = "5503 E Danbury Rd, Scottsdale, AZ 85254";
  // "1600 Amphitheatre Parkway, Mountain View, California, United States";
  const src = `https://maps.google.com/maps?&q="+${address}"&output=embed&&loading=async&callback=initMap`;
  const Embed = () => {
    return (
      <div>
        <iframe
          className="w-100 d-block rounded-bottom"
          width="100%"
          height="250"
          aria-hidden="false"
          tabIndex="0"
          allowFullScreen=""
          loading="lazy"
          // loading="async"
          referrerPolicy="no-referrer-when-downgrade"
          src={src}
        ></iframe>
      </div>
    );
  };

  return (
    <>
      <Embed />
    </>
  );
};

const EventDetails = () => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [viewCount, setViewCount] = useState();
  const [rsvpCount, setRsvpCount] = useState();
  const [attendanceCount, setAttendanceCount] = useState();
  const [eventData, setEventData] = useState(null);
  const [displayDate, setDisplayDate] = useState();
  const [displayTime, setDisplayTime] = useState();
  const [eventImage, setEventImage] = useState();
  const [RSVPMsg, setRSVPMsg] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rowNotFound, setRowNotFound] = useState(false);
  const [disableRsvp, setDisableRsvp] = useState(false);
  const [disableSignup, setDisableSignup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.keys(
    activeComponentsDashboard
  ).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});

  // Define your function outside the component or memoize it with useCallback
  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const redirectToSignup = () => {
    // console.log(eventData);
    dispatch(setItem(eventData));
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        signup: true,
      })
    );
    navigate("/dashboard");
  };

  const updateViewCount = async () => {
    try {
      let reqkey = {
        eventId: eventId,
      };
      const { data } = await updateEventViewCount(reqkey);
      // console.log(data);
      setViewCount(data.viewCount);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getRSVPCount = async () => {
    try {
      const { data } = await getEventRSVPCount(eventId);
      // console.log(data);
      if (data.rowCount > 0) {
        setRsvpCount(data.rsvpCounts.rsvp_count);
        setAttendanceCount(data.rsvpCounts.attendance_count);
      }
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const fetchEventDetails = async () => {
    try {
      const { data } = await getEventById(eventId);
      if (data.rowCount === 0) {
        setRowNotFound(true);
      } else {
        setEventData(data.event);
        // if (
        //   data.event.is_over === true ||
        //   data.event.status === "Cancelled" ||
        //   data.event.is_archived === true ||
        //   data.event?.event_details?.disable_rsvp === true
        // ) {
        //   setDisableRsvp(true);
        // } else {
        //   setDisableRsvp(false);
        // }
        setDisableRsvp(
          data.event.is_over === true ||
            data.event.status === "Cancelled" ||
            data.event.is_archived === true ||
            data.event?.event_details?.disable_rsvp === true
        );
        // data.event.is_over ? true : data.event.is_cancelled ? true : false
        // data.event.event_details
        //   ? eventData.event_details.disable_rsvp
        //     ? eventData.event_details.disable_rsvp
        //     : true
        //   : true
        // );
        setDisableSignup(
          data.event.is_over === true ||
            data.event.status === "Cancelled" ||
            data.event.is_archived === true ||
            data.event?.event_details?.disable_signup === true
        );

        // setDisableSignup(
        //   data.event.event_details
        //     ? eventData.event_details.disable_signup
        //       ? eventData.event_details.disable_signup
        //       : true
        //     : true
        // );
        // console.log(formatDate(data.event.from_date))
        updateViewCount();
        getRSVPCount();
        setDisplayDate(
          data.event.from_date === data.event.to_date
            ? formatDate(data.event.from_date)
            : formatDate(data.event.from_date) +
                " - " +
                formatDate(data.event.to_date)
        );

        setDisplayTime(
          formatTime(data.event.from_time) +
            " - " +
            formatTime(data.event.to_time)
        );

        if (data.event.pic_folder !== null) {
          setEventImage(
            `../${data.event.pic_folder}/${data.event.pic_filename}`
          );
        } else {
          setEventImage(
            `../assets/images/events/defaults/Default-${data.event.category_name}.jpg`
          );
        }
      }
      // console.log(data.event)
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchEventDetails().finally(() => setLoading(false));
  }, [eventId]);

  // Display loading state
  if (loading) {
    return (
      <Layout>
        <div>Loading...</div>
      </Layout>
    );
  }

  // Display error state
  if (error) {
    return (
      <Layout>
        <div>Oops Something went wrong! We're on it.</div>
      </Layout>
    );
  }
  // Display message if event is not found
  if (rowNotFound) {
    return (
      <Layout>
        <div>
          Oops! Something is not right. We cannot find the event. We are on it
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      {eventData && (
        <EventHeader eventData={eventData} eventImage={eventImage} />
      )}
      {eventData && (
        <section className="pt-0 pb-4">
          <div className="container">
            {/* Row Start */}
            <div className="row g-4 g-lg-5">
              {/* <!-- Main content START --> */}
              <div className="col-lg-8 order-1">
                {/* <!-- Organization and time --> */}
                <EventLogistics
                  eventData={eventData}
                  displayDate={displayDate}
                  displayTime={displayTime}
                />

                {/* <!-- Alert if event is archived --> */}
                {eventData.status === "Cancelled" ? (
                  <EventCancelled />
                ) : eventData.is_over || eventData.is_archived ? (
                  <EventArchived />
                ) : (
                  /* <!-- Signup and RSVP buttons - show only on smaller screens --> */
                  <div className="d-md-none">
                    <SignupButtons
                      eventId={eventId}
                      setRSVPMsg={setRSVPMsg}
                      disableRsvp={disableRsvp}
                      disableSignup={disableSignup}
                      redirectToSignup={redirectToSignup}
                    />
                  </div>
                )}

                {/* <!-- Content --> */}
                <EventDescription eventData={eventData} />

                {/* <!-- Event Schedule--> */}
                <EventSchedule eventId={eventId} />

                {/* <!-- Counter --> */}
                <EventCounter
                  viewCount={viewCount}
                  rsvpCount={rsvpCount}
                  attendanceCount={attendanceCount}
                />

                {/* <!-- Flyer Link --> */}
                <EventFlyerLink eventId={eventId} />
              </div>
              {/* <!-- Main content END --> */}

              {/* <!-- Left sidebar START --> */}
              <div className="col-lg-4 order-2 order-lg-2">
                {/* <!-- RSVP and Address Map--> */}
                <EventRSVP
                  eventData={eventData}
                  disableRsvp={disableRsvp}
                  openModal={openModal}
                  modalIsOpen={modalIsOpen}
                  closeModal={closeModal}
                  eventId={eventId}
                  setRSVPMsg={setRSVPMsg}
                  RSVPMsg={RSVPMsg}
                />

                {/* Signup Requests start*/}
                <SignupReqs item={eventData} disableSignup={disableSignup} />
                {/* Signup Requests end*/}

                {/* Sponsorship Requests start*/}
                <SponsorshipReqs
                  item={eventData}
                  disableSignup={disableSignup}
                />
                {/* Sponsorship Requests end*/}
              </div>
              {/* <!-- Left sidebar END --> */}
            </div>
            {/* Row End */}
          </div>
        </section>
      )}
    </Layout>
  );
};
export default EventDetails;
