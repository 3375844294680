import '../assets/styles/login.css';
import Layout from '../components/layout/layout';
import Register from '../components/registration/register';
import VerifyOTP from "../components/registration/verifyOTP";
import ResendOTP from "../components/registration/resendOTP";
import { useSelector } from 'react-redux';

const Registration = () => {

  const { register, resendOTP, verifyOTP } =
    useSelector(state => state.auth.activeComponentsRegister);

  return (
    <Layout>
      {register && (<Register />)}
      {resendOTP && <ResendOTP />}
      {verifyOTP && <VerifyOTP />}
    </Layout>
  );
};


export default Registration;