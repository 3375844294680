import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const CopyToClipboardLink = ({ textToCopy, alertMessage, children }) => {
  const buttonRef = useRef();

  useEffect(() => {
    const button = buttonRef.current;
    const handleClick = async () => {
      try {
        await navigator.clipboard.writeText(textToCopy);
        alert(alertMessage || "Text is copied to the clipboard!");
      } catch (err) {
        alert("Failed to copy text");
      }
    };

    if (button) {
      button.addEventListener("click", handleClick);
    }

    return () => {
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, [textToCopy]);

  return (
    <Link className="text-button" ref={buttonRef}>
      {children}
    </Link>
  );
};

export default CopyToClipboardLink;
