import { useState, useEffect } from "react";
import SuppliesSignupReq from "./suppliesSignupReq";
import VolunteerSignupReq from "./volunteerSignupReq";
import FoodSignupReq from "./foodSignupReq";
import SponsorshipReq from "./sponsorshipReq";
import { formatDate, formatTime } from "../helpers/formatDateTime";
import EventHeaderShort from "./../dashboard/event/eventHeaderShort";
import { dateRangeList } from "../helpers/dateRangeList";

const EventSignupReq = ({ item }) => {
  const { id, from_date, to_date, from_time, to_time } = item;

  const [dateList, setDateList] = useState([]);

  function displayDate() {
    return item.from_date === item.to_date
      ? formatDate(from_date)
      : formatDate(from_date) + " - " + formatDate(to_date);
  }

  function displayTime() {
    return formatTime(from_time) + " - " + formatTime(to_time);
  }

  useEffect(() => {
    // function returns a list of event date range
    setDateList(dateRangeList(from_date, to_date));
    // console.log(dateRangeList(from_date, to_date));
  }, []);

  return (
    <div className="card card-body mt-2 mb-4">
      <EventHeaderShort item={item} />

      <div className="container mt-4">
        <h6 className="mb-3 card-header app-card-header">
          Create Signup Sheet
        </h6>
        <ul className="nav nav-tabs tab-responsive" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active btn-tab-responsive"
              id="food-tab"
              data-bs-toggle="tab"
              data-bs-target="#food"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              <i className="fa fa-cutlery d-block d-sm-none"></i>{" "}
              {/* This icon will only be visible on small screens */}
              <span className="d-none d-sm-block">Food</span>{" "}
              {/* This text will be hidden on small screens */}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link btn-tab-responsive"
              id="supplies-tab"
              data-bs-toggle="tab"
              data-bs-target="#supplies"
              type="button"
              role="tab"
              aria-controls="supplies"
              aria-selected="false"
            >
              <i className="fa fa-cubes d-block d-sm-none"></i>{" "}
              {/* This icon will only be visible on small screens */}
              <span className="d-none d-sm-block">Supplies</span>{" "}
              {/* This text will be hidden on small screens */}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link btn-tab-responsive"
              id="volunteers-tab"
              data-bs-toggle="tab"
              data-bs-target="#volunteers"
              type="button"
              role="tab"
              aria-controls="volunteers"
              aria-selected="false"
            >
              <i className="fa fa-users d-block d-sm-none"></i>{" "}
              {/* This icon will only be visible on small screens */}
              <span className="d-none d-sm-block">Volunteers</span>{" "}
              {/* This text will be hidden on small screens */}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link btn-tab-responsive"
              id="sponsors-tab"
              data-bs-toggle="tab"
              data-bs-target="#sponsors"
              type="button"
              role="tab"
              aria-controls="sponsor"
              aria-selected="false"
            >
              <i className="fa fa-handshake-o d-block d-sm-none"></i>{" "}
              {/* This icon will only be visible on small screens */}
              <span className="d-none d-sm-block">Sponsors</span>{" "}
              {/* This text will be hidden on small screens */}
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="food"
            role="tabpanel"
            aria-labelledby="food-tab"
          >
            {dateList && <FoodSignupReq item={item} dateList={dateList} />}
          </div>
          <div
            className="tab-pane fade"
            id="supplies"
            role="tabpanel"
            aria-labelledby="supplies-tab"
          >
            {dateList && <SuppliesSignupReq item={item} dateList={dateList} />}
          </div>
          <div
            className="tab-pane fade"
            id="volunteers"
            role="tabpanel"
            aria-labelledby="volunteers-tab"
          >
            {dateList && <VolunteerSignupReq item={item} dateList={dateList} />}
          </div>
          <div
            className="tab-pane fade"
            id="sponsors"
            role="tabpanel"
            aria-labelledby="sponsors-tab"
          >
            <SponsorshipReq item={item} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventSignupReq;
