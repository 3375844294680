import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decode } from "html-entities";
import {
  getEventCategories,
  getStatesList,
  updateEvent,
  uploadImage,
} from "../../../api/authApis";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { reset as resetDashboard } from "../../../redux/slices/dashboardSlice";
import { reset as resetEventsTab } from "../../../redux/slices/eventsTabSlice";
import { setEventData } from "../../../redux/slices/eventCreateSlice";
import { purifyHtml, sanitizeText } from "../../helpers/sanitize";
import DOMPurify from "dompurify";
import useImageResize from "../../hooks/useImageResize";
import {
  validateInPerson,
  validateVirtual,
} from "../../helpers/validateEventForm";
import FormInput from "../../formComponents/formInput";
import FormSelect from "../../formComponents/formSelect";
import FormTextArea from "../../formComponents/formTextArea";

const EventEdit = () => {
  const dispatch = useDispatch();
  const eventData = useSelector((state) => state.dashboard.item);
  const {
    resizedImage,
    uploadError,
    resizeError,
    getResizedImage,
    uploadResizedImage,
  } = useImageResize();

  const {
    id = "",
    event_name = "",
    organized_by = "",
    event_type = "",
    category_id = "",
    category_name = "",
    from_date = "",
    to_date = "",
    from_time = "",
    to_time = "",
    numDays = "",
    location = "",
    address_line1 = "",
    address_line2 = "",
    city = "",
    state = "",
    zip_code = "",
    description = "",
    contact_details = "",
    pic_folder = "",
    pic_filename = "",
    pic_caption = "",
    fb_page = "",
    instagram_page = "",
    twitter_handle = "",
    is_private = "",
    is_active,
    status,
    event_details,
    imageUrl = pic_folder + "/" + pic_filename,
  } = eventData || {};

  const { meeting_id, meeting_passcode, meeting_link } = event_details || {};

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const eventsTabActive = useSelector(
    (state) => state.eventsTab.eventsTabActive
  );

  const formattedFromDate = eventData.from_date.toString().split("T")[0];
  const formattedToDate = eventData.to_date.toString().split("T")[0];

  const imgsrc = eventData.pic_folder + "/" + eventData.pic_filename || "";

  const initializeFormData = {
    id: id || "",
    event_name: event_name || "",
    organized_by: organized_by || "",
    event_type: event_type || "",
    category_id: category_id || "",
    category_name: category_name || "",
    from_date: formattedFromDate || from_date || "",
    to_date: formattedToDate || to_date || "",
    from_time: from_time || "",
    to_time: to_time || "",
    numDays: numDays || "",
    location: location || "",
    address_line1: address_line1 || "",
    address_line2: address_line2 || "",
    city: city || "",
    state: state || "",
    zip_code: zip_code || "",
    description: decode(DOMPurify.sanitize(description)) || "",
    contact_details: contact_details || "",
    pic_filename: pic_filename || "",
    pic_caption: pic_caption || "",
    defaultImage: pic_filename.includes("Default-") ? true : false,
    fb_page: fb_page || "",
    instagram_page: instagram_page || "",
    twitter_handle: twitter_handle || "",
    is_private: is_private ? is_private : false,
    is_active: true,
    status: "Published",
    meeting_id: meeting_id || "",
    meeting_passcode: meeting_passcode || "",
    meeting_link: meeting_link || "",
    imageUrl: imageUrl,
    action: "Update",
  };

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  // Make all the components active = false
  let eventsTabActiveNew = Object.assign(
    {},
    ...Object.entries(eventsTabActive).map(([k, v]) => ({ [k]: false }))
  );

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [
        {
          color: [],
        },
      ],
    ],
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const [eventCategories, setEventCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageFileName, setImageFileName] = useState(null);
  const [inputError, setInputError] = useState(null);
  const [values, setValues] = useState(initializeFormData);

  const fetchStatesList = async () => {
    // console.log('fetchStatesList')
    try {
      const { data } = await getStatesList();
      // console.log(data.states)
      setStates(data.states);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEventCategories = async () => {
    try {
      const { data } = await getEventCategories();
      // console.log(data.message)
      setEventCategories(data.categories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEventCategories();
    fetchStatesList();
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    // Convert the value for is_private to boolean if the changed field is is_private
    if (name === "is_private") {
      newValue = value === "private" ? true : false;
    }
    setValues((prevValues) => ({ ...prevValues, [name]: newValue }));
    // setValues({ ...values, [name]: newValue });
  };

  // function changeTimeFormat(time) {
  //   var [h, m] = time.split(":");
  //   return ((h % 12 ? h % 12 : 12) + ":" + m, h >= 12 ? 'PM' : 'AM');
  // }
  const showHideImg = () => {
    if (imageFileName) {
      return "show-img";
    } else {
      return "hide-img";
    }
  };

  const handleFileChange = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);

      if (file?.size > 4718592) {
        // alert('File size exceeds the maximum allowed limit (1 MB).');
        setError("File size exceeds the maximum allowed limit (4 MB).");
        return;
      } else {
        setError("");
      }

      let newFilename;
      if (
        values.pic_filename === "" ||
        values.pic_filename.includes("Default-")
      ) {
        // Generate a random 6-digit number
        const randomNumber = Math.floor(100000 + Math.random() * 900000);
        // Get the file extension
        const fileExtension = file.name.split(".").pop();
        // Create the new filename
        newFilename = `${randomNumber}.${fileExtension}`;
      } else {
        newFilename = values.pic_filename;
      }
      await getResizedImage(file, newFilename, 1344, 900);
    }
  };

  const redirectToMyEvents = () => {
    // console.log("redirectToMyEvents");
    dispatch(setEventData(""));
    dispatch(resetDashboard());
    dispatch(resetEventsTab());
  };

  const handleEditorContentChange = (editorContent) => {
    Object.assign(values, { description: editorContent });
  };

  const handleImageUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    let newFilename;
    if (
      values.pic_filename === "" ||
      values.pic_filename.includes("Default-")
    ) {
      // Generate a random 6-digit number
      const randomNumber = Math.floor(100000 + Math.random() * 900000);
      // Get the file extension
      const fileExtension = selectedFile.name.split(".").pop();
      // Create the new filename
      newFilename = `${randomNumber}.${fileExtension}`;
    } else {
      newFilename = values.pic_filename;
    }

    // Call the upload function with the selected file, desired filename, maxWidth, and optionally maxHeight
    const success = await uploadResizedImage(
      // selectedFile,
      resizedImage,
      newFilename,
      1344 /* maxHeight (optional) */
    );
    values.pic_filename = newFilename;
    values.defaultImage = false;
    return success;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    if (values.event_type === "In person") {
      validationErrors = validateInPerson(values);
    } else if (values.event_type === "Virtual") {
      validationErrors = validateVirtual(values);
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log(validationErrors);
      return;
    }
    try {
      const cleanDescription = sanitizeText(
        DOMPurify.sanitize(values.description)
      );
      const cleanContact = sanitizeText(
        DOMPurify.sanitize(values.contact_details)
      );
      // const cleanText = purifyHtml(values.description);
      setValues((prevValues) => ({
        ...prevValues,
        description: cleanDescription,
        contact_details: cleanContact,
      }));

      if (resizedImage) {
        const success = await handleImageUpload();
        if (success) {
          setError("Upload successful!");
        } else {
          setError(`Upload failed: ${uploadError}`);
          return;
        }
      }

      const response = await updateEvent(values);
      if (response.status === 201) {
        setValues({
          event_name: "",
          organized_by: "",
          event_type: "",
          category_id: "",
          from_date: "",
          to_date: "",
          from_time: "",
          to_time: "",
          numDays: "",
          location: "",
          address_line1: "",
          address_line2: "",
          city: "",
          state: "",
          zip_code: "",
          description: "",
          contact_details: "",
          pic_filename: "",
          pic_caption: "",
          fb_page: "",
          instagram_page: "",
          twitter_handle: "",
        });
      }
      setError("");
      // console.log(response);
      redirectToMyEvents();
    } catch (error) {
      console.log(error);
      // setError(error.response.data.errors[0].msg)
    }
  };

  return (
    <div className="row">
      <div className="col-9 col-md-9">
        <div className="card">
          <div className="card-header justify-content-between align-items-center">
            <span>Update Event Details</span>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row form-wrapper-gen">
                <FormInput
                  id="event_name"
                  name="event_name"
                  label="Event Name"
                  type="text"
                  value={values.event_name}
                  onChange={onChange}
                  error={errors?.event_name}
                  placeholder="Event Name"
                  maxLength="50"
                  colSpan="col-md-12"
                  required
                />

                <FormInput
                  id="organized_by"
                  name="organized_by"
                  label="Organized By"
                  type="text"
                  maxLength="100"
                  value={values.organized_by}
                  onChange={onChange}
                  error={errors?.organized_by}
                  colSpan="col-md-6"
                  required
                />

                <FormSelect
                  id="event_type"
                  name="event_type"
                  label="Event Type"
                  value={values.event_type}
                  onChange={onChange}
                  options={[
                    { id: "In person", name: "In person" },
                    { id: "Virtual", name: "Virtual" },
                  ]}
                  error={errors?.event_type}
                  colSpan="col-md-3"
                  required
                />

                <FormSelect
                  id="category_id"
                  name="category_id"
                  label="Event Category"
                  value={values.category_id}
                  onChange={onChange}
                  options={eventCategories}
                  error={errors?.category_id}
                  colSpan="col-md-3"
                  required
                />
                <FormInput
                  id="from_date"
                  name="from_date"
                  label="From Date"
                  type="date"
                  value={values.from_date}
                  onChange={onChange}
                  error={errors?.from_date}
                  colSpan="col-md-3"
                  required
                />

                <FormInput
                  id="from_time"
                  name="from_time"
                  label="From Time"
                  type="time"
                  value={values.from_time}
                  onChange={onChange}
                  error={errors?.from_time}
                  colSpan="col-md-3"
                  required
                />

                <FormInput
                  id="to_date"
                  name="to_date"
                  label="To Date"
                  type="date"
                  value={values.to_date}
                  onChange={onChange}
                  error={errors?.to_date}
                  colSpan="col-md-3"
                  required
                />

                <FormInput
                  id="to_time"
                  name="to_time"
                  label="To Time"
                  type="time"
                  value={values.to_time}
                  onChange={onChange}
                  error={errors?.to_time}
                  colSpan="col-md-3"
                  required
                />
                <FormInput
                  id="location"
                  name="location"
                  label="Location"
                  type="text"
                  maxLength="100"
                  value={values.location}
                  onChange={onChange}
                  error={errors?.location}
                  colSpan="col-md-4"
                  required
                />
                {values.event_type === "In person" ? (
                  <>
                    <FormInput
                      id="address_line1"
                      name="address_line1"
                      label="Address Line 1"
                      type="text"
                      maxLength="50"
                      value={values.address_line1}
                      onChange={onChange}
                      error={errors?.address_line1}
                      colSpan="col-md-4"
                      required
                    />

                    <FormInput
                      id="address_line2"
                      name="address_line2"
                      label="Address Line 2"
                      type="text"
                      maxLength="30"
                      value={values.address_line2}
                      colSpan="col-md-4"
                      onChange={onChange}
                    />

                    <FormInput
                      id="city"
                      name="city"
                      label="City"
                      type="text"
                      maxLength="30"
                      value={values.city}
                      onChange={onChange}
                      error={errors?.city}
                      colSpan="col-md-4"
                      required
                    />

                    <FormSelect
                      id="state"
                      name="state"
                      label="State"
                      value={values.state}
                      onChange={onChange}
                      options={states.map((state) => ({
                        id: state.state_abbrev,
                        name: state.state_name,
                      }))}
                      error={errors?.state}
                      colSpan="col-md-4"
                      required
                    />
                    <FormInput
                      id="zip_code"
                      name="zip_code"
                      label="Zip Code"
                      type="text"
                      maxLength="10"
                      value={values.zip_code}
                      onChange={onChange}
                      error={errors?.zip_code}
                      colSpan="col-md-4"
                      required
                    />
                  </>
                ) : values.event_type === "Virtual" ? (
                  <>
                    <FormInput
                      id="meeting_id"
                      name="meeting_id"
                      label="Meeting ID"
                      type="text"
                      maxLength="15"
                      value={values.meeting_id}
                      onChange={onChange}
                      error={errors?.meeting_id}
                      colSpan="col-md-4"
                    />
                    <FormInput
                      id="meeting_passcode"
                      name="meeting_passcode"
                      label="Meeting Passcode"
                      type="text"
                      maxLength="15"
                      value={values.meeting_passcode}
                      onChange={onChange}
                      error={errors?.meeting_passcode}
                      colSpan="col-md-4"
                    />
                    <FormTextArea
                      id="meeting_link"
                      name="meeting_link"
                      label="Meeting Link"
                      rows="2"
                      maxLength="150"
                      value={values.meeting_link}
                      onChange={onChange}
                      error={errors?.meeting_details}
                      colSpan="col-md-12"
                    />
                  </>
                ) : null}
                <div className="col-md-12 mt-2 d-flex">
                  <label
                    htmlFor="is_private"
                    className="form-label fl-fs-9 me-2"
                  >
                    Public or Private event?
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="form-check fc-fs-8 me-2 ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_private"
                      id="public"
                      value="public"
                      checked={!values.is_private} // This will be true (checked) when is_private is false
                      onChange={(e) => onChange(e)}
                    />
                    <label
                      className="form-check-label fl-fs-9"
                      htmlFor="public"
                    >
                      Public
                    </label>
                  </div>
                  <div className="form-check fc-fs-8">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_private"
                      id="private"
                      value="private"
                      checked={values.is_private} // This will be true (checked) when is_private is true
                      onChange={(e) => onChange(e)}
                    />
                    <label
                      className="form-check-label fl-fs-9"
                      htmlFor="private"
                    >
                      Private
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-2">
                    <label htmlFor="description" className="form-label fl-fs-9">
                      Event Description<span style={{ color: "red" }}>*</span>
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={values.description}
                      onChange={handleEditorContentChange}
                      modules={{ toolbar: toolbarOptions }}
                      className="ql-editor fc-fs-8"
                    />
                    {/* <RichTextField className="form-control form-input fc-fs-8" onEditorContentChange={handleEditorContentChange} /> */}
                    {/* <textarea onChange={(e) => onChange(e)} id="description" name="description" value={values.description} rows="4" className="form-control form-input fc-fs-8" required></textarea> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-2">
                    <label
                      htmlFor="contact_details"
                      className="form-label fl-fs-9"
                    >
                      Contact Details<span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      onChange={(e) => onChange(e)}
                      id="contact_details"
                      name="contact_details"
                      value={values.contact_details}
                      rows="2"
                      maxLength="250"
                      className="form-control form-input fc-fs-8 hide-placeholder"
                      placeholder="Please scan the QR code to RSVP and Signup for the event. For more information please contact the Organizer"
                      required
                    ></textarea>
                  </div>
                </div>

                <FormInput
                  id="fb_page"
                  name="fb_page"
                  label="FB Page"
                  type="text"
                  maxLength="100"
                  value={values.fb_page}
                  onChange={onChange}
                  colSpan="col-md-4"
                />
                <FormInput
                  id="instagram_page"
                  name="instagram_page"
                  label="Instagram Page"
                  type="text"
                  maxLength="100"
                  value={values.instagram_page}
                  onChange={onChange}
                  colSpan="col-md-4"
                />
                <FormInput
                  id="twitter_handle"
                  name="twitter_handle"
                  label="Twitter Handle"
                  type="text"
                  maxLength="100"
                  value={values.twitter_handle}
                  onChange={onChange}
                  colSpan="col-md-4"
                />
                <div className="col-md-4">
                  <label htmlFor="upload" className="btn app-btn-primary-md">
                    Choose Image
                  </label>
                  <div className="mb-2">
                    <input
                      type="file"
                      id="upload"
                      className="file-input"
                      onChange={handleFileChange}
                      accept="image/gif, image/jpeg, image/png"
                    />
                    <img
                      id="previewImage"
                      src={resizedImage || values.imageUrl}
                      className="img-thumbnail mt-2"
                      style={{ maxWidth: "150px" }}
                      alt=""
                    />
                    <div id="imgSizeHelp" className="text-danger app-fs-7">
                      {inputError}
                    </div>
                    {/* <button type="button" onClick={handleUpload} className="btn btn-app-primary">Upload</button> */}
                    {/* <img id="imagePreview" className={showHideImg} src={imageSrc} style={{ "width": "100px" }} /> */}
                  </div>
                  {/* <div id="picSizeHelp" className="form-text">Image should be of 1920 X 1080 for the flyer to look good</div> */}
                </div>
                <div className="col-md-8">
                  <div className="mb-2">
                    <label htmlFor="pic_caption" className="form-label fl-fs-9">
                      Picture Caption<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      onChange={(e) => onChange(e)}
                      type="text"
                      maxLength="200"
                      id="pic_caption"
                      name="pic_caption"
                      value={values.pic_caption}
                      className="form-control form-input app-fc-8"
                      required
                    />
                    <div id="picCaptionHelp" className="form-text help-text-xs">
                      Caption will overlay the image
                    </div>
                  </div>
                </div>
                <div className="col-md-12 action-buttons">
                  <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
                  <button
                    type="button"
                    className="btn app-btn-primary me-3"
                    onClick={redirectToMyEvents}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={error}
                    className="btn app-btn-primary"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventEdit;
