import { useState } from "react";
import Layout from "../components/layout/layout";

const AboutUs = () => {
  return (
    <Layout>
      <section className="py-4 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 align-self-start p-4">
              <h1>About Us</h1>
              <p>
                Picture this: you are excited about your upcoming event- a
                carefully crafted vision is swirling in your head. But then,
                reality sets in. You need to wrangle RSVPs, manage
                registrations, and navigate the logistical labyrinth of event
                planning. Enter the dreaded Google Forms – clunky, impersonal,
                and prone to spreadsheet tantrums.
              </p>
              <p>
                We have all been there, battling the limitations of generic
                tools and yearning for a more intuitive, streamlined solution.
                That is why we built{" "}
                <span className="app-text-accent1 fw-bold">SignupQR</span>!
              </p>
              <p>
                Born from the ashes of countless Google Form frustrations,{" "}
                <span className="app-text-accent1 fw-bold">SignupQR</span> is an
                event management platform crafted with love and empathy for
                event organizers like you. We understand the pain points, the
                late-night spreadsheet wrangling, and the desperate desire for a
                tool that speaks your language.
              </p>
              <p>
                So, we ditched the unfriendly interfaces and confusing menus for
                user-friendly dashboards, intuitive workflows, and features that
                anticipate your every need - an all in one unique platform.
              </p>
              <p>
                From seamless registration with customizable forms to automated
                email reminders and guest management tools, we empower you to
                focus on what truly matters: creating incredible experiences for
                your attendees.
              </p>
              <p>
                Join the{" "}
                <span className="app-text-accent1 fw-bold">SignupQR</span>{" "}
                revolution and unlock the joy of effortless event management!
              </p>
            </div>
            {/* <div className="col-lg-4 align-self-start"> */}
            <div className="col-lg-4 ms-auto">
              <div className="card">
                <div
                  className="position-relative overflow-hidden"
                  style={{
                    height: "220px",
                    marginTop: "-30px",
                    borderRadius: "10px",
                    // marginLeft: "10px",
                    // marginRight: "-10px",
                  }}
                >
                  <div className="position-absolute w-100 top-0 z-index-1">
                    {/* <!-- Image --> */}
                    <img
                      src="assets/images/landing/about-us5.png"
                      className="img-fluid mw-md-100 img-responsive" // Adjust the width as needed
                      alt="Harness the Power of the Community"
                    />
                  </div>
                </div>
                <div className="card-body">
                  <h4>Harness the Power of the Community</h4>
                  <p>
                    <span className="app-text-accent1 fw-bold">SignupQR</span>{" "}
                    empowers you to create unforgettable experiences and
                    streamline event management.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutUs;
