import QRCode from "qrcode.react";

function QRCodeGenerator({ url }) {
  return (
    <div>
      <div
        style={{
          border: "2px solid #00004F",
          marginTop: "1.2rem",
          display: "inline-block",
        }}
      >
        <QRCode
          value={url}
          renderAs="svg"
          size={160}
          bgColor="#F3722C"
          fgColor="#00004F"
          level="H"
          includeMargin={true}
        />
      </div>
      <div className="app-fs-7">Scan QR Code to RSVP</div>
    </div>
  );
}

export default QRCodeGenerator;
