import { Link, useNavigate } from "react-router-dom";
import { formatDate, formatTime } from "../helpers/formatDateTime";
import { setAuthenticated } from "../../redux/slices/authSlice";
import { addEventInterest } from "../../api/authApis";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getEventRSVPCount(eventId, rsvpData) {
  // console.log(eventId)
  let result = rsvpData?.find((rsvp) => {
    return rsvp.event_id === eventId;
  });
  if (typeof result !== "undefined") {
    return result.rsvp_count;
  } else {
    return 0;
  }
}

const CreateAvatars = ({ eventId, rsvpData }) => {
  // console.log('createAvatars')
  // let rndInt = randomIntFromInterval(1, 6)
  let rndInt = 1;
  let rsvpcount = getEventRSVPCount(eventId, rsvpData);
  var elements = [];
  const avatarCount = rsvpcount > 3 ? 3 : rsvpcount;

  for (let i = 0; i < avatarCount; i++) {
    elements.push(
      <li key={i} className="avatar avatar-xs">
        <img
          className="avatar-img rounded-circle"
          src={`assets/images/avatar/0${rndInt}.jpg`}
          alt="avatar"
        />
      </li>
    );
    rndInt++;
  }

  const AttendingText = () => (
    <li className="ms-3">
      <small className="app-text-accent1"> are attending</small>
    </li>
  );

  rsvpcount === 0
    ? elements.push(
        <li key="no-rsvps">
          <small className="app-text-accent5">There are no RSVPs yet</small>
        </li>
      )
    : rsvpcount > 3
    ? elements.push(
        <React.Fragment key="more-than-3">
          <li className="avatar avatar-xs">
            <div className="avatar-img rounded-circle bg-primary">
              <span className="app-fs-7 text-white position-absolute top-50 start-50 translate-middle">
                +{rsvpcount - 3}
              </span>
            </div>
          </li>
          <AttendingText />
        </React.Fragment>
      )
    : elements.push(<AttendingText key="attending-text" />);

  return elements;
};

const EventImage = ({ event }) => (
  <div className="position-relative event-card-image">
    <Link className="mt-n3" to={`/event/${event.id}`}>
      <img
        className="card-img-top custom-image"
        src={
          event.pic_folder
            ? `${event.pic_folder}/${event.pic_filename}`
            : `../assets/images/events/defaults/Default-${event.category_name}.jpg`
        }
        alt=""
      />
    </Link>
    <div className="badge bg-danger text-white mt-2 me-2 position-absolute top-0 end-0">
      {event?.event_type}
    </div>
  </div>
);

// const EventImage = ({ event }) => (
//   <div className="position-relative">
//     <Link className="mt-n3" to={`/event/${event.id}`}>
//       <img
//         className="img-fluid rounded-top custom-image"
//         src={
//           event.pic_folder
//             ? `${event.pic_folder}/${event.pic_filename}`
//             : `../assets/images/events/defaults/Default-${event.category_name}.jpg`
//         }
//         alt=""
//       />
//     </Link>
//     <div className="badge bg-danger text-white mt-2 me-2 position-absolute top-0 end-0">
//       {event?.event_type}
//     </div>
//   </div>
// );

const EventDetails = ({
  event,
  handleInterest,
  interestedEvents,
  rsvpData,
}) => (
  <div className="card-body position-relative pt-0">
    <Link className="btn btn-responsive mt-n3" to={`/event/${event.id}`}>
      {event.category_name}{" "}
    </Link>
    <h6 className="mt-3">
      <Link to={`/event/${event.id}`}> {event.event_name} </Link>
    </h6>
    <p className="mb-0 small">
      {event.from_date === event.to_date
        ? formatDate(event.from_date)
        : formatDate(event.from_date) + " - " + formatDate(event.to_date)}
    </p>
    <p className="mb-0 small">
      {formatTime(event.from_time) + " - " + formatTime(event.to_time)}
    </p>
    <p className="small"> {event.location} </p>
    <ul className="avatar-group list-unstyled align-items-center mb-0">
      <CreateAvatars eventId={event.id} rsvpData={rsvpData} />
    </ul>
    <div className="d-flex mt-3 justify-content-between">
      <div className="w-100">
        <input
          type="button"
          onClick={(e) => handleInterest(e, event.id)}
          className="btn-check d-block"
          id={event.id}
        />
        <label className="btn app-border-secondary d-block" htmlFor={event.id}>
          <i
            className={`fa-solid fa-thumbs-up me-1 ${
              interestedEvents?.includes(event.id)
                ? "app-text-accent1"
                : "app-text-secondary"
            }`}
          ></i>{" "}
          Interested
        </label>
      </div>
    </div>
  </div>
);

const EventCard = ({
  event,
  interestedEvents,
  setInterestedEvents,
  rsvpData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("isAuth");

  const [error, setError] = useState(null);

  const handleInterest = useCallback(async (e, eventId) => {
    // Prevent the default button click behavior
    e.preventDefault();
    // Here you can add the code to handle the user's interest in the event.
    try {
      const { response } = await addEventInterest(eventId);
      if (!interestedEvents.includes(eventId)) {
        setInterestedEvents((prevState) => [...prevState, eventId]);
      }
      // setEventInterest([...eventInterest, eventId]);
      // console.log(interestedEvents);
    } catch (error) {
      const status = error.response?.status;
      if (status === 401) {
        dispatch(setAuthenticated(false));
        localStorage.removeItem("isAuth");
        navigate("/login");
      }
      setError(error.message);
    }
    // console.log(`User is interested in event with id: ${eventId}`);
  });

  return (
    <div className="col-sm-6 col-xl-4 g-4">
      <div key={event.id} className="card h-100">
        <EventImage event={event} />
        <EventDetails
          event={event}
          handleInterest={handleInterest}
          interestedEvents={interestedEvents}
          rsvpData={rsvpData}
        />
      </div>
    </div>
  );
};

export default EventCard;
