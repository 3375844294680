import "./App.css";
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Home from "./pages/home";
import Dashboard from "./pages/dashboard";
import Authentication from "./pages/authentication";
import Faq from "./pages/faq";
import Features from "./pages/features";
import EventDetails from "./pages/eventDetails";
import EventsHome from "./pages/eventsHome";
import RestrictedRoutes from "./components/utils/restrictedRoutes";
import PrivateRoutes from "./components/utils/privateRoutes";
import Registration from "./pages/registration";
import Testimonials from "./pages/testimonials";
import ContactUs from "./pages/contactUs";
import AboutUs from "./pages/aboutUs";
import EventFlyer from "./pages/eventFlyer";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import ChatHome from "./components/chat/chatHome";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/events" element={<EventsHome />} />
        <Route path="/event/:eventId" element={<EventDetails />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/features" element={<Features />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/eventFlyer/:eventId" element={<EventFlyer />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />

        <Route element={<PrivateRoutes />}>
          {/* <Route path="/event/signup" element={<Dashboard />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/chatHome" element={<ChatHome />} />
        </Route>
        <Route element={<RestrictedRoutes />}>
          <Route path="/register" element={<Registration />} />
          <Route path="/login" element={<Authentication />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
