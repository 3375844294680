import { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  formatDate,
  formatTime,
} from "../../../components/helpers/formatDateTime";
import { useDispatch, useSelector } from "react-redux";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../../redux/slices/dashboardSlice";
import { getMySignups } from "../../../api/signupApis";
import ChatModal from "../../chat/chatModal";

const EventDetails = ({ event, handleActionClick }) => {
  const {
    id: eventId,
    event_name,
    from_date,
    to_date,
    from_time,
    to_time,
    organized_by,
    location,
    is_private,
    status,
    Categories,
  } = event;
  return (
    <div>
      <div>
        <Link
          className="card-subheader app-link-fs-9"
          onClick={() => handleActionClick(event, "eventDetail")}
        >
          <span>{event.event_name}</span>
        </Link>
      </div>
      <div>
        <span className="fw-bold app-fs-8 mr-2">Date & Time: </span>
        <span className="app-fs-8 mr-2">
          {from_date === to_date
            ? formatDate(from_date)
            : formatDate(from_date) + " - " + formatDate(to_date)}
        </span>
        &nbsp;{" "}
        <span className="app-fs-8">
          {formatTime(from_time) + " - " + formatTime(to_time)}
        </span>
      </div>
      <div>
        <span className="fw-bold app-fs-8 mr-2">Organized by: </span>
        <span className="app-fs-8">{organized_by} </span>
      </div>
      <div>
        <span className="fw-bold app-fs-8 mr-2">Location: </span>
        <span className="app-fs-8">{location} </span>
      </div>
      {is_private && (
        <div className="mb-2">
          <span className="fw-bold app-fs-8 highlight">
            This is a Private Event{" "}
          </span>
        </div>
      )}
      {status === "Cancelled" && (
        <div className="mb-2">
          <span className="fw-bold app-fs-8 highlight">
            This Event Has Been Cancelled{" "}
          </span>
        </div>
      )}
    </div>
  );
};

const CategoryItems = ({ category }) => (
  <div>
    <h6 className="fw-bold app-fs-9 mt-2">{category["Category"]}</h6>
    {category["Items"].map((item) => {
      if (item.category === "Volunteers") {
        return (
          <div
            key={item.signup_id}
            className={`d-flex d-sm-flex-column align-items-start ${
              item.quantity === 0 ? "strikethrough" : ""
            }`}
          >
            <div
              className={`app-fs-8 me-2 ${
                item.quantity === 0 ? "strikethrough" : ""
              }`}
            >
              {item.item}:
            </div>
            <div
              className={`app-fs-8 ${
                item.quantity === 0 ? "strikethrough" : ""
              }`}
            >
              {item.quantity} {item.unit_measure}
              <span className="mx-2 app-fs-8">
                {formatTime(item.signup_from_time)} -{" "}
                {formatTime(item.signup_to_time)}
              </span>
            </div>
          </div>
        );
      } else {
        return (
          <div key={item.signup_id} className="d-flex align-items-center mb-1">
            <div
              className={`app-fs-8 me-2 ${
                item.quantity === 0 ? "strikethrough" : ""
              }`}
            >
              {item.item}:
            </div>
            <div
              className={`app-fs-8 ${
                item.quantity === 0 ? "strikethrough" : ""
              }`}
            >
              {item.quantity} {item.unit_measure}
            </div>
          </div>
        );
      }
    })}
  </div>
);

const SignupCard = ({
  event,
  handleActionClick,
  userEmail,
  setStatusMsg,
  modalIsOpen,
  openModal,
  closeModal,
  archived,
}) => {
  const {
    id: eventId,
    event_name,
    from_date,
    to_date,
    from_time,
    to_time,
    organized_by,
    location,
    event_user_id,
    organizer_email,
    organizer_firstname,
    Categories,
  } = event;
  return (
    <div className="card shadow mb-2" key={eventId}>
      <div className="card-body">
        <EventDetails
          key={eventId}
          event={event}
          handleActionClick={handleActionClick}
        />
        {Object.values(Categories).map((category) => (
          <CategoryItems key={category["Category"]} category={category} />
        ))}
        {!archived && (
          <div
            className={`my-2 action-buttons ${
              userEmail === organizer_email ? "hideDiv" : ""
            }`}
          >
            <button className="btn app-btn-primary-sm" onClick={openModal}>
              Message Organizer
            </button>
            <ChatModal
              setStatusMsg={setStatusMsg}
              receiver_user_id={event_user_id}
              receiver_email={organizer_email}
              receiver_firstname={organizer_firstname}
              isOpen={modalIsOpen}
              onClose={closeModal}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const MySignups = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.email);

  const [signupReqsUpcoming, setSignupReqsUpcoming] = useState([]);
  const [signupReqsArchived, setSignupReqsArchived] = useState([]);
  // Add loading state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusMsg, setStatusMsg] = useState(null); // success message for form submission
  const [modalIsOpen, setModalIsOpen] = useState();

  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const activeComponentsDashboardNew = Object.keys(
    activeComponentsDashboard
  ).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});

  // Group events by event and category
  const groupEventsByEventAndCategory = (eventData) => {
    const events = (eventData || []).reduce((acc, item) => {
      const {
        event_id: eventId,
        event_name: eventName,
        category,
        event_date,
        organized_by,
        location,
        address_line1,
        address_line2,
        city,
        state,
        zip_code,
        from_date,
        to_date,
        from_time,
        to_time,
        description,
        is_private,
        status,
        event_user_id,
        organizer_email,
        organizer_firstname,
      } = item;

      if (!acc[eventId]) {
        acc[eventId] = {
          id: eventId,
          event_name: eventName,
          event_date: event_date,
          organized_by: organized_by,
          location: location,
          address_line1: address_line1,
          address_line2: address_line2,
          city: city,
          state: state,
          zip_code: zip_code,
          from_date: from_date,
          to_date: to_date,
          from_time: from_time,
          to_time: to_time,
          description: description,
          is_private,
          status,
          event_user_id,
          organizer_email,
          organizer_firstname,
          Categories: {},
        };
      }

      if (!acc[eventId]["Categories"][category]) {
        acc[eventId]["Categories"][category] = {
          Category: category,
          Items: [],
        };
      }

      acc[eventId]["Categories"][category]["Items"].push(item);

      return acc;
    }, {});
    // console.log(Object.values(events));
    return Object.values(events);
  };

  // Get all the suppSignup requests for the user
  const fetchSignupReqs = async () => {
    try {
      const { data } = await getMySignups();
      // console.log(data.signupResp);
      const today = new Date();
      const upComing = data.signupResp.filter(
        (items) => items.is_archived === false
        // (items) => new Date(items.from_date) > today
      );
      const archived = data.signupResp.filter(
        (items) => items.is_archived === true
        // (items) => new Date(items.from_date) < today
      );
      // console.log(upComing.length, archived.length)
      setSignupReqsUpcoming(groupEventsByEventAndCategory(upComing));
      setSignupReqsArchived(groupEventsByEventAndCategory(archived));
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSignupReqs().finally(() => setLoading(false));
  }, []);

  const handleActionClick = useCallback(
    (item, component) => {
      dispatch(setItem(item));
      dispatch(
        setActiveComponentsDashboard({
          ...activeComponentsDashboardNew,
          [component]: true,
        })
      );
    },
    [activeComponentsDashboardNew]
  );

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Oops! Something went wrong. We are working on fixing this!
      </div>
    );
  }

  return (
    <div>
      <nav>
        <ul className="nav nav-tabs" id="nav-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="nav-upcoming-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-upcoming"
              type="button"
              role="tab"
              aria-controls="nav-upcoming"
              aria-selected="true"
            >
              Upcoming
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="nav-archived-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-archived"
              type="button"
              role="tab"
              aria-controls="nav-archived"
              aria-selected="true"
            >
              Archived
            </button>
          </li>
        </ul>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-upcoming"
          role="tabpanel"
          aria-labelledby="nav-upcoming-tab"
        >
          <div>
            {signupReqsUpcoming.length === 0 ? (
              <div className="row g-4">
                <div className="col">
                  <div className="card shadow">
                    <div className="card-body">
                      <div className="card-title">
                        You have not signed up for any upcoming events
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              signupReqsUpcoming.map((event) => (
                <SignupCard
                  key={event["id"]}
                  event={event}
                  handleActionClick={handleActionClick}
                  userEmail={userEmail}
                  setStatusMsg={setStatusMsg}
                  modalIsOpen={modalIsOpen}
                  openModal={openModal}
                  closeModal={closeModal}
                  archived={false}
                />
              ))
            )}
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-archived"
          role="tabpanel"
          aria-labelledby="nav-archived-tab"
        >
          <div>
            {signupReqsArchived.length === 0 ? (
              <div className="row g-4">
                <div className="col">
                  <div className="card shadow">
                    <div className="card-body">
                      <div className="card-title">
                        No Archived Signups Found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              signupReqsArchived.map((event) => (
                <SignupCard
                  key={event["id"]}
                  event={event}
                  handleActionClick={handleActionClick}
                  userEmail={userEmail}
                  setStatusMsg={setStatusMsg}
                  modalIsOpen={modalIsOpen}
                  openModal={openModal}
                  closeModal={closeModal}
                  archived={true}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySignups;
