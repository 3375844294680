import { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  formatDate,
  formatTime,
} from "../../../components/helpers/formatDateTime";
import { useDispatch, useSelector } from "react-redux";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../../redux/slices/dashboardSlice";
import { getMySponsorships } from "../../../api/signupApis";

const EventDetails = ({ eventData, handleActionClick }) => {
  const {
    event_id,
    event_name,
    from_date,
    to_date,
    from_time,
    to_time,
    organized_by,
    location,
  } = eventData;
  return (
    <div>
      <Link
        className="card-subheader app-link-fs-9"
        onClick={() => handleActionClick(eventData, "eventDetail")}
      >
        <span>{event_name}</span>
      </Link>
      <div>
        <span className="fw-bold app-fs-8 mr-2">Date & Time: </span>
        <span className="app-fs-8 mr-2">
          {from_date === to_date
            ? formatDate(from_date)
            : formatDate(from_date) + " - " + formatDate(to_date)}
        </span>
        &nbsp;{" "}
        <span className="app-fs-8">
          {formatTime(from_time) + " - " + formatTime(to_time)}
        </span>
      </div>
      <div>
        <span className="fw-bold app-fs-8 mr-2">Organized by: </span>
        <span className="app-fs-8">{organized_by} </span>
      </div>
      <div className="mb-2">
        <span className="fw-bold app-fs-8 mr-2">Location: </span>
        <span className="app-fs-8">{location} </span>
      </div>
    </div>
  );
};

const SponsorshipCard = ({ sponsorships, handleActionClick }) => {
  return (
    <div>
      {Object.entries(sponsorships).map(([eventName, group]) => (
        <div className="card shadow mb-2" key={eventName}>
          <div className="card-body">
            <EventDetails
              eventData={group.eventDetails}
              handleActionClick={handleActionClick}
            />
            <ul>
              {group.sponsorships.map((sponsorship) => (
                <li key={sponsorship.sponsorship_id}>
                  You have pledged ${sponsorship.sponsorship_amt} - Sponsorship
                  Level - {sponsorship.sponsorship_level}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

const MySponsorships = () => {
  const dispatch = useDispatch();
  const [sponsorshipsUpcoming, setSponsorshipsUpcoming] = useState([]);
  const [sponsorshipsArchived, setSponsorshipsArchived] = useState([]);
  const [sponsorships, setSponsorships] = useState([]); // [{}

  // Add loading state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const activeComponentsDashboardNew = Object.keys(
    activeComponentsDashboard
  ).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});

  const groupByEvent = (data) => {
    return data.reduce((acc, item) => {
      const eventName = item.event_name;

      // Initialize the group if it doesn't exist
      if (!acc[eventName]) {
        acc[eventName] = {
          eventDetails: item, // Store the first occurrence of the event
          sponsorships: [],
        };
      }

      // Add the sponsorship to the event
      acc[eventName].sponsorships.push(item);
      // console.log(acc)
      return acc;
    }, {});
  };

  // Get all the suppSignup requests for the user
  const fetchSponsorshipReqs = async () => {
    try {
      const { data } = await getMySponsorships();

      setSponsorships(data.sponsorships);

      const today = new Date();

      const upComing = groupByEvent(
        // data.sponsorships.filter((items) => new Date(items.from_date) > today)
        data.sponsorships.filter((items) => items.is_archived === false)
      );
      const archived = groupByEvent(
        // data.sponsorships.filter((items) => new Date(items.from_date) < today)
        data.sponsorships.filter((items) => items.is_archived === true)
      );
      // console.log(upComing.length, archived.length);
      setSponsorshipsUpcoming(upComing);
      setSponsorshipsArchived(archived);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSponsorshipReqs().finally(() => setLoading(false));
  }, []);

  const handleActionClick = useCallback(
    (item, component) => {
      dispatch(setItem(item));
      dispatch(
        setActiveComponentsDashboard({
          ...activeComponentsDashboardNew,
          [component]: true,
        })
      );
    },
    [activeComponentsDashboardNew]
  );

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Oops! Something went wrong. We are working on fixing this!
      </div>
    );
  }

  return (
    <div>
      <nav>
        <ul className="nav nav-tabs" id="nav-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="nav-upcoming-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-upcoming"
              type="button"
              role="tab"
              aria-controls="nav-upcoming"
              aria-selected="true"
            >
              Upcoming
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="nav-archived-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-archived"
              type="button"
              role="tab"
              aria-controls="nav-archived"
              aria-selected="true"
            >
              Archived
            </button>
          </li>
        </ul>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-upcoming"
          role="tabpanel"
          aria-labelledby="nav-upcoming-tab"
        >
          <div>
            {sponsorships.length === 0 || sponsorshipsUpcoming.length === 0 ? (
              <div className="row g-4">
                <div className="col">
                  <div className="card shadow">
                    <div className="card-body">
                      <div className="card-title">
                        You have not signed up for any upcoming events
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <SponsorshipCard
                sponsorships={sponsorshipsUpcoming}
                handleActionClick={handleActionClick}
              />
            )}
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-archived"
          role="tabpanel"
          aria-labelledby="nav-archived-tab"
        >
          <div>
            {sponsorships.length === 0 || sponsorshipsArchived.length === 0 ? (
              <div className="row g-4">
                <div className="col">
                  <div className="card shadow">
                    <div className="card-body">
                      <div className="card-title">
                        No Archived Signups Found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <SponsorshipCard
                sponsorships={sponsorshipsArchived}
                handleActionClick={handleActionClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySponsorships;
