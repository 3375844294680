import { saveAs } from "file-saver";

export const exportToCSV = (data, columns, headers, filename_prefix) => {
  // Convert data to CSV format
  const csvData = data.map((row) => columns.map((column) => row[column]));

  // Add header row
  csvData.unshift(headers);

  // Generate a unique filename for the CSV file
  const filename = `${filename_prefix}_${new Date().getTime()}.csv`;

  // Create a CSV string
  const csvString = csvData.map((row) => row.join(",")).join("\n");

  // Create a Blob object from the CSV string
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

  // Save the Blob object as a file using file-saver
  saveAs(blob, filename);
};
