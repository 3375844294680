import { Link } from "react-router-dom";
import Layout from "../components/layout/layout";

const Features = () => {
  return (
    <Layout>
      {/* <!-- Section with four info areas left & one card right with image and waves --> */}
      <section className="py-4 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 g-4">
              <div className="row justify-content-start">
                <div className="col-md-6 p-2">
                  <div className="info">
                    <div className="icon icon-md">
                      <i className="fas fa-calendar-alt fa-2x feature-icon-primary"></i>
                    </div>
                    <h5>Built for Event Planners and Organizers</h5>
                    <p>
                      Easily create, edit, and manage events, including event
                      details, descriptions, and schedules. Generate promotional
                      event flyers for marketing and volunteer signup.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <div className="info">
                    <div className="icon icon-md">
                      <i className="fas fa-list-alt fa-2x feature-icon-primary"></i>
                    </div>
                    <h5>Easily manage Program Schedules</h5>
                    <p>
                      Define detailed and structured program schedules with
                      specific time slots for each activity. Allow better user
                      experience and participation by providing detailed event
                      description.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-start">
                <div className="col-md-6">
                  <div className="info">
                    <div className="icon icon-md">
                      <i className="fas fa-users fa-2x feature-icon-primary"></i>
                    </div>
                    <h5>Volunteer Signup Sheets</h5>
                    <p>
                      Bring the community together by providing a platform to
                      sign up for volunteer opportunities. Create and manage
                      signup sheets for various supplies and services.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info">
                    <div className="icon icon-md">
                      <i className="fa-solid fa-layer-group fa-2x feature-icon-primary"></i>
                    </div>
                    <h5>Integrated platform</h5>
                    <p>
                      End-to-end event management platform including event
                      marketing and promotion, event planning and scheduling,
                      reporting and analytics.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ms-auto">
              <div className="card">
                <div
                  className="position-relative overflow-hidden"
                  style={{ height: "150px", marginTop: "0px" }}
                >
                  <div className="position-absolute w-100 top-0 z-index-1">
                    {/* <!-- Image --> */}
                    <img
                      src="assets/images/landing/signup.jpg"
                      className="screenshot img-fluid img-responsive"
                      alt="..."
                    />
                  </div>
                </div>
                <div className="card-body">
                  <h4>From paper to Digital Signups</h4>
                  <p>
                    Engage your community with digital signups for volunteer
                    opportunities, supplies, and services.
                  </p>
                  <Link
                    to="/aboutUs"
                    className="app-link-fs-10 icon-move-right"
                  >
                    More about us
                    <i className="fas fa-arrow-right text-xs ms-1"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- END Section with four info areas left & one card right with image and waves --> */}
    </Layout>
  );
};

export default Features;
