import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateOTP } from "../../api/authApis";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveComponentsLogin,
  setActiveComponentsRegister,
} from "../../redux/slices/authSlice";

const ResendOTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = useSelector((state) => state.auth.email);
  const activeComponentsRegister = useSelector(
    (state) => state.auth.activeComponentsRegister
  );
  const activeComponentsLogin = useSelector(
    (state) => state.auth.activeComponentsLogin
  );

  // Make all the components active = false
  let activeComponentsRegisterNew = Object.assign(
    {},
    ...Object.entries(activeComponentsRegister).map(([k, v]) => ({
      [k]: false,
    }))
  );

  let activeComponentsLoginNew = Object.assign(
    {},
    ...Object.entries(activeComponentsLogin).map(([k, v]) => ({ [k]: false }))
  );

  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [values, setValues] = useState({
    email: email,
    step: "resendOTP",
  });

  const cancelResendOTP = () => {
    dispatch(
      setActiveComponentsLogin({ ...activeComponentsLoginNew, login: true })
    );
    navigate("/login");
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, status } = await generateOTP(values);
      // console.log(status);
      if (status === 201) {
        dispatch(
          setActiveComponentsRegister({
            ...activeComponentsRegisterNew,
            verifyOTP: true,
          })
        );
      }
    } catch (error) {
      if (error.response.status === 404) {
        setStatus("Could not find the user. Please retry !");
      }
      if (error.response.data.error === "Unauthorized") {
        setStatus("Unauthorized");
      }
      setError(error.response.data.message);
      console.log(error.response.data.error);
    }
  };

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <div className="title text-center">
          <h6>Please Enter the Registration Email</h6>
        </div>
        <form onSubmit={(e) => onSubmit(e)} className="mb-2 text-center">
          <div className="form-group textbox mb-3">
            <input
              type="text"
              onChange={(e) => onChange(e)}
              id="email"
              name="email"
              value={values.email}
              className="form-control form-input"
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-2">
            <div className="app-fs-7" style={{ color: "red" }}>
              {status}
            </div>
            <button
              type="button"
              className="btn app-btn-primary"
              onClick={cancelResendOTP}
            >
              Cancel
            </button>
            <button type="submit" className="btn app-btn-primary mx-3">
              Send OTP
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ResendOTP;
