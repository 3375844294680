import { Link, useNavigate } from "react-router-dom";
import avatar from "../../assets/images/avatars/profile.png";
import { FaMedal } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponentsDashboard } from "../../redux/slices/dashboardSlice";
import { setEventsTabActive } from "../../redux/slices/eventsTabSlice";
import { setAuthenticated } from "../../redux/slices/authSlice";
import { getUserProfile } from "../../api/authApis";
import { useState, useEffect, useMemo } from "react";
import { reset as resetDashboard } from "../../redux/slices/dashboardSlice";
import { reset as resetCreateEvent } from "../../redux/slices/eventCreateSlice";

const Sidenav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState();

  // const chatHomeActive = useSelector((state) => state.chatHome.chatHomeActive);
  // console.log("chatHomeActive", chatHomeActive);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );
  const { eventList, eventCreate, eventDetail, signup, program, profile } =
    activeComponentsDashboard;

  const eventsTabActive = useSelector(
    (state) => state.eventsTab.eventsTabActive
  );

  const { myEvents, topEvents, localEvents, weekEvents, interestedEvents } =
    eventsTabActive;

  const iconStyles = {
    border: "none !important",
    outline: "none",
    color: "silver",
    fontSize: "1.5em",
  };

  // Make all the components active = false
  const activeComponentsDashboardNew = useMemo(() => {
    return Object.assign(
      {},
      ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
        [k]: false,
      }))
    );
  }, [activeComponentsDashboard]);

  // Make all the components active = false
  const eventsTabActiveNew = Object.keys(eventsTabActive).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});

  const setActiveComponent = (componentName) => {
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        [componentName]: true,
      })
    );
  };

  const setChatHome = (e) => {
    // console.log("setChatHome");
    e.preventDefault();
    dispatch(resetDashboard());
    // dispatch(setActiveComponentsDashboard({ ...activeComponentsDashboardNew }));
    // dispatch(setChatHomeActive(true));
    navigate("/chatHome");
  };

  const eventsHome = () => {
    setActiveComponent("eventList");
    dispatch(setEventsTabActive({ ...eventsTabActiveNew, myEvents: true }));
  };

  const createEvent = () => {
    // This will ensure that the event form is reset. Commenting out for now.
    // dispatch(resetCreateEvent());
    setActiveComponent("eventCreate");
  };

  const eventDrafts = () => {
    setActiveComponent("eventDrafts");
  };

  const userProfile = () => {
    setActiveComponent("profile");
  };

  const resetPassword = () => {
    setActiveComponent("updatePassword");
  };

  const mySignups = () => {
    setActiveComponent("mySignups");
  };

  const mySponsorships = () => {
    setActiveComponent("mySponsorships");
  };

  const reportsMain = () => {
    setActiveComponent("reportsMain");
  };

  // const chatMessages = () => {
  //   console.log("chatMessages");
  //   // setActiveComponent("chatMessages");
  //   setChatHome();
  // };

  const navItems = [
    // {
    //   icon: "fas fa-user fa-lg app-icon-sidenav",
    //   text: "Profile",
    //   onClick: userProfile,
    // },
    {
      icon: "fa fa-calendar fa-lg app-icon-sidenav",
      text: "My Events",
      onClick: eventsHome,
    },
    {
      icon: "fa fa-plus-square fa-lg app-icon-sidenav",
      text: "Create Event",
      onClick: createEvent,
    },
    {
      icon: "fa fa-shopping-basket fa-lg app-icon-sidenav",
      text: "My Drafts",
      onClick: eventDrafts,
    },
    {
      icon: "fa fa-user-plus fa-lg app-icon-sidenav",
      text: "My Signups",
      onClick: mySignups,
    },
    {
      icon: "fa fa-credit-card fa-lg app-icon-sidenav",
      text: "My Sponsorships",
      onClick: mySponsorships,
    },
    {
      icon: "fa fa-comment fa-lg app-icon-sidenav",
      text: "Messages",
      onClick: setChatHome,
    },
    {
      icon: "fa fa-chart-bar fa-lg app-icon-sidenav",
      text: "Reports",
      onClick: reportsMain,
    },
  ];

  const fetchProfile = async () => {
    try {
      const { data, status } = await getUserProfile();
      setUser(data.user);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("isAuth");
        dispatch(setAuthenticated(false));
      }
      console.log(error.response.status);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [profile]);

  return (
    // <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
    //   <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
    //     <span class="fs-5 d-none d-sm-inline">Menu</span>
    //   </a>
    //   <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
    //     <li class="nav-item">
    //       <a href="#" class="nav-link align-middle px-0">
    //         <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline">Home</span>
    //       </a>
    //     </li>
    //     <li class="nav-item">
    //       <a href="#" class="nav-link align-middle px-0">
    //         <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline">Home</span>
    //       </a>
    //     </li>
    //     <li class="nav-item">
    //       <a href="#" class="nav-link align-middle px-0">
    //         <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline">Home</span>
    //       </a>
    //     </li>
    //     <li class="nav-item">
    //       <a href="#" class="nav-link align-middle px-0">
    //         <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline">Home</span>
    //       </a>
    //     </li>
    //   </ul>

    // </div>
    <div className="d-flex flex-column flex-shrink-0 p-3 bg-light">
      <div className="card overflow-hidden">
        {/* <!-- Cover image --> */}
        <div className="h-50px"></div>
        {/* <!-- Card body START --> */}
        <div className="card-body pt-0">
          <div className="text-center d-none d-lg-block">
            {/* <!-- Avatar --> */}
            <div className="avatar-md avatar-lg mb-1 mt-1">
              <Link onClick={userProfile}>
                <img
                  className="avatar-md-img rounded-circle border border-white border-3"
                  src={user?.profile || avatar}
                  alt="Profile"
                />
              </Link>
            </div>

            {/* <!-- Info --> */}
            <h6 className="mb-1 mt-2">
              <Link className="app-link-fs-9" onClick={userProfile}>
                {user?.first_name} {user?.last_name}{" "}
              </Link>
            </h6>
            <div className="aligns-items-center">
              <FaMedal style={iconStyles} />
              &nbsp;<small>{user?.title}</small>
            </div>
            <p className="mt-1 app-fs-8">{user?.about}</p>
            {/* <!-- Divider --> */}
            <hr />
          </div>

          <ul className="nav nav-pills flex-column mb-auto nav-link-secondary">
            {navItems?.map((item, index) => (
              <li className="nav-item" key={index}>
                <Link
                  className="nav-link app-link-fs-9 action-buttons"
                  id="sidenav"
                  onClick={item.onClick}
                >
                  <i className={item.icon} aria-hidden="true"></i>
                  {/* <span className="ms-2 d-none d-sm-inline">{item.text}</span> */}
                  <span className="ms-2 d-sm-inline d-lg-inline d-md-none">
                    {item.text}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidenav;

// d-none applies the display: none; style, which hides the element.
// d-sm-inline applies the display: inline; style for small devices and larger, which shows the element.
// d-lg-inline applies the display: inline; style for large devices and larger, which shows the element.
// d-md-none applies the display: none; style for medium devices and larger, which hides the element.
