import Layout from "../components/layout/layout";
import { Link } from "react-router-dom";

const Faq = () => {
  return (
    <Layout>
      <section className="py-4 bg-white">
        <div className="container">
          <div className="row my-2">
            <div className="col-md-6 mx-auto text-center">
              <h2>Frequently Asked Questions</h2>
              <p className="text-muted">
                Here are some of the most frequently asked questions about our
                platform.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="accordion" id="accordionRental">
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button fw-bold border-bottom"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How can I create an event on your platform?
                    </button>
                  </h5>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      To create and event on the platform you'll have to first
                      register on the platform and create a login. Once you
                      login to your account, you'll be redirected to your
                      personal dashboard. Just follow the steps to create and
                      publish a new event with all the required details.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button border-bottom fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Can I customize my event branding?
                    </button>
                  </h5>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      Yes, you can customize your event branding. You can upload
                      your own logo and banner images to be used on your event
                      page. We are working to add more customizable features to
                      the platform. Stay tuned!
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button border-bottom fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How can I manage my event schedule?
                    </button>
                  </h5>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      Once you publish your event, you can add detailed program
                      schedule to your event from the dashboard. You can add
                      multiple days and multiple sessions to your event. You can
                      also add descriptions and other details to your event
                      schedule.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Can I message the volunteers for follow up?
                    </button>
                  </h5>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      Yes, you can message the volunteers for follow up. You can
                      send personalized messages to indivudal volunteers who
                      have signed up for your event.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingFifth">
                    <button
                      className="accordion-button border-bottom fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFifth"
                      aria-expanded="false"
                      aria-controls="collapseFifth"
                    >
                      How can I track RSVPs and signups?
                    </button>
                  </h5>
                  <div
                    id="collapseFifth"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFifth"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      You can track RSVPs and signups from your dashboard. You
                      can also export the list of volunteers who have signed up
                      for your event.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Can I cancel the event?
                    </button>
                  </h5>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      We understand that unforeseen circumstances may require
                      you to cancel your event.
                      <p>
                        <strong>Generally, you can follow these steps: </strong>
                      </p>
                      <ul>
                        <li>Log in to your account.</li>
                        <li>
                          Go to your "My Events" section or locate the specific
                          event you want to cancel.
                        </li>
                        <li>
                          Click on the event to see the event details page.
                        </li>
                        <li>Cancel button will be available at the bottom</li>
                        <li>Confirm your cancellation.</li>
                      </ul>
                      <p>
                        <strong>Remember:</strong>
                      </p>
                      <ul>
                        <li>
                          Once you cancel an event, it cannot be undone. It's
                          crucial to <strong>communicate effectively</strong>{" "}
                          with your attendees regarding the cancellation.
                        </li>
                        <li>
                          <strong>Provide a clear explanation</strong> and
                          apologize for any inconvenience caused. If you have
                          collected any donations, you will need to refund them
                          manually.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h5 className="accordion-header" id="headingSixth">
                    <button
                      className="accordion-button border-bottom fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixth"
                      aria-expanded="false"
                      aria-controls="collapseSixth"
                    >
                      How can I suggest a feature to be added to the platform?
                    </button>
                  </h5>
                  <div
                    id="collapseSixth"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSixth"
                    data-bs-parent="#accordionRental"
                  >
                    <div className="accordion-body text-sm opacity-8">
                      We are constantly looking for opportunities to improve the
                      platform to best serve our customer needs. Please use the
                      contact us form to send us a{" "}
                      <Link to="/contactUs">message</Link> with the detailed
                      description of your use case. We will get back to you as
                      soon as possible.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Faq;
