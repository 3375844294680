import { Link } from "react-router-dom";
import Layout from "../components/layout/layout";
import "../assets/styles/home.css";

const Welcome = () => (
  <section id="welcome">
    <div className="container ">
      <div className="row align-items-center align-content-center">
        <div className="col-12 col-md-5 col-lg-4 order-md-2">
          {/* <!-- Image --> */}
          <img
            src="assets/images/landing/collaboration1.png"
            className="img-fluid mw-md-25 img-responsive img-position"
            alt="Harness the Power of the Community"
          />
        </div>
        <div className="col-12 col-md-7 col-lg-8 order-md-1">
          {/* <!-- Heading --> */}
          <h1 className="display-3 text-center text-md-start">
            Welcome to <br />
            <span className="app-text-accent1">SignupQR</span> <br />
          </h1>
          {/* <!-- Text --> */}
          <p className="lead text-md-start text-center text-body-secondary mb-4 mb-lg-8">
            Streamline and simplify event planning, organization, and execution
            with our Event Management platform. Tap into the power of the
            community to make your events a success.
          </p>
          {/* <!-- Buttons --> */}
          {/* <div className="text-center text-md-start">
          <a href="overview.html" className="btn btn-primary shadow lift me-1">
            View all pages <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
          </a>
          <a href="docs/index.html" className="btn btn-primary-subtle lift">
            Documentation
          </a>
        </div> */}
        </div>
      </div>
      {/* <!-- / .row --> */}
      <hr />
    </div>
    {/* <!-- / .container --> */}
  </section>
);

const CallToAction = () => (
  <section id="cta">
    <div className="container">
      <div className="row align-items-center align-content-center">
        <div className="col-12 col-md-12 col-lg-12">
          {/* <!-- Heading --> */}
          <h2>
            Ready to get started? <br />
            <span className="app-text-accent1">Sign up now</span>!
          </h2>
          {/* <!-- Text --> */}
          <p className="fs-lg text-body-secondary mb-3">
            Create an account and start creating and managing your events today.{" "}
          </p>

          <Link to="/register" className="custom-button me-5" role="button">
            <span> Get Started</span>
          </Link>
        </div>
      </div>
      <hr />
    </div>
  </section>
);

const WhyUs = () => (
  <section id="why-us">
    <div className="container ">
      <h2 className="pb-2">Why Choose Us</h2>
      <div className="row g-4 py-3 row-cols-1 row-cols-lg-3">
        <div className="feature col">
          <h4>Efficient Event Organization</h4>
          <p>
            Our app streamlines the process of planning and managing events,
            saving you time and effort. From scheduling to attendee management,
            everything is at your fingertips.
          </p>
        </div>
        <div className="feature col">
          <h4>Community Engagement</h4>
          <p>
            With features like public event listings and social sharing, our app
            makes it easy to promote your events and engage with your community.
          </p>
        </div>
        <div className="feature col">
          <h4>Volunteer Management</h4>
          <p>
            Easily recruit, manage, and communicate with volunteers for your
            events. Our app simplifies volunteer scheduling and coordination.
          </p>
        </div>
      </div>
      <hr />
    </div>
  </section>
);

const Features = () => (
  <section id="features">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-12 col-lg-12">
          {/* <!-- Heading --> */}
          <h2>
            Powerful Features to <br />
            <span className="app-text-accent1">better manage your Events</span>.
          </h2>
          {/* <!-- Text --> */}
          <p className="fs-lg text-body-secondary mb-5">
            Born out of a necessity, our platform empowers you to manage every
            aspect of your event seamlessly and efficiently.
          </p>
        </div>
        <div className="col-12 col-md-7 col-lg-8">
          {/* <!-- List --> */}
          <div className="d-flex flex-column flex-md-row">
            {/* <!-- Icon --> */}
            <div className="feature col-md-2 col-sm-12 icon app-text-primary mb-2 mb-md-0">
              <i className="fas fa-calendar-alt fa-5x feature-icon-primary"></i>
            </div>
            <div>
              {/* <!-- Heading --> */}
              <h4 className="mb-1">Built for Event Planners and Organizers</h4>
              {/* <!-- Text --> */}
              <p className="text-body-secondary mb-5">
                Easily create, edit, and manage events, including event details,
                descriptions, and schedules. Generate promotional event flyers
                for marketing and volunteer signup
              </p>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row">
            {/* <!-- Icon --> */}
            <div className="feature col-12 col-md-2 icon text-primary mb-2 mb-md-0">
              <i className="fas fa-list-alt fa-4x feature-icon-primary"></i>
            </div>
            <div>
              {/* <!-- Heading --> */}
              <h4 className="mb-1">Easily manage Program Schedules</h4>
              {/* <!-- Text --> */}
              <p className="text-body-secondary mb-5">
                Define detailed and structured program schedules with specific
                time slots for each activity. Allow better user experience and
                participation by providing detailed event description.
              </p>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row">
            {/* <!-- Icon --> */}
            <div className="feature col-md-2 col-sm-12 icon text-primary mb-2 mb-md-0">
              <i className="fas fa-users fa-4x feature-icon-primary"></i>
            </div>
            <div>
              {/* <!-- Heading --> */}
              <h4 className="mb-1">Volunteer Signup Sheets</h4>
              {/* <!-- Text --> */}
              <p className="text-body-secondary mb-5">
                Bring the community together by providing a platform to sign up
                for volunteer opportunities. Create and manage signup sheets for
                various supplies and services.
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-5 col-lg-4 mb-4">
          <div className="w-md-150 w-lg-130 position-relative">
            {/* <!-- Shape --> */}
            {/* <div className="shape shape-fluid-y shape-blur-4 text-gray-200">
          </div> */}

            {/* <!-- Image --> */}
            <div className="img-skewed img-skewed-start">
              {/* <!-- Image --> */}
              <img
                src="assets/images/landing/signup.jpg"
                className="screenshot img-fluid img-responsive img-skewed-item"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- / .row --> */}
      <hr />
    </div>
  </section>
);

const Card = ({ iconClass, title, text }) => (
  <div className="card mx-4">
    <i className={iconClass}></i>
    <div className="card-body">
      <h4 className="card-title">{title}</h4>
      <p className="card-text">{text}</p>
    </div>
  </div>
);

const HowItWorks = () => (
  <section id="howitworks">
    <div className="container ">
      <div className="row g-4 g-lg-2 align-items-center app-bg-skyblue-light">
        <div className="col-lg-8 p-3">
          <h2 className="h2">HOW IT WORKS!</h2>
          <p className="mb-2">
            Create, Collaborate and Coordinate your events with ease{" "}
          </p>
        </div>
        <div className="card-group mb-4 ">
          <Card
            iconClass="fas fa-lightbulb fa-4x text-center feature-icon-primary mt-2"
            title="Step 1 - Create"
            text="Create your Event and Manage Signup Sheets. Sign up for exciting events happening in your area with just a few clicks"
          />
          <Card
            iconClass="fas fa-users fa-4x text-center feature-icon-primary mt-2"
            title="Step 2 - Collaborate"
            text="Connect with the community. Lend a helping hand and make a difference by volunteering for various event activities"
          />
          <Card
            iconClass="fas fa-handshake fa-4x text-center feature-icon-primary mt-2"
            title="Step 3 - Coordinate"
            text="Manage event logistics, from bringing food and supplies to helping with registration and stage setup"
          />
        </div>
      </div>
    </div>
  </section>
);

const Home = () => {
  return (
    <Layout>
      <div>
        {/* <!-- WELCOME --> */}
        <Welcome />

        {/* <!-- Call To Action --> */}
        <CallToAction />

        {/* <!-- WHY US --> */}
        <WhyUs />

        {/* <!-- FEATURES --> */}
        <Features />

        {/* <!-- HOW IT WORKS --> */}
        <HowItWorks />
      </div>
    </Layout>
  );
};

export default Home;
