import "../../assets/styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../api/authApis";
import {
  setEmail,
  setUsername,
  setFirstName,
  setAuthenticated,
  setActiveComponentsLogin,
  setActiveComponentsRegister,
} from "../../redux/slices/authSlice";
import { reset as resetChatHome } from "../../redux/slices/chatSlice";
import { reset as resetDashboard } from "../../redux/slices/dashboardSlice";
import { reset as resetEventsTab } from "../../redux/slices/eventsTabSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  // Use a default location if no location was stored in the state
  // const { from } = location.state || { from: { pathname: "/dashboard" } };

  const email = useSelector((state) => state.auth.email);
  const activeComponentsLogin = useSelector(
    (state) => state.auth.activeComponentsLogin
  );
  const activeComponentsRegister = useSelector(
    (state) => state.auth.activeComponentsRegister
  );
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );
  const eventsTabActive = useSelector(
    (state) => state.eventsTab.eventsTabActive
  );

  // console.log("Login", email, activeComponentsDashboard);
  // Make all the components active = false
  let activeComponentsLoginNew = Object.assign(
    {},
    ...Object.entries(activeComponentsLogin).map(([k, v]) => ({ [k]: false }))
  );

  let activeComponentsRegisterNew = Object.assign(
    {},
    ...Object.entries(activeComponentsRegister).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const initializeFormData = {
    email: email || "",
    password: "",
  };

  const [values, setValues] = useState(initializeFormData);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [showHideRegister, setShowHideRegister] = useState("showDiv");
  const [showHideValidate, setShowHideValidate] = useState("hideDiv");

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const validateEmail = (e) => {
    e.preventDefault();
    // dispatch(resetAuth());
    dispatch(
      setActiveComponentsRegister({
        ...activeComponentsRegisterNew,
        resendOTP: true,
      })
    );
    navigate("/register");
  };

  const recoverPassword = () => {
    // dispatch(resetAuth());
    dispatch(
      setActiveComponentsLogin({
        ...activeComponentsLoginNew,
        passwordOTP: true,
      })
    );
  };

  const showRegister = async (e) => {
    e.preventDefault();
    // dispatch(resetAuth());
    dispatch(
      setActiveComponentsRegister({
        ...activeComponentsRegisterNew,
        register: true,
      })
    );
    navigate("/register");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(values)
      const { data, status } = await login(values);
      // console.log(data)
      if (!data.emailValidated) {
        dispatch(setEmail(data.user.email));
        setStatus(
          "Please verify your email address to complete the registration process"
        );
        setShowHideRegister("hideDiv");
        setShowHideValidate("showDiv");
      } else {
        dispatch(setEmail(data.user.email));
        dispatch(setUsername(data.user.username));
        dispatch(setFirstName(data.user.first_name));
        dispatch(setAuthenticated(true));
        dispatch(resetChatHome());
        // dispatch(resetDashboard());
        dispatch(resetEventsTab());
        localStorage.setItem("isAuth", true);
        setValues(initializeFormData);
        // navigate(from);
        setError("");
      }
    } catch (error) {
      setError(
        error.response && error.response.data
          ? error.response.data.error
          : "Oops! Something went wrong. Please try again later."
      );
      // setError("Oops! Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <h4 className="text-center">Please Login</h4>
        <form onSubmit={(e) => onSubmit(e)} className="g-3">
          <div className="row">
            <div className="form-group mb-1">
              <label className="form-label fs-9rem" htmlFor="email">
                Email Address
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="email"
                className="form-control form-input hide-placeholder"
                id="email"
                name="email"
                value={values.email}
                placeholder="Please enter your email"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label fs-9rem" htmlFor="password">
                Password
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="password"
                className="form-control form-input"
                id="password"
                name="password"
                value={values.password}
                placeholder="Enter your password"
                required
              />
            </div>
          </div>
          <div className="text-center mb-2 action-buttons">
            <div className="app-fs-8 error-message">{error}</div>
            <div className="app-fs-8 status-message">{status}</div>
            <button className="btn app-btn-primary w-100" type="submit">
              Sign in
            </button>
          </div>
        </form>
        <div className="text-center">
          <div className={`${showHideRegister}`}>
            <span className="link-btn">
              Don't have an account?{" "}
              <Link onClick={showRegister}> Signup here</Link>
            </span>
          </div>
          <div className={`${showHideValidate}`}>
            <span className="link-btn">
              <Link onClick={validateEmail}> Verify Email</Link>
            </span>
          </div>
          <div className={`${showHideRegister} "text-center"`}>
            <span className="app-link-fs-8">
              <Link onClick={recoverPassword}>Forgot Password?</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
