import { useState, useEffect } from "react";
import { profileValidate } from "../helpers/validate";
import { useDispatch, useSelector } from "react-redux";
import { setAuthenticated } from "../../redux/slices/authSlice";
import {
  getStatesList,
  getUserProfile,
  updateAddressDetails,
} from "../../api/authApis";
import { setActiveComponentsDashboard } from "../../redux/slices/dashboardSlice";

const initializeFormData = {
  address_line1: "",
  address_line2: "",
  city: "",
  state: "",
  zipcode: "",
  country: "",
  email: "",
};

const UpdateAddress = () => {
  const dispatch = useDispatch();
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const [user, setUser] = useState();
  const [error, setError] = useState(false);

  // Make all the components active = false
  const activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const [values, setValues] = useState(initializeFormData);
  const [states, setStates] = useState([]);

  const fetchStatesList = async () => {
    // console.log('fetchStatesList')
    try {
      const { data } = await getStatesList();
      // console.log(data.states)
      setStates(data.states);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProfile = async () => {
    try {
      const { data, status } = await getUserProfile();
      setUser(data.user);
      setValues({
        address_line1: data.user?.address_line1,
        address_line2: data.user?.address_line2,
        city: data.user?.city,
        state: data.user?.state || "",
        zipcode: data.user?.zipcode,
        country: data.user?.country,
        email: data.user?.email,
      });
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("isAuth");
        dispatch(setAuthenticated(false));
      }
      console.log(error.response.status);
    }
  };

  useEffect(() => {
    fetchStatesList();
    fetchProfile();
  }, []);

  const userProfile = () => {
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        profile: true,
      })
    );
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateAddressDetails(values);
      // console.log(response)
      setValues(initializeFormData);
      setError("");
      userProfile();
    } catch (error) {
      // console.log(error);
      setError(error.response.data.error);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h6 className="card-subtitle text-white">Address Details</h6>
        <p className="mb-0 app-fs-7">
          Others will not be able to see your Personal and Address details!
        </p>
      </div>
      <form onSubmit={onSubmit} className="row g-3">
        <div className="card-body">
          {/* <h6 className="mb-2 card-subtitle">Address Details</h6> */}
          <div className="row form-wrapper-gen w-75">
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="addressLine1">
                  Address Line 1
                </label>
                <input
                  placeholder="Address Line 1"
                  type="text"
                  onChange={(e) => onChange(e)}
                  value={values.address_line1}
                  id="address_line1"
                  name="address_line1"
                  className="form-control form-input fc-fs-8"
                />
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="addressLine2">
                  Address Line 2
                </label>
                <input
                  placeholder="Address Line 2"
                  type="text"
                  onChange={(e) => onChange(e)}
                  value={values.address_line2}
                  id="address_line2"
                  name="address_line2"
                  className="form-control form-input fc-fs-8"
                />
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="city">
                  City
                </label>
                <input
                  placeholder="city"
                  type="text"
                  onChange={(e) => onChange(e)}
                  value={values.city}
                  id="city"
                  name="city"
                  className="form-control form-input fc-fs-8"
                />
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label htmlFor="state" className="form-label fl-fs-9">
                  State:
                </label>
                <select
                  onChange={(e) => onChange(e)}
                  value={values.state}
                  className="form-select form-input fc-fs-8"
                  id="state"
                  name="state"
                  aria-label="Default select"
                  required
                >
                  <option value="" disabled>
                    Select State
                  </option>
                  {states &&
                    states.map((state) => {
                      return (
                        <option key={state.id} value={state.state_abbrev}>
                          {state.state_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="city">
                  Zip Code
                </label>
                <input
                  placeholder="zipcode"
                  type="text"
                  onChange={(e) => onChange(e)}
                  value={values.zipcode}
                  id="zipcode"
                  name="zipcode"
                  className="form-control form-input fc-fs-8"
                />
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="city">
                  Country
                </label>
                <input
                  placeholder="country"
                  type="text"
                  onChange={(e) => onChange(e)}
                  value={values.country}
                  id="country"
                  name="country"
                  className="form-control form-input fc-fs-8"
                />
              </div>
            </div>
            <div className="my-3 col-md-12 col-sm-12 action-buttons">
              <button type="submit" className="btn app-btn-primary-md">
                Update Address
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateAddress;
