import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatMessages from "./chatMessages";
import { setAuthenticated } from "../../redux/slices/authSlice";
import { getUserProfile } from "../../api/authApis";
import ChatSidenav from "./chatSidenav";
import Layout from "../layout/layout";
import { getChatConversations, getChatSenders } from "../../api/chatApis";

const ChatHome = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.email);
  const chatHomeActive = useSelector((state) => state.chatHome.chatHomeActive);

  const [loggedInUser, setLoggedInUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chatUsers, setChatUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [hasSetSelectedUserId, setHasSetSelectedUserId] = useState(false);
  const [refreshMsgs, setRefreshMsgs] = useState(false);

  const fetchProfile = async () => {
    try {
      const { data, status } = await getUserProfile();
      setLoggedInUser(data.user);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("isAuth");
        dispatch(setAuthenticated(false));
      }
      setError(error.response.data.message);
      console.log(error.response.status);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [email]);

  // Get all the messages senders
  const fetchChatSenders = useCallback(async () => {
    try {
      const { data, status } = await getChatSenders();
      // console.log("fetchChatSenders", data);
      if (data && data.rowCount > 0) {
        setChatUsers(data.chatSenders);
        if (!hasSetSelectedUserId) {
          setSelectedUser(data.chatSenders[0]);
          setHasSetSelectedUserId(true);
        }
        fetchChatMessages();
      }
    } catch (error) {
      setError(error.message);
    }
  }, [loggedInUser]);

  useEffect(() => {
    setLoading(true);
    fetchChatSenders().finally(() => setLoading(false));
  }, [loggedInUser]);

  // console.log("chatHome:", selectedUserId);

  // Get all the messages senders
  const fetchChatMessages = async () => {
    try {
      const reqKey = { selectedUserId: selectedUser?.id };
      // console.log(reqKey);
      const { data, status } = await getChatConversations(reqKey);
      // console.log(data);
      if (data && data.rowCount > 0) {
        setMessages(data.chatMessages);
        setRefreshMsgs(false);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    // console.log("selectedUserId:", selectedUserId);
    setLoading(true);
    fetchChatMessages().finally(() => setLoading(false));
  }, [selectedUser, loggedInUser, refreshMsgs]);

  return (
    <Layout>
      <div className="row g-2 flex-nowrap">
        <div className="col-auto col-md-2 col-lg-3 col-xl-3 px-sm-2 px-0 chat-sidenav">
          {/* <div className="row g-4"> */}
          {/* <!-- Sidenav START --> */}
          {/* <div className="col-auto col-md-3 col-lg-3 vstack gap-4 chat-sidenav"> */}
          {/* {chatMessages ? <ChatSidenav /> : <Sidenav />} */}
          <ChatSidenav
            chatUsers={chatUsers}
            error={error}
            setSelectedUser={setSelectedUser}
          />
        </div>
        {/* <!-- Sidenav END --> */}

        {/* <!-- Main content START --> */}
        {/* <div className="col-md-9 col-lg-9 vstack gap-4"> */}
        <div className="col py-3">
          <ChatMessages
            loggedInUser={loggedInUser}
            messages={messages}
            selectedUser={selectedUser}
            setRefreshMsgs={setRefreshMsgs}
          />
        </div>
        {/* <!-- Main content END --> */}
      </div>
    </Layout>
  );
};

export default ChatHome;
