import EventCardHeader from "./eventCardHeader";
import EventCard from "./eventCard";
import { useNavigate } from "react-router-dom";
import {
  getAllEvents,
  getEventInterests,
  getRSVPCount,
} from "../../api/authApis";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponentsDashboard } from "../../redux/slices/dashboardSlice";

const NoEventsFound = () => (
  <div className="row">
    <div className="col-md-12 col-lg-12 vstack gap-2">
      {/* <!-- Card START --> */}
      <div className="card border-0 h-100">
        {/* <!-- Card body START --> */}
        <div className="card-body">
          <div className="row g-4">
            <h5>
              {" "}
              We could not find any Events that match your search criteria!{" "}
            </h5>
          </div>
        </div>
        {/* <!-- Card body END --> */}
      </div>
      {/* <!-- Card END --> */}
    </div>
  </div>
);

const EventCards = ({ eventData, searchFlag }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("isAuth");

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rsvpData, setRsvpData] = useState([]);
  const [values, setValues] = useState("");
  const [interestedEvents, setInterestedEvents] = useState([]);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // Make all the components active = false
  const activeComponentsDashboardNew = useMemo(() => {
    return Object.assign(
      {},
      ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
        [k]: false,
      }))
    );
  }, [activeComponentsDashboard]);

  const createEvent = () => {
    // console.log('createEvent')
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        eventCreate: true,
      })
    );
    navigate("/dashboard");
  };

  // console.log(eventData)
  //call the function only if the user is logged in
  const fetchEventInterests = async () => {
    try {
      const { data } = await getEventInterests();
      setInterestedEvents(data.eventIds);
      // console.log(data.eventIds)
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(eventData)
  const getRSVPCountAll = async () => {
    try {
      const { data } = await getRSVPCount();
      // console.log(data.rsvpCount)
      setRsvpData(data.rsvpCount);
      // console.log(data.rsvpCount)
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEvents = async () => {
    try {
      // console.log(eventData.length)
      if (!searchFlag) {
        const { data } = await getAllEvents();
        setEvents(data.events);
      } else {
        setEvents(eventData);
      }
      getRSVPCountAll();
      // console.log(isAuthenticated)
      if (isAuthenticated) {
        fetchEventInterests();
      }
    } catch (error) {
      setError(error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchEvents().finally(() => setLoading(false));
  }, [eventData]);

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return <div>Oops Something went wrong! We're on it.</div>;
  }

  return (
    <section className="py-5">
      <div className="row">
        <div className="col-md-12 col-lg-12 vstack gap-2">
          <div className="card border-0 h-100">
            <EventCardHeader createEvent={createEvent} />
            <div className="card-body">
              <div className="row g-3">
                {events && events.length === 0 ? (
                  <NoEventsFound />
                ) : (
                  rsvpData &&
                  interestedEvents &&
                  events.map((event) => (
                    <EventCard
                      key={event.id}
                      event={event}
                      interestedEvents={interestedEvents}
                      setInterestedEvents={setInterestedEvents}
                      rsvpData={rsvpData}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventCards;
