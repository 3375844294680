import { useState } from "react";
import Modal from "react-modal";
import { addChatMessage } from "../../api/chatApis";
import { validateChatMessage } from "../helpers/validateChatMessage";

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// };

Modal.setAppElement("#root");

// const ModalRSVP = ({ isOpen, onClose, eventId, setRSVPMsg }) => {
const ChatModal = ({
  setStatusMsg,
  receiver_user_id,
  receiver_email,
  receiver_firstname,
  isOpen,
  onClose,
}) => {
  // console.log(setStatusMsg);
  // const { id: eventId, event_user_id: sender_user_id, signup_user_id: receiver_user_id } = event;

  const initializeForm = {
    receiver_user_id: receiver_user_id,
    message_text: "",
  };

  // console.log(initializeForm)
  // const dispatch = useDispatch();
  // const isModalOpen = useSelector((state) => state.modal.isModalOpen);

  const [formData, setFormData] = useState(initializeForm);
  const [error, setError] = useState(null); // error for form submission
  const [validationErrors, setValidationErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationErrors(null);
    try {
      const errors = validateChatMessage(formData);
      if (Object.keys(errors).length === 0) {
        const response = await addChatMessage(formData);
        setFormData(initializeForm);
        setStatusMsg("Message sent successfully!");
        onClose();
      } else {
        setValidationErrors(errors);
      }
    } catch (error) {
      setError(error);
      setStatusMsg("Message failed to send!");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      // style={customStyles}
      className="modal-dialog"
    >
      <aside className="modal-overlay">
        <div className="modal-container-msg shadow mt-2">
          {/* <div className="card card-body shadow mt-2  mb-4"> */}
          {/* <img src={image} className="img modal-img" alt={title} /> */}
          <div className="modal-content">
            <h5 className="fw-bold mb-1 text-dark">
              Send a Message to {receiver_firstname}
            </h5>
            <form onSubmit={handleSubmit} className="form-wrapper-gen">
              <div className="row">
                <label
                  htmlFor="message_text"
                  className="col-form-label fl-fs-8"
                >
                  Message:
                </label>
                <div className="col-md-12">
                  <textarea
                    rows="4"
                    className="form-control form-input fc-fs-7"
                    id="message_text"
                    name="message_text"
                    value={formData.message_text}
                    onChange={handleInputChange}
                  />
                </div>
                <div id="itemHelp" className="form-text help-text-xs">
                  Maximum 255 characters.
                </div>
                {validationErrors?.message_text && (
                  <div className="app-text-error-xs">
                    {validationErrors.message_text}
                  </div>
                )}
                <div className="d-flex col-md-12 mt-3">
                  <button type="submit" className="btn btn-responsive mx-2">
                    Send Message
                  </button>
                  <button
                    type="button"
                    className="btn btn-responsive"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </aside>
    </Modal>
  );
};

export default ChatModal;
