import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventData: "",
};

export const eventCreateSlice = createSlice({
  name: "eventCreate",
  initialState,
  reducers: {
    setEventData: (state, action) => {
      state.eventData = action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setEventData, reset } = eventCreateSlice.actions;

export default eventCreateSlice.reducer;
