import Layout from "../components/layout/layout";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <Layout>
      <section className="py-4">
        <div className="container">
          <div className="row align-items-center align-contents-center">
            <div className="col-lg-12 align-self-start p-4">
              <h4>SignupQR Terms of Use</h4>
              <p>
                Welcome to SignupQR! These Terms of Use ("Terms") govern your
                access and use of our event management platform. By accessing or
                using the Platform, you agree to be bound by these Terms. If you
                do not agree to these Terms, you may not use the Platform.
              </p>

              <h5>1. Eligibility:</h5>
              <p>
                You must be at least 18 years old and have the legal capacity to
                enter into these Terms in order to use the Platform.
              </p>
              <h5>2. Acceptable Use:</h5>
              <p>
                You agree to use the Platform only for lawful purposes and in
                accordance with these Terms. You will not:
              </p>
              <ul>
                <li> violate any laws or regulations; </li>
                <li>
                  {" "}
                  infringe upon the rights of others, including intellectual
                  property rights;{" "}
                </li>
                <li>
                  {" "}
                  use the Platform to send spam or other unsolicited messages;{" "}
                </li>
                <li> attempt to gain unauthorized access to the Platform; </li>
                <li> interfere with the operation of the Platform; </li>
                <li>
                  {" "}
                  use the Platform to promote or engage in illegal activities.{" "}
                </li>
                <li>transmit any harmful or malicious content;</li>
                <li>impersonate any person or entity;</li>
                <li>
                  use the Platform for any commercial purpose without our
                  express written consent.
                </li>
              </ul>
              <h5>3. User Content:</h5>
              <p>
                You are responsible for any content you submit to the Platform
                ("User Content"). You represent and warrant that you own all
                rights to your User Content or have obtained all necessary
                permissions to submit it. You grant us a non-exclusive,
                worldwide, royalty-free license to use, reproduce, modify,
                publish, and distribute your User Content in connection with the
                Platform. You are solely responsible for the consequences of
                your User Content. We are not obligated to monitor User Content
                but reserve the right to remove any User Content that we deem
                inappropriate or unlawful.
              </p>
              <h5>4. Third-Party Content:</h5>
              <p>
                The Platform may contain links to third-party websites or
                services that are not owned or controlled by us. We are not
                responsible for the content or privacy practices of any
                third-party websites or services. You use such third-party
                websites or services at your own risk.
              </p>

              <h5>5. Disclaimer of Warranties:</h5>
              <p>
                The Platform is provided "as is" and without any warranties of
                any kind, express or implied. We disclaim all warranties,
                including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose, and
                non-infringement. We do not warrant that the Platform will be
                uninterrupted, error-free, or secure.
              </p>
              <h5>6. Limitation of Liability:</h5>
              <p>
                You agree to indemnify and hold harmless us and our affiliates,
                officers, directors, employees, and agents from and against any
                and all claims, losses, damages, liabilities, costs, and
                expenses (including attorneys' fees) arising out of or relating
                to your use of the Platform.
              </p>
              <h5>7. Termination:</h5>
              <p>
                We may terminate your access to the Platform at any time and for
                any reason, with or without notice.
              </p>
              <h5>8. Governing Law:</h5>
              <p>
                These Terms are governed by the laws of the State of Arizona
                without regard to its conflict of law principles.
              </p>
              <h5>8. Entire Agreement:</h5>
              <p>
                These Terms constitute the entire agreement between you and us
                with respect to your use of the Platform.
              </p>
              <h5>9. Amendments:</h5>
              <p>
                We reserve the right to amend these Terms at any time by posting
                the amended Terms on the Platform. Your continued use of the
                Platform following the posting of amended Terms constitutes your
                acceptance of such amendments.
              </p>
              <h5>Contact Us:</h5>
              <p>
                If you have any questions about these Terms, please{" "}
                <Link to="/contactUs">contact us</Link>.
              </p>
              <h5>Effective Date: March, 2024</h5>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Terms;
