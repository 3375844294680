import avatar from "../../assets/images/avatars/profile.png";
import { useState, useEffect } from "react";
import { profileValidate } from "../helpers/validate";
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenticated } from "../../redux/slices/authSlice";
import { getOrgList, getUserProfile, updatePersonalDetails } from "../../api/authApis";
import { setActiveComponentsDashboard } from "../../redux/slices/dashboardSlice";


const UpdatePersonal = () => {
  const dispatch = useDispatch()
  const activeComponentsDashboard = useSelector((state) => state.dashboard.activeComponentsDashboard);

  const [user, setUser] = useState()
  const [error, setError] = useState(false);

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign({},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({ [k]: false }))
  );

  const initializeFormData = {
    first_name: '',
    last_name: '',
    username: '',
    org_id: '',
    mobile: '',
    email: '',
  }
  const [values, setValues] = useState(initializeFormData);
  const [orgList, setOrgList] = useState([]);

  const fetchOrgList = async () => {
    // console.log('fetchStatesList')
    try {
      const { data } = await getOrgList()
      setOrgList(data.orgList)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchProfile = async () => {
    try {
      const { data, status } = await getUserProfile()
      setUser(data.user)
      setValues({
        first_name: data.user?.first_name,
        last_name: data.user?.last_name,
        username: data.user?.username,
        org_id: data.user?.org_id || '',
        mobile: data.user?.mobile_phone,
        email: data.user?.email,
      })
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('isAuth')
        dispatch(setAuthenticated(false));
      }
      console.log(error.response.status)
    }
  }

  useEffect(() => {
    fetchOrgList();
    fetchProfile();
  }, [])


  const userProfile = () => {
    dispatch(setActiveComponentsDashboard({ ...activeComponentsDashboardNew, "profile": true }));
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(values);
      // const errors = await profileDetailsValidate(values);
      // // console.log(errors);
      // if (Object.keys(errors).length > 0) {
      //   setError(Object.values(errors)[0]);
      //   return
      // }
      const response = await updatePersonalDetails(values)
      // console.log(response)
      setValues(initializeFormData)
      setError('');
      userProfile()
    } catch (error) {
      // console.log(error);
      setError(error.response.data.error);
    }
  };


  return (
    <div className="card">
      <div className="card-header">
        <h6 className="card-subtitle text-white">Personal Details</h6>
        <p className="mb-0 app-fs-7">Others will not be able to see your Personal and Address details!</p>
      </div>
      <form onSubmit={(e) => onSubmit(e)} className="row g-3">
        <div className="card-body">
          {/* <h6 className="mb-2 card-subtitle">Personal Details</h6> */}
          <div className="row form-wrapper-gen w-75">
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="first_name">First Name</label>
                <input placeholder="First Name" type="text" onChange={(e) => onChange(e)} value={values.first_name} id="first_name" name="first_name" className="form-control form-input fc-fs-8" />
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="last_name">Last Name</label>
                <input placeholder="Last Name" type="text" onChange={(e) => onChange(e)} value={values.last_name} id="last_name" name="last_name" className="form-control form-input fc-fs-8" />
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="firstName">Username</label>
                <input placeholder="username" type="text" id="username" onChange={(e) => onChange(e)} value={values.username} name="username" className="form-control form-input fc-fs-8" />
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                {/* <div className="mb-1">
                <label htmlFor="organization" className="col-form-label fl-fs-9">Organization</label>
                <input type="text" onChange={(e) => onChange(e)} value={values.organization} className="form-control fc-fs-8" id="organization" name="organization" list="orgList" placeholder="eg: Large" />
              </div> */}
                <label htmlFor="org_id" className="form-label fl-fs-9">Organization</label>
                <select onChange={(e) => onChange(e)} value={values.org_id} className="form-select form-input fc-fs-8" id="org_id" name="org_id" aria-label="Default select"  >
                  <option value='' disabled>Select Organization</option>
                  {orgList && orgList.map((org) => {
                    return (
                      <option key={org.id} value={org.id}>{org.org_name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="mobile">Mobile Phone</label>
                <input placeholder="(###) ###-####" type="text" onChange={(e) => onChange(e)} value={values.mobile} id="mobile" name="mobile" className="form-control form-input fc-fs-8" maxLength="14" />
              </div>
            </div>
            <div className="mb-2 col-md-6 col-sm-12">
              <div className="mb-0">
                <label className="form-label fl-fs-9" htmlFor="email">Email</label>
                <input placeholder="email" readOnly type="text" onChange={(e) => onChange(e)} value={values.email} id="email" name="email" className="form-control form-input fc-fs-8" />
              </div>
            </div>
            <div className="my-3 col-md-12 col-sm-12 action-buttons">
              <button type="submit" className="btn app-btn-primary-md">Update Details</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UpdatePersonal