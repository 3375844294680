import { useDispatch, useSelector } from "react-redux";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../redux/slices/dashboardSlice";
import EventListCard from "./eventListCard";

const InterestedEvents = ({ events, error }) => {
  // const [error, setError] = useState(null)
  // console.log(events)
  const dispatch = useDispatch();
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // Make all the components active = false
  // let activeComponentsDashboardNew = Object.assign({},
  //   ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({ [k]: false }))
  // );
  const activeComponentsDashboardNew = Object.keys(
    activeComponentsDashboard
  ).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});

  const handleActionClick = (item, component) => {
    dispatch(setItem(item));
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        [component]: true,
      })
    );
  };

  // Display the  message if there is are no events
  if (events.length === 0) {
    return (
      <div className="row g-4">
        <div className="col-sm-12 col-xl-12">
          <div className="card border-0 mb-3 d-sm-flex pt-3">
            <div className="card-subheader py-3 alert alert-info w-75">
              You can view all the events you are interested in here. Click on
              the "Interested" button under the event on the events home page to
              add the event to your interested list.
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Display the error message if there is an error
  if (error) {
    return (
      <div className="row g-4">
        <div className="col-sm-12 col-xl-12">
          <h6 className="text-danger">
            Oops! Something went wrong. We are working on fixing this!
          </h6>
        </div>
      </div>
    );
  }

  // console.log(events)
  return (
    <div className="row g-4">
      <div className="col-sm-12 col-xl-12">
        {/* <!-- Event item START --> */}
        <div className="card border-0 mb-3 d-sm-flex p-3">
          {events &&
            events.map((event) => {
              return (
                <EventListCard
                  key={event.id}
                  event={event}
                  handleActionClick={handleActionClick}
                  eventTab="intersetedEvents"
                />
              );
            })}
        </div>
        {/* <!-- Event item END --> */}
      </div>
    </div>
  );
};
export default InterestedEvents;
