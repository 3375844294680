import { useState } from "react";
import FormInput from "../../formComponents/formInput";
import FormSelect from "../../formComponents/formSelect";
import {
  validateStep2a,
  validateStep2b,
} from "../../helpers/validateEventForm";
import FormTextArea from "../../formComponents/formTextArea";

const EventCreateStep2 = ({
  values,
  onChange,
  states,
  handleContinueClick,
  handlePrevClick,
}) => {
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    if (values.event_type === "In person") {
      validationErrors = validateStep2a(values);
    } else if (values.event_type === "In person") {
      validationErrors = validateStep2b(values);
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log(validationErrors);
      return;
    }
    handleContinueClick(e);
  };

  return (
    <div className="card">
      <div className="card-header d-flex pb-2 justify-content-between align-items-center">
        <div>Create Event - Logistics</div>
        <div>Step 2 of 4</div>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          {values.event_type === "Virtual" ? (
            <div className="row form-wrapper-gen w-75">
              <FormInput
                id="location"
                name="location"
                label="Location"
                type="text"
                maxLength="100"
                value={values.location}
                onChange={onChange}
                error={errors?.location}
                colSpan="col-md-4"
                required
              />
              <FormInput
                id="meeting_id"
                name="meeting_id"
                label="Meeting ID"
                type="text"
                maxLength="15"
                value={values.meeting_id}
                onChange={onChange}
                error={errors?.meeting_id}
                colSpan="col-md-4"
              />
              <FormInput
                id="meeting_passcode"
                name="meeting_passcode"
                label="Meeting Passcode"
                type="text"
                maxLength="15"
                value={values.meeting_passcode}
                onChange={onChange}
                error={errors?.meeting_passcode}
                colSpan="col-md-4"
              />
              <FormTextArea
                id="meeting_link"
                name="meeting_link"
                label="Meeting Link"
                rows="2"
                maxLength="150"
                value={values.meeting_link}
                onChange={onChange}
                error={errors?.meeting_details}
                colSpan="col-md-12"
              />
              <div className="col-md-12">
                <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
              </div>
              <div className="col-md-12 d-flex pb-2 justify-content-between align-items-center action-buttons mt-3">
                <button
                  type="button"
                  onClick={handlePrevClick}
                  className="btn btn-responsive me-3"
                >
                  Prev
                </button>
                <button type="submit" className="btn btn-responsive">
                  Save and Continue
                </button>
              </div>
            </div>
          ) : values.event_type === "In person" ? (
            <div className="row form-wrapper-gen w-75">
              <FormInput
                id="location"
                name="location"
                label="Location"
                type="text"
                maxLength="100"
                value={values.location}
                onChange={onChange}
                error={errors?.location}
                colSpan="col-md-4"
                required
              />

              <FormInput
                id="address_line1"
                name="address_line1"
                label="Address Line 1"
                type="text"
                maxLength="50"
                value={values.address_line1}
                onChange={onChange}
                error={errors?.address_line1}
                colSpan="col-md-4"
                required
              />

              <FormInput
                id="address_line2"
                name="address_line2"
                label="Address Line 2"
                type="text"
                maxLength="30"
                value={values.address_line2}
                colSpan="col-md-4"
                onChange={onChange}
              />

              <FormInput
                id="city"
                name="city"
                label="City"
                type="text"
                maxLength="30"
                value={values.city}
                onChange={onChange}
                error={errors?.city}
                colSpan="col-md-4"
                required
              />

              <FormSelect
                id="state"
                name="state"
                label="State"
                value={values.state}
                onChange={onChange}
                options={states.map((state) => ({
                  id: state.state_abbrev,
                  name: state.state_name,
                }))}
                error={errors?.state}
                colSpan="col-md-4"
                required
              />

              <FormInput
                id="zip_code"
                name="zip_code"
                label="Zip Code"
                type="text"
                maxLength="10"
                value={values.zip_code}
                onChange={onChange}
                error={errors?.zip_code}
                colSpan="col-md-4"
                required
              />

              <div className="col-md-12">
                <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
              </div>
              <div className="col-md-12 d-flex pb-2 justify-content-between align-items-center action-buttons mt-3">
                <button
                  type="button"
                  onClick={handlePrevClick}
                  className="btn btn-responsive me-3"
                >
                  Prev
                </button>
                <button type="submit" className="btn btn-responsive">
                  Save and Continue
                </button>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default EventCreateStep2;
