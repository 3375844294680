// Validate Event Form - Step 1
export const validateStep1 = (values) => {
  const errors = validateEventName({}, values);
  validateOrganizedBy(errors, values);
  validateCategory(errors, values);
  validateDates(errors, values);
  validateTime(errors, values);
  return errors;
};

// Validate Event Form - Step 2a - Address details when event type is in-person
export const validateStep2a = (values) => {
  const errors = validateLocation({}, values);
  validateAddress(errors, values);
  return errors;
};

// Validate Event Form - Step 2b - Meeting details when event type is virtual. Just validate location. Other fields are optional
export const validateStep2b = (values) => {
  const errors = validateLocation({}, values);
  return errors;
};

// Validate Event Form - Step 3
export const validateStep3 = (values) => {
  const errors = validateDescription({}, values);
  return errors;
};

// Validate Event Form - Step 3
export const validateInPerson = (values) => {
  const errors = validateEventName({}, values);
  validateOrganizedBy(errors, values);
  validateCategory(errors, values);
  validateDates(errors, values);
  validateTime(errors, values);
  validateLocation({}, values);
  validateAddress(errors, values);
  validateDescription({}, values);
  return errors;
};

// Validate Event Form - Step 3
export const validateVirtual = (values) => {
  const errors = validateEventName({}, values);
  validateOrganizedBy(errors, values);
  validateCategory(errors, values);
  validateDates(errors, values);
  validateTime(errors, values);
  validateLocation({}, values);
  validateDescription({}, values);
  return errors;
};

// validate event name
function validateEventName(errors = {}, values) {
  if (!values.event_name) {
    errors.event_name = "Event Name is required";
  } else if (values.event_name.length > 50) {
    errors.event_name = "Event Name must be no more than 50 characters";
  }
  return errors;
}

// validate organized by
function validateOrganizedBy(errors = {}, values) {
  if (!values.organized_by) {
    errors.organized_by = "Please enter the name of the event ORganizer";
  } else if (values.organized_by.length > 100) {
    errors.event_name = "Organizer name must be no more than 100 characters";
  }
  return errors;
}

// validate Event Category
function validateCategory(errors = {}, values) {
  if (!values.category_id) {
    errors.category_id = "Please select an Event Category";
  }
  return errors;
}

// validate Event Dates
function validateDates(errors = {}, values) {
  if (!values.from_date) {
    errors.from_date = "Please provide the Event Dates";
  }
  if (!values.to_date) {
    errors.to_date = "Please provide the Event Dates";
  }
  if (new Date(values.to_date) < new Date(values.from_date)) {
    errors.to_date = "To Date must be greater than or equal to From Date";
  }
  return errors;
}

// validate Event Times
function validateTime(errors = {}, values) {
  if (!values.from_time) {
    errors.from_time = "From Time is required";
  }
  if (!values.to_time) {
    errors.to_time = "To Time is required";
  }
  return errors;
}

// validate Event Location
function validateLocation(errors = {}, values) {
  if (!values.location) {
    errors.location = "Please provide an event Location";
  }
  return errors;
}

// validate Event Location
function validateAddress(errors = {}, values) {
  if (!values.address_line1) {
    errors.address_line1 = "Please provide an event address";
  } else if (values.address_line1.length > 50) {
    errors.address_line1 = "Address must be no more than 30 characters";
  }
  if (values.address_line2.length > 30) {
    errors.address_line2 = "Address must be no more than 30 characters";
  }
  if (!values.city) {
    errors.city = "Please provide an event city";
  } else if (values.city.length > 30) {
    errors.city = "City must be no more than 30 characters";
  }
  if (!values.state) {
    errors.state = "Please provide the event state";
  }
  if (!values.zip_code) {
    errors.zip_code = "Please provide the address Zip code";
  } else if (values.zip_code.length > 10) {
    errors.city = "Zip code must be no more than 10 characters";
  }
  return errors;
}

// validate Event Description
function validateDescription(errors = {}, values) {
  if (!values.description) {
    errors.description = "Please provide a detailed description of the event";
  }
  return errors;
}
