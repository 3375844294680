import { cancelEvent, getEventRSVPCount } from "../../../api/authApis";
import { useState, useCallback } from "react";
import EventProgram from "./eventProgram";
import EventHeaderLong from "./eventHeaderLong";
import { useSelector } from "react-redux";
import SignupReqs from "./signupReqs";
import SponsorshipReqs from "./sponsorshipReqs";
import ChatModal from "../../chat/chatModal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// const eventId = "acef1608-b81f-4a3e-b983-b5d97ad106b6";

const EventDetails = () => {
  const item = useSelector((state) => state.dashboard.item);
  const userEmail = useSelector((state) => state.auth.email);

  const {
    id,
    user_id,
    organizer_email,
    organizer_firstname,
    is_over,
    has_started,
  } = item;
  // console.log(item);

  const [rsvpCount, setRsvpCount] = useState();
  const [error, setError] = useState(null);
  const [statusMsg, setStatusMsg] = useState(null); // success message for form submission
  const [modalIsOpen, setModalIsOpen] = useState();
  const [cancelBtnActive, setCancelBtnActive] = useState(
    !is_over && !has_started
  );

  // Define your function outside the component or memoize it with useCallback
  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // const getRSVPCount = async () => {
  //   try {
  //     const { data } = await getEventRSVPCount(id);
  //     setRsvpCount(data.rsvpCount);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const cancelEventClick = async () => {
    confirmAlert({
      title: "Confirm to cancel",
      message: "Are you sure you want to cancel the event?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await cancelEvent(id);
              if (response.status === 201) {
                setStatusMsg(
                  "Event has been cancelled. Please inform the volunteers."
                );
                setCancelBtnActive(false);
              } else {
                setStatusMsg("Error cancelling event");
              }
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  // const cancelEventClick = async () => {
  //   if (window.confirm("Are you sure you want to cancel the event?")) {
  //     try {
  //       const response = await cancelEvent(id);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  return (
    <div className="card card-body shadow mt-2 mb-4">
      {item && (
        <>
          <EventHeaderLong item={item} />
          <div>
            <section className="mx-2 pt-0 pb-2">
              <EventProgram eventId={id} />
              {/* Signup Requests start*/}
              <SignupReqs event={item} />
              {/* Signup Requests end*/}
              {/* Sponsorship Requests start*/}
              <SponsorshipReqs event={item} />
              {/* Sponsorship Requests end*/}
            </section>
            {/* <div
              className={`my-3 action-buttons ${
                userEmail === item?.organizer_email ? "hideDiv" : ""
              }`}
            > */}
            <div
              className={`my-3 action-buttons ${
                userEmail === organizer_email ? "" : "hideDiv"
              }`}
            >
              <button
                disabled={!cancelBtnActive}
                className="btn btn-responsive"
                onClick={cancelEventClick}
              >
                {cancelBtnActive}
                Cancel This Event
              </button>
            </div>
            <div
              className={`my-3 action-buttons ${
                userEmail === organizer_email ? "hideDiv" : ""
              }`}
            >
              <button
                className={`btn btn-responsive ${is_over ? "hideBtn" : ""}`}
                onClick={openModal}
              >
                Message Organizer
              </button>
              <ChatModal
                setStatusMsg={setStatusMsg}
                receiver_user_id={user_id}
                receiver_email={organizer_email}
                receiver_firstname={organizer_firstname}
                isOpen={modalIsOpen}
                onClose={closeModal}
              />
            </div>
            {statusMsg && (
              <div className="alert alert-success" role="alert">
                {statusMsg}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default EventDetails;
