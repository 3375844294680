import Layout from "../components/layout/layout";
import Sidenav from "../components/dashboard/sidenav";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProtectedInfo } from "../api/authApis";
import { setAuthenticated } from "../redux/slices/authSlice";
import DashboardComponents from "../components/dashboard/dashboardComponents";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // const chatHomeActive = useSelector((state) => state.chatHome.chatHomeActive);

  // console.log("chatHomeActive", chatHomeActive);
  // const { chatMessages } = activeComponentsDashboard;

  const checkTokenStatus = async () => {
    try {
      const response = await fetchProtectedInfo();
    } catch (error) {
      dispatch(setAuthenticated(false));
      localStorage.removeItem("isAuth");
      setError(error.message || error);
    }
  };

  useEffect(() => {
    checkTokenStatus();
  }, []);

  // Display error state
  if (error) {
    return <div>Oops Something went wrong! We're on it.</div>;
  }

  return (
    <Layout>
      <div className="row g-2 flex-nowrap flex-md-row flex-column-reverse">
        <div className="col-auto col-md-3 col-lg-3 px-sm-2 px-0 dash-sidenav">
          {/* 
      <div className="row g-2 flex-nowrap">
        <div className="col-auto col-md-3 col-lg-3 px-sm-2 px-0 chat-sidenav"> */}
          {/* <div className="row g-4"> */}
          {/* <!-- Sidenav START --> */}
          {/* <div className="col-auto col-md-3 col-lg-3 vstack gap-4 chat-sidenav"> */}
          {/* {chatMessages ? <ChatSidenav /> : <Sidenav />} */}
          {/* {chatHomeActive ? "" : <Sidenav />} */}
          <Sidenav />
        </div>
        {/* <!-- Sidenav END --> */}

        {/* <!-- Main content START --> */}
        {/* <div className="col-md-9 col-lg-9 vstack gap-4"> */}
        <div className="col py-3">
          <DashboardComponents />
        </div>
        {/* <!-- Main content END --> */}
      </div>
    </Layout>
  );
};

export default Dashboard;
