import { useSelector } from "react-redux";
import EventHeaderShort from "./eventHeaderShort";
import SponsorshipResp from "./sponsorshipResp";
import SignupResp from "./signupResp";

const EventSignup = () => {
  const event = useSelector((state) => state.dashboard.item);
  const eventId = event.id;

  return (
    <div className="card card-body mt-2 mb-4">
      <EventHeaderShort item={event} />
      <div className="mt-3">
        <ul className="nav nav-tabs tab-responsive" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active btn-tab-responsive"
              id="signup-tab"
              data-bs-toggle="tab"
              data-bs-target="#signup"
              type="button"
              role="tab"
              aria-controls="signup"
              aria-selected="true"
            >
              Signup Requests
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link btn-tab-responsive "
              id="sponsorship-tab"
              data-bs-toggle="tab"
              data-bs-target="#sponsorship"
              type="button"
              role="tab"
              aria-controls="sponsorship"
              aria-selected="false"
            >
              Sponsorship Requests
            </button>
          </li>
        </ul>
        <div className="tab-content mt-2" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="signup"
            role="tabpanel"
            aria-labelledby="signup-tab"
          >
            <SignupResp eventId={eventId} />
          </div>
          <div
            className="tab-pane fade"
            id="sponsorship"
            role="tabpanel"
            aria-labelledby="sponsorship-tab"
          >
            <SponsorshipResp eventId={eventId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSignup;
