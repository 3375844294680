import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { onLogout } from "../../api/authApis";
import {
  setAuthenticated,
  setActiveComponentsLogin,
  setActiveComponentsRegister,
} from "../../redux/slices/authSlice";
import { setChatHomeActive } from "../../redux/slices/chatSlice";
import { setActiveComponentsDashboard } from "../../redux/slices/dashboardSlice";
import { setEventsTabActive } from "../../redux/slices/eventsTabSlice";
import { reset as resetChatHome } from "../../redux/slices/chatSlice";
import { reset as resetDashboard } from "../../redux/slices/dashboardSlice";
import { reset as resetEventsTab } from "../../redux/slices/eventsTabSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.auth);

  const { firstName, username } = useSelector((state) => state.auth);

  const activeComponentsLogin = useSelector(
    (state) => state.auth.activeComponentsLogin
  );
  const activeComponentsRegister = useSelector(
    (state) => state.auth.activeComponentsRegister
  );
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );
  const eventsTabActive = useSelector(
    (state) => state.eventsTab.eventsTabActive
  );

  // console.log(activeComponents)
  // Make all the components active = false
  let activeComponentsLoginNew = Object.assign(
    {},
    ...Object.entries(activeComponentsLogin).map(([k, v]) => ({ [k]: false }))
  );

  let activeComponentsRegisterNew = Object.assign(
    {},
    ...Object.entries(activeComponentsRegister).map(([k, v]) => ({
      [k]: false,
    }))
  );

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  // Make all the components active = false
  let eventsTabActiveNew = Object.assign(
    {},
    ...Object.entries(eventsTabActive).map(([k, v]) => ({ [k]: false }))
  );

  const setActiveComponent = (componentName) => {
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        [componentName]: true,
      })
    );
  };

  const resetPassword = () => {
    setActiveComponent("updatePassword");
  };

  const userProfile = () => {
    setActiveComponent("profile");
  };

  const logout = async () => {
    try {
      const response = await onLogout();
      // console.log(response)
      dispatch(resetChatHome());
      dispatch(resetDashboard());
      dispatch(resetEventsTab());
      dispatch(setAuthenticated(false));
      localStorage.removeItem("isAuth");
    } catch (error) {
      console.log(error.response);
    }
  };

  const login = () => {
    dispatch(
      setActiveComponentsLogin({ ...activeComponentsLoginNew, login: true })
    );
    navigate("/login");
  };

  const register = () => {
    dispatch(
      setActiveComponentsRegister({
        ...activeComponentsRegisterNew,
        register: true,
      })
    );
    navigate("/register");
  };

  const dashboardHome = (e) => {
    e.preventDefault();
    // dispatch(setChatHomeActive(false));
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        eventList: true,
      })
    );
    dispatch(setEventsTabActive({ ...eventsTabActiveNew, myEvents: true }));
    navigate("/dashboard");
  };

  return (
    <nav className="navbar navbar-expand-lg app-bg-primary mb-4 border-bottom">
      <div className="col-md-3 ps-2 mb-2 mb-md-0">
        <Link to="/" className="navbar-brand">
          {/* <h4 className='text-white'>Logo</h4> */}
          <img
            src="/assets/images/logo/logo5.png"
            alt="logo"
            className="logo"
          />
        </Link>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link px-2 link-light" to="/events">
              Events
            </Link>
          </li>
          <li className="nav-item">
            <button
              onClick={dashboardHome}
              className="nav-link px-2 link-light"
            >
              Dashboard
            </button>
          </li>
          <li className="nav-item">
            <Link className="nav-link px-2 link-light" to="/features">
              Features
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link px-2 link-light" to="/faq">
              FAQs
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link px-2 link-light" to="/aboutUs">
              About
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto">
          {isAuthenticated ? (
            <li className="nav-item dropdown">
              <button
                className="btn btn-outline-light me-2 dropdown-toggle font-responsive"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {firstName ? `Welcome, ${firstName}` : "Welcome"}
              </button>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <button
                  className="dropdown-item font-responsive"
                  onClick={userProfile}
                >
                  Profile
                </button>
                <button
                  className="dropdown-item font-responsive"
                  onClick={resetPassword}
                >
                  Reset Password
                </button>
                <button
                  className="dropdown-item font-responsive"
                  onClick={() => logout()}
                >
                  Logout
                </button>
              </div>
            </li>
          ) : (
            <>
              <li className="nav-item">
                <button
                  className="btn btn-outline-light me-2 font-responsive"
                  onClick={login}
                >
                  Login
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-outline-light me-2 font-responsive"
                  onClick={register}
                >
                  Sign-up
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Header;
