import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  getAllEvents,
  getUserEvents,
  getArchivedEvents,
  getLocalEvents,
  getWeekEvents,
  getInterestedEvents,
} from "../../api/authApis";

import { formatDate, formatTime } from "../helpers/formatDateTime";
import { useDispatch, useSelector } from "react-redux";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../redux/slices/dashboardSlice";
import { setEventsTabActive } from "../../redux/slices/eventsTabSlice";
import MyEvents from "./myEvents";
import LocalEvents from "./localEvents";
import WeekEvents from "./weekEvents";
import InterestedEvents from "./interestedEvents";
import ArchivedEvents from "./archivedEvents";
import { setAuthenticated } from "../../redux/slices/authSlice";
import AllEvents from "./allEvents";

const TabButton = ({ name, title, isActive, handleTabClick }) => (
  <li className="nav-item" role="presentation">
    <button
      className={`${isActive ? "nav-link active" : "nav-link"} smaller-buttons`}
      onClick={() => handleTabClick(name)}
      id={`${name}-tab`}
      data-bs-toggle="tab"
      data-bs-target={`#${name}`}
      type="button"
      role="tab"
      aria-controls={name}
      aria-selected={isActive}
    >
      {title}
    </button>
  </li>
);

const TabContent = ({ name, isActive, Component, events, error }) => (
  <div
    className={`tab-pane fade ${isActive ? "show active" : ""}`}
    id={name}
    role="tabpanel"
    aria-labelledby={`${name}-tab`}
  >
    {isActive && <Component events={events} error={error} />}
  </div>
);

const EventList = () => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );
  const eventsTabActive = useSelector(
    (state) => state.eventsTab.eventsTabActive
  );

  const {
    myEvents,
    allEvents,
    localEvents,
    weekEvents,
    interestedEvents,
    archivedEvents,
  } = eventsTabActive;
  // console.log(eventsTabActive);

  // Make all the components active = false
  // let eventsTabActiveNew = Object.assign({},
  //   ...Object.entries(eventsTabActive).map(([k, v]) => ({ [k]: false }))
  // );
  const eventsTabActiveNew = useMemo(() => {
    return Object.assign(
      {},
      ...Object.entries(eventsTabActive).map(([k, v]) => ({ [k]: false }))
    );
  }, [eventsTabActive]);

  const tabs = [
    { name: "allEvents", title: "All Events", component: AllEvents },
    { name: "myEvents", title: "My Events", component: MyEvents },
    { name: "localEvents", title: "Local", component: LocalEvents },
    { name: "weekEvents", title: "This Week", component: WeekEvents },
    {
      name: "interestedEvents",
      title: "Interested",
      component: InterestedEvents,
    },
    { name: "archivedEvents", title: "Archived", component: ArchivedEvents },
  ];

  const handleTabClick = (tabName) => {
    dispatch(setEventsTabActive({ ...eventsTabActiveNew, [tabName]: true }));
  };

  // Get all the events for the user
  const fetchEvents = async () => {
    // console.log("fetchEvents");
    try {
      let data, status;

      if (myEvents) {
        ({ data, status } = await getUserEvents());
      } else if (allEvents) {
        ({ data, status } = await getAllEvents());
      } else if (localEvents) {
        ({ data, status } = await getLocalEvents());
      } else if (weekEvents) {
        ({ data, status } = await getWeekEvents());
      } else if (interestedEvents) {
        ({ data, status } = await getInterestedEvents());
      } else if (archivedEvents) {
        ({ data, status } = await getArchivedEvents());
      }
      if (data && data.rowCount > 0) {
        setEvents(data.events);
      } else {
        setEvents([]);
      }
    } catch (error) {
      const status = error.response?.status;
      if (status === 401) {
        dispatch(setAuthenticated(false));
        localStorage.removeItem("isAuth");
      }
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchEvents().finally(() => setLoading(false));
  }, [eventsTabActive]);

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card-body">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {tabs.map(({ name, title }) => (
          <TabButton
            key={name}
            name={name}
            title={title}
            isActive={eventsTabActive[name]}
            handleTabClick={handleTabClick}
            className="smaller-tabs"
          />
        ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        {tabs.map(({ name, component: Component }) => (
          <TabContent
            key={name}
            name={name}
            isActive={eventsTabActive[name]}
            Component={Component}
            events={events}
            error={error}
          />
        ))}
      </div>
      {error && <div>Error: {error}</div>}
    </div>
  );
};

export default EventList;
