import { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getEventRSVPList, getEventRSVPCount } from "../../../api/authApis";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponentsDashboard } from "../../../redux/slices/dashboardSlice";

const EventRSVPList = () => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.dashboard.item);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const eventId = event.id;
  const event_name = event.event_name;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rsvpSummary, setRsvpSummary] = useState();

  const fetchEventRSVPCount = async () => {
    try {
      const { data } = await getEventRSVPCount(eventId);
      // console.log(data.rsvpCounts);
      setRsvpSummary(data.rsvpCounts);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const fetchRSVPList = async () => {
    try {
      const { data } = await getEventRSVPList(eventId);
      // console.log(data.rsvpList);
      setTableData(data.rsvpList);
    } catch (error) {
      console.log(error.message);
      setError(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchRSVPList();
    fetchEventRSVPCount().finally(() => setLoading(false));
  }, []);

  const handleBackButtonClick = () => {
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        eventList: true,
      })
    );
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    const header = "RSVP List for " + event.event_name;
    doc.text(header, 20, 10);

    // First table - Individual RSVP Details
    const rsvpTableColumn = ["Name", "Email", "# of Adults", "# of Children"];
    const rsvpTableRows = [];
    tableData?.forEach((item) => {
      const rsvpDetails = [
        item.first_name + " " + item.last_name,
        item.email,
        item.adult_count,
        item.children_count,
      ];
      rsvpTableRows.push(rsvpDetails);
    });
    doc.autoTable({
      head: [rsvpTableColumn],
      body: rsvpTableRows,
      // Include any additional options you need for this table
    });

    // Check the position after the first table
    let currentPosition = doc.autoTable.previous.finalY;

    // Add a new page if needed
    if (currentPosition >= doc.internal.pageSize.height - 20) {
      doc.addPage();
      currentPosition = 20; // Reset Y position for the new page
    }

    // Summary header position on the same page or new page
    let summaryHeaderY = currentPosition + 10;
    // doc.addPage();
    // let summaryHeaderY = 10;
    const summaryHeader = "RSVP Summary";
    doc.text(summaryHeader, 20, summaryHeaderY);

    // Summary table position
    let summaryTableStartY = summaryHeaderY + 10;

    // Second table - RSVP Summary
    const summaryTableColumn = [
      "RSVP Count",
      "Adult Count",
      "Children Count",
      "Attendance Count",
    ];
    const summaryTableRows = [
      [
        rsvpSummary?.rsvp_count,
        rsvpSummary?.adult_count,
        rsvpSummary?.children_count,
        rsvpSummary?.attendance_count,
      ],
    ];
    doc.autoTable({
      head: [summaryTableColumn],
      body: summaryTableRows,
      startY: summaryTableStartY,
      // Include any additional options you need for this table
    });

    // Save the PDF document
    doc.save(`rsvp_list.pdf`);
  };

  // Function to generate and download PDF
  // const downloadPdf = () => {
  //   try {
  //     const doc = new jsPDF();
  //     const header = "RSVP List for " + event.event_name;
  //     doc.text(header, 20, 10);

  //     const tableColumn = ["Name", "Email", "# of Adults", "# of Children"];
  //     const tableRows = [];

  //     tableData?.forEach((item) => {
  //       const itemData = [
  //         item.first_name + " " + item.last_name,
  //         item.email,
  //         item.adult_count,
  //         item.children_count,
  //       ];
  //       tableRows.push(itemData);
  //     });

  //     doc.autoTable(tableColumn, tableRows, { startY: 20 });
  //     doc.save("rsvp.pdf");
  //   } catch (error) {}
  // };

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Oops! Something went wrong. We are working on fixing this!
      </div>
    );
  }

  // Display error state
  if (tableData.length === 0) {
    return (
      <div>
        <div className="card shadow mb-2">
          <div className="card-header">RSVP List for {event_name}</div>
          <div className="card-body">
            <div className="alert alert-info" role="alert">
              No RSVPs yet!
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <button
            type="button"
            className="btn btn-responsive"
            onClick={handleBackButtonClick}
          >
            Back to My Events
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="card shadow mb-2">
        <div className="card-header d-flex pb-2 justify-content-between align-items-center">
          RSVP List for {event_name}
          <i
            className="fa fa-file-pdf fa-lg app-icon-style"
            title="Export List to pdf"
            aria-hidden="true"
            onClick={downloadPdf}
          ></i>
        </div>
        <div className="card-body">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col" className="row-text-sm">
                  Name
                </th>
                <th scope="col" className="row-text-sm">
                  Email
                </th>
                <th scope="col" className="row-text-sm">
                  # of Adults
                </th>
                <th scope="col" className="row-text-sm">
                  # of Children
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item, index) => (
                <tr key={index}>
                  <td className="align-middle row-text-sm">
                    {item.first_name} {item.last_name}
                  </td>
                  <td className="align-middle row-text-sm">{item.email}</td>
                  <td className="align-middle row-text-sm">
                    {item.adult_count}
                  </td>
                  <td className="align-middle row-text-sm">
                    {item.children_count}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="action-buttons">
        <button
          type="button"
          className="btn app-btn-primary-sm"
          onClick={handleBackButtonClick}
        >
          Back to My Events
        </button>
      </div>
    </div>
  );
};

export default EventRSVPList;
