// Validate Registration Form
export const validateRegistration = (values) => {
  const errors = validateFirstName({}, values);
  validateLastName(errors, values);
  validateEmail(errors, values);
  validatePassword(errors, values);
  validateConfPassword(errors, values);
  return errors;
};

// validate First name
function validateFirstName(errors = {}, values) {
  if (!values.first_name) {
    errors.first_name = "First Name is required";
  } else if (values.first_name.length > 30) {
    errors.first_name = "First Name must be no more than 30 characters";
  }
  return errors;
}

// validate Last name
function validateLastName(errors = {}, values) {
  if (!values.last_name) {
    errors.last_name = "Last Name is required";
  } else if (values.last_name.length > 30) {
    errors.last_name = "Last Name must be no more than 30 characters";
  }
  return errors;
}

// validate email
function validateEmail(error = {}, values) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!values.email) {
    error.email = "Email is required";
  } else if (values.email.includes(" ")) {
    error.email = "Invalid email";
  } else if (!emailPattern.test(values.email)) {
    error.email = "Email format is incorrect";
  }
  return error;
}

// validate First name
function validatePassword(errors = {}, values) {
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6 || values.password.length > 15) {
    errors.password = "Password must be between 6 and 15 chars long";
  }
  return errors;
}

// validate First name
function validateConfPassword(errors = {}, values) {
  // console.log(values);
  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }
  return errors;
}
