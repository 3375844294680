import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEvent, updateEvent } from "../../../api/authApis";
import {
  reset as resetDashboard,
  setActiveComponentsDashboard,
} from "../../../redux/slices/dashboardSlice";
import { reset as resetEventsTab } from "../../../redux/slices/eventsTabSlice";
import { reset as resetCreateEvent } from "../../../redux/slices/eventCreateSlice";
import EventCreateStep1 from "./eventCreateStep1";
import EventCreateStep2 from "./eventCreateStep2";
import EventCreateStep3 from "./eventCreateStep3";
import EventCreateStep4 from "./eventCreateStep4";
import EventCreateStep5 from "./eventCreateStep5";
import { formatCurrentDate } from "../../helpers/formatDateTime";
import { getEventCategories } from "../../../api/authApis";
import { getStatesList } from "../../../api/authApis";
import { decode } from "html-entities";
import { setAuthenticated } from "../../../redux/slices/authSlice";

const EventCreate = () => {
  const dispatch = useDispatch();
  const eventData = useSelector((state) => state.eventCreate.eventData);
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const [eventCategories, setEventCategories] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState("");

  const {
    id = "",
    event_name = "",
    organized_by = "",
    organizer_initials = "",
    event_type = "In person",
    category_id = "",
    category_name = "",
    from_date = formatCurrentDate(),
    to_date = formatCurrentDate(),
    from_time = "10:00",
    to_time = "17:00",
    numDays = "",
    location = event_type === "In person" ? "" : "Virtual Meeting",
    address_line1 = "",
    address_line2 = "",
    city = "",
    state = "",
    zip_code = "",
    description = "Brief description of the event. This will be displayed on the event page.",
    contact_details = "",
    pic_filename = "",
    pic_folder = "",
    pic_caption = "",
    fb_page = "",
    instagram_page = "",
    twitter_handle = "",
    is_private = false,
    is_active = false,
    status = "Draft",
    event_details = "",
    imageUrl = pic_folder + "/" + pic_filename,
    action = "Save Draft",
  } = eventData || {};

  // console.log("event_details", event_details);

  const initializeFormData = {
    id,
    event_name,
    organized_by,
    organizer_initials,
    event_type,
    category_id,
    category_name,
    from_date,
    to_date,
    from_time,
    to_time,
    numDays,
    location,
    address_line1,
    address_line2,
    city,
    state,
    zip_code,
    meeting_id: event_details?.meeting_id,
    meeting_passcode: event_details?.meeting_passcode,
    meeting_link: event_details?.meeting_link
      ? event_details?.meeting_link
      : "Meeting Link will be shared to the registered participants.",
    description: decode(description),
    contact_details: contact_details,
    // contact_details ||
    // "Please scan the QR code to RSVP for the event. For any queries, please contact the organizer.",
    pic_filename,
    pic_folder,
    pic_caption: pic_caption || event_name,
    defaultImage:
      pic_filename.includes("Default-") || pic_filename === "" ? true : false,
    fb_page,
    instagram_page,
    twitter_handle,
    is_private,
    is_active: false,
    status: "Draft",
    imageUrl,
    action: "Save Draft",
  };

  const [values, setValues] = useState(initializeFormData);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);

  const fetchStatesList = async () => {
    // console.log('fetchStatesList')
    try {
      const { data } = await getStatesList();
      // console.log(data.states)
      setStates(data.states);
    } catch (error) {
      console.log(error);
    }
  };

  function getCategoryNameById(id) {
    // console.log("id", id);
    return eventCategories.find((obj) => obj.id === id)?.name;
  }

  const fetchEventCategories = async () => {
    try {
      const { data } = await getEventCategories();
      // console.log(data.categories);
      setEventCategories(data.categories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEventCategories();
    fetchStatesList();
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;

    // Convert the value for is_private to boolean if the changed field is is_private
    if (name === "is_private") {
      newValue = value === "private" ? true : false;
    }

    if (name === "category_id") {
      const categoryName = getCategoryNameById(value);
      setValues((prevValues) => ({
        ...prevValues,
        [name]: newValue,
        category_name: categoryName,
      }));
    }
    // If the changed field is from_date, also update to_date
    if (name === "from_date") {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: newValue,
        to_date: newValue,
      }));
    } else {
      setValues((prevValues) => ({ ...prevValues, [name]: newValue }));
    }
  };

  const handleEditorContentChange = (editorContent) => {
    // setValues({ ...values, ["description"]: editorContent });
    Object.assign(values, { description: editorContent });
  };

  const redirectToMyEvents = () => {
    dispatch(resetCreateEvent());
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        eventList: true,
      })
    );
  };

  const handleDraftClick = () => {
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        eventDrafts: true,
      })
    );
  };

  const handlePrevClick = () => {
    if (currentStep <= 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCancelClick = () => {
    dispatch(resetDashboard());
    dispatch(resetEventsTab());
  };

  const handleContinueClick = async (e) => {
    e.preventDefault();
    // console.log("values", values);
    let response;
    try {
      // console.log("values", values.id);
      if (values.id) {
        response = await updateEvent(values);
      } else {
        response = await createEvent(values);
      }

      if (response.status === 201) {
        setValues((prevValues) => ({
          ...prevValues,
          id: response.data.event.id,
          organizer_initials: response.data.event.organizer_initials,
          pic_folder: response.data.event.pic_folder,
          pic_filename: response.data.event.pic_filename,
          pic_caption: response.data.event.pic_caption
            ? response.data.event.pic_caption
            : response.data.event.event_name,
          imageUrl:
            response.data.event.pic_folder +
            "/" +
            response.data.event.pic_filename,
        }));

        setCurrentStep(currentStep + 1);
        // console.log("currentStep", currentStep + 1);
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("isAuth");
        dispatch(setAuthenticated(false));
      } else {
        console.log(error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const eventPublish = {
        ...values,
        is_active: true,
        status: "Published",
        action: "Publish",
      };
      console.log("eventPublish", eventPublish);
      const response = await updateEvent(eventPublish);
      // console.log("response", response);
      if (response.status === 201) {
        console.log("Event created successfully");
        redirectToMyEvents();
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("isAuth");
        dispatch(setAuthenticated(false));
      } else {
        console.log(error);
        setError("Oops! Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      {currentStep === 1 && (
        <EventCreateStep1
          values={values}
          onChange={onChange}
          errors={errors}
          eventCategories={eventCategories}
          handleContinueClick={handleContinueClick}
          handleCancelClick={handleCancelClick}
          error={error}
        />
      )}
      {currentStep === 2 && (
        <EventCreateStep2
          values={values}
          onChange={onChange}
          errors={errors}
          states={states}
          handleContinueClick={handleContinueClick}
          handlePrevClick={handlePrevClick}
          error={error}
        />
      )}
      {currentStep === 3 && (
        <EventCreateStep3
          values={values}
          onChange={onChange}
          handleEditorContentChange={handleEditorContentChange}
          handleContinueClick={handleContinueClick}
          handlePrevClick={handlePrevClick}
        />
      )}
      {currentStep === 4 && (
        <EventCreateStep4
          values={values}
          onChange={onChange}
          handleContinueClick={handleContinueClick}
          handlePrevClick={handlePrevClick}
          error={error}
        />
      )}
      {currentStep === 5 && (
        <EventCreateStep5
          values={values}
          handleSubmit={handleSubmit}
          handlePrevClick={handlePrevClick}
          handleDraftClick={handleDraftClick}
        />
      )}
    </>
  );
};

export default EventCreate;
