import DOMPurify from "dompurify";
import { decode } from "html-entities";

const EventDescriptionPrint = ({ description }) => {
  const decodedHtml = decode(DOMPurify.sanitize(description));

  return (
    <div className="row mt-2">
      {/* <!-- Content --> */}
      <div>
        <h3>About this event</h3>
        {/* <div
          className="me-2"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        /> */}
        <div
          className="me-2 font-responsive"
          dangerouslySetInnerHTML={{ __html: decodedHtml }}
        />
        {/* <div id="eventDesc" className="me-2" /> */}
      </div>
      {/* <!-- Main content END --> */}
    </div>
  );
};

export default EventDescriptionPrint;
