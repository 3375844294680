import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatHomeActive: false,
};

export const chatSlice = createSlice({
  name: "chatHome",
  initialState,
  reducers: {
    setChatHomeActive: (state, action) => {
      state.chatHomeActive = action.payload;
      // console.log("chatSlice", state.chatHomeActive);
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setChatHomeActive, reset } = chatSlice.actions;

export default chatSlice.reducer;
