import { useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import Modal from "react-modal";
// import { closeModal } from '../redux/slices/modalSlice';
import { addEventRSVP } from "../../api/authApis";
import { validateRSVP } from "../helpers/validateRSVPForm";

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '60%', // Adjust the width as needed
//     maxHeight: '80%', // Adjust the max height as needed
//     overflow: '', // Allow scrolling if content overflows
//   },
// };

Modal.setAppElement("#root");

// const ModalRSVP = ({ isOpen, onClose, eventId, setRSVPMsg }) => {
const ModalRSVP = ({ isOpen, onClose, eventId, setRSVPMsg }) => {
  const initializeForm = {
    eventId: "",
    firstName: "",
    lastName: "",
    email: "",
    adultCount: "",
    childrenCount: "",
    comments: "",
  };

  const [values, setValues] = useState(initializeForm);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateRSVP(values);
    if (Object.keys(errors).length === 0) {
      values.eventId = eventId;
      if (values.childrenCount === "") {
        values.childrenCount = null;
      }
      if (values.adultCount === "") {
        values.adultCount = null;
      }
      // console.log('Form data submitted:', values);
      try {
        const response = await addEventRSVP(values);
        setRSVPMsg(
          `Thank you ${values.firstName}. We look forward to seeing you at the event!`
        );
        setValues(initializeForm);
        setErrors({});
      } catch (error) {
        console.log(error);
      }
      onClose();
    } else {
      setErrors(errors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal-dialog">
      <aside className="modal-overlay">
        <div className="modal-container shadow mt-2">
          {/* <div className="card card-body shadow mt-2  mb-4"> */}
          {/* <img src={image} className="img modal-img" alt={title} /> */}
          <div className="modal-content">
            <h5 className="fw-bold mb-1 text-dark">
              We look forward to seeing you at the event !
            </h5>
            <form onSubmit={handleSubmit} className="form-wrapper-gen">
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="form-group mb-1">
                    <label htmlFor="name" className="col-form-label fl-fs-8">
                      First Name:
                    </label>
                    <input
                      required
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-control form-input fc-fs-7"
                      value={values.firstName}
                      onChange={handleInputChange}
                    />
                    {errors?.firstName && (
                      <div className="app-text-error">{errors?.firstName}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-1">
                    <label htmlFor="name" className="col-form-label fl-fs-8">
                      Last Name:
                    </label>
                    <input
                      required
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="form-control form-input fc-fs-7"
                      value={values.lastName}
                      onChange={handleInputChange}
                    />
                    {errors?.lastName && (
                      <div className="app-text-error">{errors?.lastName}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-1">
                    <label htmlFor="email" className="col-form-label fl-fs-8">
                      Email:
                    </label>
                    <input
                      required
                      type="email"
                      id="email"
                      name="email"
                      className="form-control form-input fc-fs-7"
                      value={values.email}
                      onChange={handleInputChange}
                    />
                    {errors?.email && (
                      <div className="app-text-error">{errors?.email}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-1">
                    <label
                      htmlFor="adultCount"
                      className="col-form-label fl-fs-8"
                    >
                      Adults:{" "}
                    </label>
                    <input
                      type="number"
                      min="1"
                      value={values.adultCount}
                      onChange={handleInputChange}
                      className="form-control form-input fc-fs-7"
                      id="adultCount"
                      name="adultCount"
                    />
                    {errors?.adultCount && (
                      <div className="app-text-error">{errors?.adultCount}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-1">
                    <label
                      htmlFor="childrenCount"
                      className="col-form-label fl-fs-8"
                    >
                      Children:{" "}
                    </label>
                    <input
                      type="number"
                      min="1"
                      value={values.childrenCount}
                      onChange={handleInputChange}
                      className="form-control form-input fc-fs-7"
                      id="childrenCount"
                      name="childrenCount"
                    />
                  </div>
                </div>
                <label htmlFor="comments" className="col-form-label fl-fs-8">
                  Comments:
                </label>
                <div className="col-md-12">
                  <textarea
                    rows="2"
                    className="form-control form-input fc-fs-7"
                    id="comments"
                    name="comments"
                    value={values.comments}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex col-md-12 mt-3">
                  <button type="submit" className="btn btn-responsive mx-2">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-responsive"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </aside>
    </Modal>
  );
};

export default ModalRSVP;
