import Layout from '../components/layout/layout'

const Testimonials = () => {
  return (
    <Layout>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto text-center">
              <h2 className="text-gradient text-primary mb-0">What random people</h2>
              <h2 className="mb-3">Think about us</h2>
              <p>That’s the main thing people are controlled by! Thoughts- their perception of themselves! </p>
            </div>
          </div>
          <div className="row mt-6">
            <div className="col-lg-4 col-md-8">
              <div className="card card-plain">
                <div className="card-body">
                  <div className="author">
                    <img src="../../assets/img/team-3.jpg" alt="..." className="avatar shadow" />
                    <div className="name ps-2">
                      <span>Mathew Glock</span>
                      <div className="stats">
                        <small><i className="far fa-clock"></i> 5 min read</small>
                      </div>
                    </div>
                  </div>
                  <p className="mt-4">"If you have the opportunity to play this game of life you need to appreciate every moment."</p>
                  <div className="rating mt-3">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="far fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 ms-md-auto">
              <div className="card bg-gradient-primary">
                <div className="card-body">
                  <div className="author align-items-center">
                    <img src="../../assets/img/marie.jpg" alt="..." className="avatar shadow" />
                    <div className="name ps-2">
                      <span className="text-white">Mathew Glock</span>
                      <div className="stats">
                        <small className="text-white">Posted on 28 February</small>
                      </div>
                    </div>
                  </div>
                  <p className="mt-4 text-white">"If you have the opportunity to play this game of life you need to appreciate every moment."</p>
                  <div className="rating mt-3">
                    <i className="fas fa-star text-white"></i>
                    <i className="fas fa-star text-white"></i>
                    <i className="fas fa-star text-white"></i>
                    <i className="far fa-star text-white"></i>
                    <i className="far fa-star text-white"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8">
              <div className="card card-plain">
                <div className="card-body">
                  <div className="author">
                    <img src="../../assets/img/team-2.jpg" alt="..." className="avatar shadow" />
                    <div className="name ps-2">
                      <span>Mathew Glock</span>
                      <div className="stats">
                        <small><i className="far fa-clock"></i> 5 min read</small>
                      </div>
                    </div>
                  </div>
                  <p className="mt-4">"If you have the opportunity to play this game of life you need to appreciate every moment."</p>
                  <div className="rating mt-3">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="horizontal dark my-5" />
          <div className="row">
            <div className="col-lg-2 col-6">
              <img className="w-100 opacity-6" src="../../assets/img/logos/gray-logos/logo-apple.svg" alt="Logo" />
            </div>
            <div className="col-lg-2 col-6">
              <img className="w-100 opacity-6" src="../../assets/img/logos/gray-logos/logo-facebook.svg" alt="Logo" />
            </div>
            <div className="col-lg-2 col-6">
              <img className="w-100 opacity-6" src="../../assets/img/logos/gray-logos/logo-behance.svg" alt="Logo" />
            </div>
            <div className="col-lg-2 col-6">
              <img className="w-100 opacity-6" src="../../assets/img/logos/gray-logos/logo-spotify.svg" alt="Logo" />
            </div>
            <div className="col-lg-2 col-6">
              <img className="w-100 opacity-6" src="../../assets/img/logos/gray-logos/logo-coinbase.svg" alt="Logo" />
            </div>
            <div className="col-lg-2 col-6">
              <img className="w-100 opacity-6" src="../../assets/img/logos/gray-logos/logo-pinterest.svg" alt="Logo" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Testimonials