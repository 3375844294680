import { Link, useNavigate } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";

const EventCardHeader = ({ createEvent }) => {
  return (
    <div className="mb-2">
      <div className="card-header d-flex justify-content-between align-items-center justify-content-sm-between px-2 px-sm-2">
        <span className="h4-responsive">Discover Events</span>
        {/* <!-- Button modal --> */}
        <button
          className="btn btn-responsive mx-2 mx-sm-2"
          onClick={createEvent}
        >
          <i
            className="fa fa-plus fa-lg me-1"
            title="Create Event"
            aria-hidden="true"
          />
          Create event
        </button>
      </div>
    </div>
  );
};

export default EventCardHeader;
