import "../../assets/styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
// import { resetPasswordValidate } from "../helpers/validate";
import { resetPassword } from "../../api/authApis";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponentsLogin } from "../../redux/slices/authSlice";
import { validatePassword } from "../helpers/validateLoginForm";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeComponentsLogin = useSelector(
    (state) => state.auth.activeComponentsLogin
  );

  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    page: "resetPassword",
  });
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({});

  // Make all the components active = false
  let activeComponentsLoginNew = Object.assign(
    {},
    ...Object.entries(activeComponentsLogin).map(([k, v]) => ({ [k]: false }))
  );

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const showLogin = () => {
    dispatch(
      setActiveComponentsLogin({ ...activeComponentsLoginNew, login: true })
    );
    navigate("/login");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(values);
    try {
      const errors = validatePassword(values);
      // console.log(errors);
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      const { data, status } = await resetPassword(values);
      // console.log(status)
      if (status === 204) {
        dispatch(
          setActiveComponentsLogin({ ...activeComponentsLoginNew, login: true })
        );
      }
    } catch (error) {
      console.log(error);
      setError(error.response.data.error);
    }
  };

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <div className="title text-center">
          <h5>Reset Password</h5>
        </div>
        <form onSubmit={handleSubmit} className="text-center">
          <div className="form-group mb-3">
            <input
              type="password"
              className="form-control textbox"
              onChange={(e) => onChange(e)}
              id="password"
              name="password"
              value={values.password}
              placeholder="Password"
              required
            />
            {errors?.password && (
              <div className="app-text-error-xs">{errors?.password}</div>
            )}
          </div>
          <div className="textbox mb-2">
            <input
              type="password"
              className="form-control textbox"
              onChange={(e) => onChange(e)}
              id="confirmPassword"
              name="confirmPassword"
              value={values.confirmPassword}
              placeholder="Re-enter Password"
              required
            />
            {errors?.confirmPassword && (
              <div className="app-text-error-xs">{errors?.confirmPassword}</div>
            )}
          </div>
          <div className="mb-2 action-buttons">
            <div className="app-fs-8" style={{ color: "red" }}>
              {error}
            </div>
            <button type="submit" className="btn app-btn-primary w-100">
              Reset
            </button>
          </div>
          <div className="mb-2">
            <span className="app-fs-9">
              Back to <Link onClick={showLogin}>Login</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
