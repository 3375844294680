import { useState } from "react";
import FormInput from "../../formComponents/formInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import { sanitizeText } from "../../helpers/sanitize";
import DOMPurify from "dompurify";
import { validateStep3 } from "../../helpers/validateEventForm";

const EventCreateStep3 = ({
  values,
  onChange,
  handleEditorContentChange,
  handleContinueClick,
  handlePrevClick,
}) => {
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["clean"],
      ["link", "image", "video"],
    ],
  };

  // useEffect(() => {
  //   const tooltipTriggerList = Array.from(
  //     document.querySelectorAll('[data-bs-toggle="tooltip"]')
  //   );
  //   tooltipTriggerList.forEach(
  //     (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
  //   );
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    validationErrors = validateStep3(values);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log(validationErrors);
      return;
    }
    const cleanDescription = sanitizeText(
      DOMPurify.sanitize(values.description)
    );
    const cleanContact = sanitizeText(
      DOMPurify.sanitize(values.contact_details)
    );
    // console.log(cleanText);
    values.description = cleanDescription;
    values.contact_details = cleanContact;
    handleContinueClick(e);
  };

  return (
    <div className="card">
      <div className="card-header d-flex pb-2 justify-content-between align-items-center">
        <div>Create Event - Description</div>
        <div>Step 3 of 4</div>
      </div>

      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row form-wrapper-gen w-75">
            <div className="col-md-12">
              <div className="mb-2">
                <label htmlFor="description" className="form-label fl-fs-9">
                  Event Description<span style={{ color: "red" }}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  value={values.description}
                  onChange={handleEditorContentChange}
                  modules={modules}
                  className="ql-editor fc-fs-8"
                />
                {errors?.description && (
                  <div className="app-text-error-xs">{errors?.description}</div>
                )}
              </div>
            </div>

            <div className="col-md-12 mb-2">
              <label htmlFor="contact_details" className="form-label fl-fs-9">
                Contact Details<span style={{ color: "red" }}>*</span>
                <span
                  className="ms-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="The contact details will be displayed alongside a QR code once the event is created. 
                  Attendees will be able to scan the QR code to directly view the event page.
                  The text entered here will provide information on how to contact the event organizer for more details."
                >
                  <i className="bi bi-question-circle"></i>
                </span>
              </label>
              <textarea
                onChange={onChange}
                id="contact_details"
                name="contact_details"
                value={values.contact_details}
                rows="2"
                maxLength="250"
                className="form-control form-input fc-fs-8 hide-placeholder"
                placeholder="Please scan the QR code to RSVP for the event. For any queries, please contact the organizer"
                required
              ></textarea>
            </div>

            <FormInput
              id="fb_page"
              name="fb_page"
              label="FB Page"
              type="text"
              maxLength="100"
              value={values.fb_page}
              onChange={onChange}
              colSpan="col-md-4"
            />

            <FormInput
              id="instagram_page"
              name="instagram_page"
              label="Instagram Page"
              type="text"
              maxLength="100"
              value={values.instagram_page}
              onChange={onChange}
              colSpan="col-md-4"
            />

            <FormInput
              id="twitter_handle"
              name="twitter_handle"
              label="Twitter Handle"
              type="text"
              maxLength="100"
              value={values.twitter_handle}
              onChange={onChange}
              colSpan="col-md-4"
            />

            <div className="col-md-12">
              <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
            </div>
            <div className="col-md-12 d-flex pb-2 justify-content-between align-items-center action-buttons mt-3">
              <button
                type="button"
                onClick={handlePrevClick}
                className="btn btn-responsive me-3"
              >
                Prev
              </button>
              <button type="submit" className="btn btn-responsive">
                Save and Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventCreateStep3;
