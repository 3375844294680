import Layout from "../components/layout/layout";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <Layout>
      <section className="py-4">
        <div className="container">
          <div className="row align-items-center align-contents-center">
            <div className="col-lg-12 align-self-start p-4">
              <h4>Introduction</h4>
              <p>
                This Privacy Policy explains how <strong>SignupQR</strong>{" "}
                ("we", "us", or "our") collects, uses, shares, and protects your
                personal information when you use our event management platform.
              </p>

              <h5>Information We Collect:</h5>
              <p>We collect only the following personal information:</p>
              <ul>
                <li>
                  <strong>Email Address:</strong> This is mandatory for user
                  authentication and to communicate essential information
                  related to your events.
                </li>
                <li>
                  <strong>Optional Information:</strong> Depending on your event
                  management needs, you may choose to provide additional
                  information such as name, organization, phone number, address,
                  etc.
                </li>
              </ul>

              <h5>How We Use Your Information:</h5>
              <p>We use your information for the following purposes:</p>
              <ul>
                <li>
                  <strong>User Authentication:</strong> Your email address is
                  used to verify your identity and grant access to the Platform.
                </li>
                <li>
                  <strong>Event Management:</strong> We may use your information
                  to help you manage your events, including sending event
                  updates, confirmations, and reminders.
                </li>
                <li>
                  <strong>Customer Support:</strong> We may use your information
                  to respond to your inquiries and provide customer support.
                </li>
                <li>
                  <strong>Analytics and Improvement:</strong> We may use your
                  information to analyze Platform usage and improve user
                  experience.
                </li>
              </ul>

              <h5>Sharing Your Information:</h5>
              <p>
                We will not share your personal information with any third
                parties except in the following limited circumstances:
              </p>
              <ul>
                <li>
                  <strong>Service Providers:</strong> We may share your
                  information with trusted third-party service providers who
                  help us operate the Platform, such as email service providers
                  or analytics providers. These providers are obligated to keep
                  your information confidential and may only use it to provide
                  services on our behalf.
                </li>
                <li>
                  <strong>Legal Requirements:</strong> We may disclose your
                  information if required by law, such as in response to a court
                  order or subpoena.
                </li>
                <li>
                  <strong>Protection of Rights:</strong> We may disclose your
                  information if we believe it is necessary to protect our
                  rights and safety or the rights and safety of others.
                </li>
              </ul>

              <h5>Your Choices and Rights:</h5>
              <p>
                You have the following rights regarding your personal
                information:
              </p>
              <ul>
                <li>
                  <strong>Access:</strong> You have the right to access your
                  personal information that we hold.
                </li>
                <li>
                  <strong>Correction:</strong> You have the right to correct any
                  inaccurate or incomplete information about you.
                </li>
                <li>
                  <strong>Deletion:</strong> You have the right to request that
                  we delete your personal information.
                </li>
                <li>
                  <strong>Restriction:</strong> You have the right to restrict
                  the processing of your personal information.
                </li>
                <li>
                  <strong>Portability:</strong> You have the right to receive
                  your personal information in a portable format.
                </li>
              </ul>
              <p>
                To exercise any of these rights, please{" "}
                <Link to="/contactUs">contact us</Link>.
              </p>
              <h5>Data Security:</h5>
              <p>
                We take the security of your personal information seriously and
                use appropriate technical and organizational measures to protect
                it from unauthorized access, disclosure, alteration, or
                destruction. However, no method of transmission over the
                internet or method of electronic storage is 100% secure.
                Therefore, we cannot guarantee the absolute security of your
                information.
              </p>
              <h5>Cookies and Tracking:</h5>
              <p>
                We may use cookies and similar tracking technologies to collect
                information about your use of the Platform. This information may
                include your IP address, browser type, operating system,
                referring/exit pages, and date/time stamps. We use this
                information to analyze Platform usage and improve user
                experience. You can disable cookies in your browser settings,
                but this may affect your ability to use all of the Platform's
                features.
              </p>
              <h5>Children's Privacy:</h5>
              <p>
                The Platform is not intended for children under the age of 13.
                We do not knowingly collect personal information from children
                under the age of 13. If you are a parent or guardian and you are
                aware that your child has provided us with personal information,
                please contact us.
              </p>
              <h5>Changes to this Policy:</h5>
              <p>
                We may update this Policy from time to time. We will notify you
                of any changes by posting the new Policy on the Platform. You
                are advised to review this Policy periodically for any changes.
              </p>
              <h5>Contact Us:</h5>
              <p>
                If you have any questions about this Policy, please{" "}
                <Link to="/contactUs">contact us</Link>.
              </p>
              <h5>Effective Date: March, 2024</h5>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Privacy;
