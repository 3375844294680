import authHub from "./authHub";

export async function addChatMessage(msgData) {
  return await authHub.post("/chatMsg", msgData);
}

export async function getChatSenders() {
  return await authHub.get("/chatSenders");
}

export async function getChatSendersReceivers() {
  return await authHub.get("/chatSendersReceivers");
}

export async function getChatMessages() {
  return await authHub.get("/chatMessages");
}

export async function getChatConversations(reqKey) {
  // console.log(reqKey);
  return await authHub.get("/chatConversations", { params: reqKey });
}
