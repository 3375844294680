import Layout from "../components/layout/layout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import QRCodeGenerator from "../components/utils/qrCodeGenerator";
import EventHeaderPrint from "../components/dashboard/event/eventHeaderPrint";
import EventProgramPrint from "../components/dashboard/event/eventProgramPrint";
import EventDescriptionPrint from "../components/dashboard/event/eventDescriptionPrint";
import { getEventById } from "../api/authApis";

const EventFlyer = () => {
  const { eventId } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [event, setEvent] = useState(null);
  const [rowNotFound, setRowNotFound] = useState(false);

  const url = `${process.env.REACT_APP_EVENT_URL}${eventId}`;

  const printDocument = async () => {
    try {
      const input = document.getElementById("divToPrint");
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL("image/png");
      const img = new Image();
      img.onload = function () {
        var doc = new jsPDF("p", "mm", "a4");
        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();
        let imgWidth = this.width;
        let imgHeight = this.height;
        const ratio = imgWidth / imgHeight;

        if (ratio > width / height) {
          imgWidth = width;
          imgHeight = imgWidth / ratio;
        } else {
          imgHeight = height;
          imgWidth = imgHeight * ratio;
        }
        const pageHeight = 297; // Height of an A4 paper in mm
        if (imgHeight > pageHeight) {
          imgHeight = pageHeight;
        }
        doc.addImage(imgData, "JPEG", 0, 0, 210, imgHeight);
        doc.addImage(imgData, "JPEG", 0, 0, 210, imgHeight);
        doc.save("event_flyer.pdf");
      };
      img.src = imgData;
    } catch (error) {
      console.error("Error printing document:", error);
    }
  };

  const fetchEventDetails = async () => {
    try {
      const { data } = await getEventById(eventId);
      // console.log(data);
      if (data.rowCount === 0) {
        setRowNotFound(true);
      } else {
        setEvent(data.event);
      }
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchEventDetails().finally(() => setLoading(false));
  }, [eventId]);

  // Display loading state
  if (loading) {
    return (
      <Layout>
        <div>Loading...</div>
      </Layout>
    );
  }

  // Display error state
  if (error) {
    return (
      <Layout>
        <div>Oops Something went wrong! We're on it.</div>
      </Layout>
    );
  }
  // Display message if event is not found
  if (rowNotFound) {
    return (
      <Layout>
        <div>
          Oops! Something is not right. We cannot find the event. We are on it
        </div>
      </Layout>
    );
  }

  return (
    <div className="container row">
      <div className="col-lg-2"></div>
      <div className="col-lg-8">
        <div id="divToPrint">
          <div className="row">
            <div className="col-md-12">
              {event && <EventHeaderPrint item={event} />}
            </div>
            <div className="row mx-1 justify-content-center align-items-end">
              <div className="col-md-12 my-3">
                {event && (
                  <EventDescriptionPrint description={event.description} />
                )}
              </div>
            </div>
            <div className="row mx-1 rounded app-bg-skyblue justify-content-center align-items-center">
              <div className="col-md-9 my-3">
                {event && <EventProgramPrint eventId={eventId} />}
              </div>
              <div className="col-md-3 my-3">
                {event && <QRCodeGenerator url={url} />}
              </div>
            </div>
          </div>
          <div className="footer my-3">
            <div className="row mx-1 justify-content-center">
              <div className="col-md-12 mb-2">
                <span className="app-fs-12">{event?.contact_details}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex action-buttons mb-2">
          <button
            type="button"
            className="btn app-btn-primary me-3"
            onClick={printDocument}
          >
            Print to PDF
          </button>
        </div>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
};

export default EventFlyer;
