import { formatDate, formatTime } from "../../helpers/formatDateTime";
import { useMemo, useRef, useEffect } from "react";

const EventHeaderPrint = ({ item }) => {
  const {
    id,
    pic_folder,
    pic_filename,
    category_name,
    from_date,
    to_date,
    from_time,
    to_time,
    event_name,
    event_type,
    pic_caption,
    organized_by,
    location,
    address_line1,
    address_line2,
    city,
    state,
    zip_code,
    event_details,
  } = item;

  const linkToCopy = `${process.env.REACT_APP_EVENT_URL}${id}`;
  const buttonRef = useRef();
  const { meeting_id, meeting_passcode, meeting_link } = event_details || {};

  // console.log(pic_folder + "/" + pic_filename);
  const imagePath = `../${
    pic_folder
      ? `${pic_folder}/${pic_filename}`
      : `assets/images/events/defaults/Default-${category_name}.jpg`
  }`;
  // console.log(imagePath);

  const displayDate = useMemo(() => {
    return from_date === to_date
      ? formatDate(from_date)
      : `${formatDate(from_date)} - ${formatDate(to_date)}`;
  }, [from_date, to_date]);

  // function displayDate() {
  //   return from_date === to_date
  //     ? formatDate(from_date)
  //     : formatDate(from_date) + " - " + formatDate(to_date);
  // }

  const displayTime = useMemo(
    () => `${formatTime(from_time)} - ${formatTime(to_time)}`,
    [from_time, to_time]
  );

  // function displayTime() {
  //   return formatTime(from_time) + " - " + formatTime(to_time);
  // }

  return (
    <div>
      {item && (
        <div>
          <div className="row">
            <div className="col-lg-12">
              {/* <!-- Card item START --> */}
              <div className="card">
                {/* <img className="overflow-hidden h-400px rounded-circle" src={eventDefaultImage} alt="" /> */}
                <img className="overflow-hidden h-450px" src={imagePath} />
                {/* <!-- Background dark overlay --> */}
                <div className="bg-overlay bg-dark opacity-6"></div>

                {/* <!-- Card image overlay --> */}
                <div className="card-img-overlay d-flex align-items-start flex-column">
                  {/* <!-- Card overlay Top --> */}
                  <div className="w-100 mb-auto d-flex justify-content-end">
                    {/* <button
                      ref={buttonRef}
                      className="btn btn-sm app-btn-white mb-0"
                    >
                      <i className="bi bi-share"></i> Share
                    </button> */}
                    {/* <button className="btn btn-sm btn-white mb-0">
                    <i className="bi bi-share"></i> Share
                  </button> */}
                  </div>
                  {/* <!-- Card overlay bottom --> */}
                  <div className="w-100 mt-auto">
                    <div className="row p-0 p-sm-3">
                      <div className="col-11 col-lg-11">
                        {/* <!-- Title --> */}
                        <h1 className="text-white">{event_name}</h1>
                        <p className="text-white mb-0">{pic_caption}</p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Organization and time --> */}
                  <div className="bg-light rounded-3 p-3 mb-2 custom-width">
                    <div className="row g-4">
                      {/* <!-- Organization --> */}
                      <div className="col-12 col-md">
                        <h6 className="font-responsive fw-bold">
                          Organized by:
                        </h6>
                        <div className="d-flex align-items-center">
                          {/* <!-- Info --> */}
                          <h6 className="mb-0 font-responsive">
                            {organized_by}
                          </h6>
                        </div>
                      </div>

                      {/* <!-- Location --> */}
                      <div className="col-12 col-md">
                        <h6 className="font-responsive fw-bold">Location:</h6>
                        <div className="d-flex align-items-center">
                          <h6 className="mb-0 font-responsive">{location}</h6>
                        </div>
                      </div>

                      {/* <!-- Date & Time --> */}
                      <div className="col-12 col-md">
                        <h6 className="font-responsive fw-bold">
                          Date &amp; Time:
                        </h6>
                        <div className="d-flex align-items-center">
                          <div>
                            <h6 className="fw-normal font-responsive mb-0">
                              {displayDate}
                            </h6>
                            <small className="font-responsive">
                              {displayTime}
                            </small>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Address --> */}
                      {event_type === "In person" && (
                        <div className="col-12 col-md">
                          <h6 className="font-responsive fw-bold">Address:</h6>
                          <div className="d-flex align-items-center">
                            <div>
                              <h6 className="fw-normal font-responsive mb-0">
                                {address_line1} {address_line2}
                              </h6>
                              <h6 className="fw-normal font-responsive mb-0">
                                {" "}
                                {city}, {state} - {zip_code}
                              </h6>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Card item END --> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventHeaderPrint;
