import "../../assets/styles/login.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyOTPCode } from "../../api/authApis";
import { setActiveComponentsLogin } from "../../redux/slices/authSlice";

const RecoverPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.email);
  const activeComponentsLogin = useSelector(
    (state) => state.auth.activeComponentsLogin
  );

  // Make all the components active = false
  let activeComponentsLoginNew = Object.assign(
    {},
    ...Object.entries(activeComponentsLogin).map(([k, v]) => ({ [k]: false }))
  );

  const recoverPassword = () => {
    dispatch(
      setActiveComponentsLogin({
        ...activeComponentsLoginNew,
        passwordOTP: true,
      })
    );
  };

  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [values, setValues] = useState({
    code: "",
    step: "recoverPassword",
  });

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(values);
    try {
      const { data, status } = await verifyOTPCode(values);
      // console.log(status)
      if (status === 204) {
        setStatus("");
        dispatch(
          setActiveComponentsLogin({
            ...activeComponentsLoginNew,
            resetPassword: true,
          })
        );
      }
    } catch (error) {
      if (error.response.data.message === "Invalid OTP Code") {
        setStatus("Invalid OTP Code");
      }
      if (error.response.data.error === "Unauthorized") {
        setStatus("Unauthorized");
      }
      setError(error.response.data.error);
      console.log(error.response);
    }
  };

  if (error) {
    return <h1>Oops Something went wrong!!</h1>;
  }

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <div className="title text-center my-2">
          <h6>Enter the verification code we sent to</h6>
          <h6>{email}</h6>
        </div>
        <form onSubmit={handleSubmit} className="text-center">
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control form-input"
              onChange={(e) => onChange(e)}
              id="code"
              name="code"
              value={values.code}
              placeholder="OTP Code"
              required
            />
          </div>
          <div className="mb-2 action-buttons">
            <div className="app-fs-8" style={{ color: "red" }}>
              {status}
            </div>
            <button type="submit" className="btn app-btn-primary w-100">
              Recover
            </button>
          </div>
          <div className="mb-2">
            <span className="app-fs-9">
              Did not receive OTP? <Link onClick={recoverPassword}>Resend</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};
export default RecoverPassword;
