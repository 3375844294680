import React, { useState, useEffect } from "react";
import { getEventRSVPList } from "../../../api/authApis";
import { exportToCSV } from "../../utils/exportToCSV";
import { exportToPDF } from "../../utils/exportToPDF";

const RSVPList = ({ eventId }) => {
  const [rsvpList, setRsvpList] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [columns, setColumns] = useState([
    "first_name",
    "last_name",
    "email",
    "adult_count",
    "children_count",
  ]);
  const [headers, setHeaders] = useState([
    "First Name",
    "Last Name",
    "Email",
    "Adult Count",
    "Children Count",
  ]);

  // Function to fetch RSVP'd users from an API or database
  const fetchRSVPList = async () => {
    setError(null);
    setMessage(null);
    if (!eventId) {
      setRsvpList([]);
      setMessage("Please select an event to view RSVPs");
      return;
    }
    try {
      const { data } = await getEventRSVPList(eventId);
      if (data.rowCount === 0 || !data.rsvpList) {
        setRsvpList([]);
        setMessage("No RSVPs found for this event");
        return;
      }
      // console.log(data.rsvpList);
      setRsvpList(data.rsvpList);
    } catch (error) {
      console.log(error.message);
      setError(error);
    }
  };

  // Fetch users when the component mounts
  useEffect(() => {
    fetchRSVPList();
  }, [eventId]);

  return (
    <div className="card">
      <div className="card-header d-flex">
        RSVP Report
        <div className="ms-auto">
          <i
            className="fa fa-file-pdf fa-lg app-icon-style me-3"
            title="Export List to pdf"
            aria-hidden="true"
            onClick={() =>
              exportToPDF("RSVP List", columns, headers, rsvpList, "RSVP_List")
            }
          ></i>
          <i
            className="fa fa-file-csv fa-lg app-icon-style"
            title="Export List to csv"
            onClick={() => exportToCSV(rsvpList, columns, headers, "RSVP_List")}
            aria-hidden="true"
          ></i>
        </div>
      </div>
      <div className="card-body">
        <div>
          {rsvpList && rsvpList.length > 0 ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" className="row-text-sm">
                    Name
                  </th>
                  <th scope="col" className="row-text-sm">
                    Email
                  </th>
                  <th scope="col" className="row-text-sm">
                    # Adults
                  </th>
                  <th scope="col" className="row-text-sm">
                    # Children
                  </th>
                </tr>
              </thead>
              <tbody>
                {rsvpList.map((item, index) => (
                  <tr key={index} className="app-fs-8">
                    <td>{item.first_name + " " + item.last_name}</td>
                    <td>{item.email}</td>
                    <td>{item.adult_count}</td>
                    <td>{item.children_count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>{message}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RSVPList;
