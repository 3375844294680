import {
  getSponsorshipReqById,
  getSponsorshipRequests,
  addSponsorshipRequest,
  updateSponsorshipRequest,
  deleteSponsorshipReq,
} from "../../api/authApis";
import { useState, useEffect } from "react";
import { BsTrash, BsPencil } from "react-icons/bs";

const SponsorshipRequestForm = ({
  handleSubmit,
  onChange,
  values,
  error,
  success,
  updateItem,
  handleCancelUpdate,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <h6 className="card-title">Request Sponsorship</h6>
        <form onSubmit={handleSubmit}>
          <div className="row form-wrapper-gen g-2">
            <div className="col-md-6">
              <div className="form-group mb-1">
                <label
                  htmlFor="sponsorshipLevel"
                  className="col-form-label fl-fs-8"
                >
                  Sponsorship Level
                </label>
                <input
                  type="text"
                  onChange={(e) => onChange(e)}
                  value={values.sponsorshipLevel}
                  className="form-control form-input fc-fs-7 hide-placeholder"
                  id="sponsorshipLevel"
                  name="sponsorshipLevel"
                  list="popularLevels"
                  placeholder="eg: Gold"
                  required
                />
                <datalist id="popularLevels">
                  <option value="Silver"></option>
                  <option value="Gold"></option>
                  <option value="Platinum"></option>
                </datalist>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-1">
                <label htmlFor="amount" className="col-form-label fl-fs-8">
                  Amount
                </label>
                <input
                  type="number"
                  onChange={(e) => onChange(e)}
                  value={values.amount}
                  className="form-control form-input fc-fs-7 hide-placeholder"
                  id="amount"
                  name="amount"
                  placeholder="101"
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-1">
                <label htmlFor="benefits" className="col-form-label fl-fs-8">
                  Benefits of the Sponsorship Level
                </label>
                <textarea
                  onChange={(e) => onChange(e)}
                  value={values.benefits}
                  id="benefits"
                  name="benefits"
                  rows="2"
                  className="form-control form-input fc-fs-7 hide-placeholder"
                  placeholder="eg: You'll have priority access to all future events"
                ></textarea>
              </div>
            </div>
            <div style={{ color: "red", fontSize: "0.8rem" }}>{error}</div>
            <div style={{ color: "green", fontSize: "0.8rem" }}>{success}</div>
            <div className="col-md-12 d-flex action-buttons">
              <button type="submit" className="btn btn-responsive">
                {updateItem ? "Update" : "Create"}
              </button>
              <button
                type="button"
                className="btn btn-responsive mx-2"
                hidden={!updateItem}
                onClick={handleCancelUpdate}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const SponsorshipLevelsTable = ({
  sponsorshipReqs,
  removeSponsorshipReq,
  loadSponsorshipReq,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <h6 className="card-title">Sponsorship Levels</h6>
        <div>
          <table className="table table-borderless table-hover mt-2">
            <thead>
              <tr>
                <th scope="col" className="row-text-sm">
                  Level
                </th>
                <th scope="col" className="row-text-sm">
                  Amount
                </th>
                <th scope="col" className="row-text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {sponsorshipReqs &&
                sponsorshipReqs.map((req) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={req.id}
                    className="table-row"
                  >
                    <th scope="row" className="align-middle row-text-sm">
                      {req.sponsorship_level}
                    </th>
                    <td className="align-middle row-text-sm">${req.amount}</td>
                    <td>
                      <button
                        className="del-icon me-2"
                        onClick={() => removeSponsorshipReq(req.id)}
                      >
                        <BsTrash />
                      </button>
                      <button
                        className="del-icon"
                        onClick={() => loadSponsorshipReq(req.id)}
                      >
                        <BsPencil />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const SponsorshipReq = ({ item }) => {
  const eventId = item.id;
  const [error, setError] = useState("");
  const [sponsorshipReqs, setSponsorshipReqs] = useState([]);
  const [success, setSuccess] = useState("");
  const [updateItem, setUpdateItem] = useState(false);
  const initializeForm = {
    id: "",
    eventId: eventId,
    sponsorshipLevel: "",
    amount: "",
    benefits: "",
  };

  const [values, setValues] = useState(initializeForm);

  // Add the new request to the array to be displayed
  const addSponsorshipReq = (sponsorshipReq) => {
    if (sponsorshipReqs.indexOf(sponsorshipReq) === -1) {
      setSponsorshipReqs([...sponsorshipReqs, sponsorshipReq]);
    }
  };

  // Update the  request to the array to be displayed
  const updateSponsorshipReq = (sponsorshipReq) => {
    // console.log(sponsorshipReq)
    const updatedItems = sponsorshipReqs.map((item) => {
      if (item.id === sponsorshipReq.id) {
        return {
          ...item,
          sponsorship_level: sponsorshipReq.sponsorship_level,
          amount: sponsorshipReq.amount,
          benefits: sponsorshipReq.benefits,
        };
      }
      return item;
    });
    setSponsorshipReqs(updatedItems);
  };

  // Load the selected request from the database
  const loadSponsorshipReq = async (id) => {
    // console.log(id)
    try {
      let reqkey = {
        id: id,
      };
      const response = await getSponsorshipReqById(reqkey);
      // console.log(response.data.signupReq)
      const sponsorshipReq = response.data.sponsorshipReq;
      setValues({
        id: sponsorshipReq.id,
        eventId: sponsorshipReq.event_id,
        sponsorshipLevel: sponsorshipReq.sponsorship_level,
        amount: sponsorshipReq.amount,
        benefits: sponsorshipReq.benefits,
      });
      setUpdateItem(true);
      // console.log(values)
    } catch (error) {
      console.log(error);
    }
  };

  // Delete a request from the database
  const removeSponsorshipReq = async (id) => {
    try {
      const response = await deleteSponsorshipReq(id);
      // console.log(response);
      setValues(initializeForm);
      setUpdateItem(false);
      setSponsorshipReqs(
        sponsorshipReqs.filter((req) => {
          return req.id !== id;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Cancel Update Item
  const handleCancelUpdate = () => {
    setUpdateItem(false);
    setValues(initializeForm);
  };

  // Get all the supplies request for the event
  const fetchData = async (isMounted) => {
    let reqkey = {
      eventId: eventId,
    };
    try {
      const { data } = await getSponsorshipRequests(reqkey);
      // console.log(data.sponsorshipReqs)
      if (isMounted) {
        setSponsorshipReqs(data.sponsorshipReqs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    fetchData(isMounted);
    return () => (isMounted = false);
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    values.eventId = eventId;
    try {
      if (values.id > 0) {
        const { data } = await updateSponsorshipRequest(values);
        // console.log(data.sponsorshipReq)
        updateSponsorshipReq(data.sponsorshipReq);
        // setSuccess(data.message)
        setUpdateItem(false);
      } else {
        const { data } = await addSponsorshipRequest(values);
        addSponsorshipReq(data.sponsorshipReq);
        setSuccess(data.message);
      }
      setValues(initializeForm);
      setError("");
    } catch (error) {
      console.log(error);
      setSuccess("");
      setError(
        "Oops Something went wrong! Signup Request could not be created."
      );
    }
  };

  return (
    <div className="row mt-3">
      <div className="col-lg-7">
        {/* <!-- Left column content --> */}
        <SponsorshipRequestForm
          handleSubmit={handleSubmit}
          onChange={onChange}
          values={values}
          error={error}
          success={success}
          updateItem={updateItem}
          handleCancelUpdate={handleCancelUpdate}
        />
      </div>
      <div className="col-lg-5">
        {/* <!-- Right column content --> */}
        <SponsorshipLevelsTable
          sponsorshipReqs={sponsorshipReqs}
          removeSponsorshipReq={removeSponsorshipReq}
          loadSponsorshipReq={loadSponsorshipReq}
        />
      </div>
    </div>
  );
};

export default SponsorshipReq;
