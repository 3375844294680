import { useDispatch, useSelector } from "react-redux";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../redux/slices/dashboardSlice";
import EventListCard from "./eventListCard";

const WeekEvents = ({ events, error }) => {
  // const [error, setError] = useState(null)
  // console.log(events)
  const dispatch = useDispatch();
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  // Make all the components active = false
  // let activeComponentsDashboardNew = Object.assign({},
  //   ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({ [k]: false }))
  // );
  const activeComponentsDashboardNew = Object.keys(
    activeComponentsDashboard
  ).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});

  const handleActionClick = (item, component) => {
    dispatch(setItem(item));
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        [component]: true,
      })
    );
  };

  // Display the error message if there is an error
  if (error) {
    return (
      <div className="row g-4">
        <div className="col-sm-12 col-xl-12">
          <h6 className="text-danger">
            Oops! Something went wrong. We are working on fixing this!
          </h6>
        </div>
      </div>
    );
  }

  // Display the  message if there is are no events
  if (events.length === 0) {
    return (
      <div className="row g-4">
        <div className="col-sm-12 col-xl-12">
          <div className="card border-0 mb-3 d-sm-flex p-3">
            <div className="card-subheader">No Events Found</div>
          </div>
        </div>
      </div>
    );
  }

  // console.log(events)
  return (
    <div className="row g-4">
      <div className="col-sm-12 col-xl-12">
        {/* <!-- Event item START --> */}
        <div className="card border-0 mb-3 d-sm-flex p-3">
          {events &&
            events.map((event) => {
              return (
                <EventListCard
                  key={event.id}
                  event={event}
                  handleActionClick={handleActionClick}
                  eventTab="week"
                />
              );
            })}
        </div>
        {/* <!-- Event item END --> */}
      </div>
    </div>
  );
};
export default WeekEvents;
