import { BsGeoAltFill, BsCalendarFill } from "react-icons/bs";
import DOMPurify from "dompurify";
import { decode } from "html-entities";
import { formatDate, formatTime } from "../../helpers/formatDateTime";

const EventCreateStep5 = ({
  values,
  handleSubmit,
  handlePrevClick,
  handleDraftClick,
  error,
}) => {
  const {
    from_date,
    to_date,
    from_time,
    to_time,
    event_name,
    pic_filename,
    pic_caption,
    organizer_initials,
    organized_by,
    location,
    description,
    imageUrl,
  } = values;

  const displayDate = () => {
    return from_date === to_date
      ? formatDate(from_date)
      : formatDate(from_date) + " - " + formatDate(to_date);
  };

  const displayTime = () => {
    return formatTime(from_time) + " - " + formatTime(to_time);
  };

  let defaultImageMsg = "";
  if (pic_filename === "" || pic_filename.includes("Default-")) {
    defaultImageMsg =
      "Default image is used for this preview. Please upload your own event image";
  }

  return (
    <div className="container">
      {values && (
        <section className="pt-0">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <img
                  id="eventImg"
                  className="overflow-hidden h-400px"
                  src={imageUrl}
                  alt=""
                />
                <div className="bg-overlay bg-dark opacity-6"></div>
                <div className="card-img-overlay d-flex align-items-start flex-column">
                  <div className="w-100 mb-auto d-flex justify-content-end"></div>
                  <div className="w-100 mt-auto">
                    <div className="row p-0 p-sm-3">
                      <div className="col-11 col-lg-7">
                        <h1 className="text-white">{event_name}</h1>
                        <p className="text-white mb-0">{pic_caption}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-danger">{defaultImageMsg}</div>
            </div>
          </div>
        </section>
      )}
      {values && (
        <form onSubmit={handleSubmit}>
          <section className="pt-0 pb-4">
            <div className="row g-4 g-lg-5">
              <div className="col-lg-12">
                <div className="bg-light rounded-3 p-3 mb-4">
                  <div className="row g-4">
                    <div className="col-md-4">
                      <h6 className="small">Organized by:</h6>
                      <div className="d-flex align-items-center">
                        <div className="avatar align-middle">
                          <div className="avatar-img rounded-1 bg-danger">
                            <span className="text-white position-absolute top-50 start-50 translate-middle fw-bold">
                              {organizer_initials}
                            </span>
                          </div>
                        </div>
                        <div className="ms-2">
                          <h6 className="mb-0">{organized_by}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h6 className="small">Location:</h6>
                      <div className="d-flex align-items-center">
                        <div className="avatar align-middle flex-shrink-0">
                          <div className="avatar-img rounded-2 bg-dark">
                            <span className="position-absolute top-50 start-50 translate-middle text-white">
                              <BsGeoAltFill />
                            </span>
                          </div>
                        </div>
                        <h6 className="fw-normal mb-0 ms-2">{location}</h6>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h6 className="small">Date &amp; Time:</h6>
                      <div className="d-flex align-items-center">
                        <div className="avatar align-middle flex-shrink-0">
                          <div className="avatar-img rounded-2 bg-dark">
                            <span className="position-absolute top-50 start-50 translate-middle text-white">
                              <BsCalendarFill />
                            </span>
                          </div>
                        </div>
                        <div className="ms-2">
                          <h6 className="fw-normal mb-0">{displayDate()}</h6>
                          <small>{displayTime()}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h5>About this event</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decode(DOMPurify.sanitize(description)),
                  }}
                />
              </div>
            </div>
            <div className="app-text-error-xs">{error}</div>
            <div className="col-md-12 d-flex pb-2 align-items-center action-buttons mt-3">
              <button
                type="button"
                onClick={handlePrevClick}
                className="btn btn-responsive me-3"
              >
                Prev
              </button>
              <button
                type="button"
                onClick={handleDraftClick}
                className="btn btn-responsive me-3"
              >
                Save as Draft
              </button>
              <button type="submit" className="btn btn-responsive">
                Publish
              </button>
            </div>
          </section>
        </form>
      )}
    </div>
  );
};

export default EventCreateStep5;
