const EventCategorySelector = ({ values, onChange, eventCategories }) => (
  <div className="col-sm-6 col-lg-3">
    <label htmlFor="state" className="form-label">
      Category
    </label>
    <select
      onChange={(e) => onChange(e)}
      value={values.category_id}
      className="form-select form-input fc-fs-8"
      id="category_id"
      name="category_id"
      aria-label="Default select"
      required
    >
      <option value="0">All</option>
      {eventCategories &&
        eventCategories.map((category) => {
          return (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          );
        })}
    </select>
  </div>
);

const EventStateSelector = ({ values, onChange, states }) => (
  <div className="col-sm-6 col-lg-3">
    <label htmlFor="state" className="form-label">
      Where
    </label>
    <select
      onChange={(e) => onChange(e)}
      value={values.state}
      className="form-select form-input fc-fs-8"
      id="state"
      name="state"
      aria-label="Default select"
      required
    >
      <option value="All">All</option>
      {states &&
        states.map((state) => {
          return (
            <option key={state.id} value={state.state_abbrev}>
              {state.state_name}
            </option>
          );
        })}
    </select>
  </div>
);

const EventPeriodSelector = ({ values, onChange }) => (
  <div className="col-sm-6 col-lg-3">
    <label htmlFor="period" className="form-label">
      When
    </label>
    <select
      onChange={(e) => onChange(e)}
      value={values.period}
      id="period"
      name="period"
      className="form-select form-input app-fs-8"
      aria-label="Default select"
      required
    >
      <option value="All">All Upcoming</option>
      <option value="Week">This Week</option>
      <option value="Month">This Month</option>
      <option value="Next Month">Next Month</option>
    </select>
  </div>
);

const EventSearch = ({
  values,
  onChange,
  handleSubmit,
  eventCategories,
  states,
}) => {
  return (
    <section className="pt-5 pb-0 position-relative dashboard-background">
      <div className="bg-overlay bg-dark opacity-8"></div>
      {/* <!-- Container START --> */}
      <div className="container">
        <div className="py-5">
          <div className="row position-relative">
            <div className="col-lg-9 mx-auto">
              <div className="text-center">
                {/* <!-- Title --> */}
                <h1 className="text-white">Find events near you</h1>
                <p className="text-white">
                  Let's uncover the next unforgettable experience near you!
                </p>
              </div>
              <div className="mx-auto bg-mode shadow p-4 mt-5">
                {/* <!-- Form START --> */}
                <form onSubmit={handleSubmit}>
                  {/* <!-- Duration --> */}
                  <div className="row form-wrapper-gen align-items-end g-4">
                    {/* <!-- Category --> */}
                    <EventCategorySelector
                      values={values}
                      onChange={onChange}
                      eventCategories={eventCategories}
                    />
                    {/* <!-- Where - State List --> */}
                    <EventStateSelector
                      values={values}
                      onChange={onChange}
                      states={states}
                    />
                    {/* <!-- Time --> */}
                    <EventPeriodSelector values={values} onChange={onChange} />
                    {/* <!-- Time --> */}
                    <div className="col-sm-6 col-lg-3">
                      <button className="btn btn-responsive w-100">
                        Search
                      </button>
                    </div>
                  </div>
                </form>
                {/* <!-- Form END --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSearch;
