import { Navigate, Outlet, useLocation } from "react-router-dom";
import { fetchProtectedInfo } from "../../api/authApis";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthenticated,
  setEmail,
  setFirstName,
  setUsername,
} from "../../redux/slices/authSlice";
import { reset as resetChatHome } from "../../redux/slices/chatSlice";
import { reset as resetDashboard } from "../../redux/slices/dashboardSlice";
import { reset as resetEventsTab } from "../../redux/slices/eventsTabSlice";

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { email, username, firstName, isAuthenticated } = auth;

  // console.log("PrivateRoutes", email, username, firstName);

  // console.log("location", location);

  const checkTokenStatus = async () => {
    try {
      const response = await fetchProtectedInfo();

      if (response.status === 200) {
        if (!email || email === "" || email === "undefined") {
          dispatch(setEmail(response.data.userEmail));
        }
        if (!username || username === "" || username === "undefined") {
          dispatch(setUsername(response.data.username));
        }
        if (!firstName || firstName === "" || firstName === "undefined") {
          dispatch(setFirstName(response.data.firstName));
        }
      }
    } catch (error) {
      dispatch(resetChatHome());
      // dispatch(resetDashboard());
      dispatch(resetEventsTab());
      dispatch(setAuthenticated(false));
      localStorage.removeItem("isAuth");
      // console.log(error.message)
    }
  };

  useEffect(() => {
    checkTokenStatus();
  }, [email]);

  // console.log('PrivateRoutes: ' + auth.isAuthenticated)
  return <>{isAuthenticated ? <Outlet /> : <Navigate to="/login" />} </>;
};

export default PrivateRoutes;
