import { useSelector } from "react-redux";
import Profile from "../profile/profile";
import UpdatePersonal from "../profile/updatePersonal";
import UpdateAddress from "../profile/updateAddress";
import UpdateAvatar from "../profile/updateAvatar";
import EventEdit from "./event/eventEdit";
import EventList from "../event/eventList";
import EventDetails from "../dashboard/event/eventDetails";
import EventSignupReq from "../event/eventSignupReq";
import EventSignup from "../dashboard/event/eventSignup";
import EventPrint from "../dashboard/event/eventPrint";
import UpdatePassword from "../profile/updatePassword";
import MySignups from "./signups/mySignups";
import EventSignups from "./signups/eventSignups";
import EventProgramAdd from "./event/eventProgramAdd";
import EventRSVPList from "./event/eventRSVPList";
import MySponsorships from "./signups/mySponsorships";
import EventDrafts from "./event/eventDrafts";
import EventCreate from "./event/eventCreate";
import ReportsMain from "./reports/reportsMain";

const DashboardComponents = () => {
  const item = useSelector((state) => state.dashboard.item);
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const {
    eventDetail,
    eventList,
    eventDrafts,
    eventCreate,
    eventEdit,
    eventPrint,
    requestSignup,
    signup,
    program,
    profile,
    updatePersonal,
    updateAddress,
    updateAvatar,
    updatePassword,
    mySignups,
    mySponsorships,
    eventSignups,
    rsvpList,
    reportsMain,
  } = activeComponentsDashboard;

  // console.log(activeComponentsDashboard)
  return (
    <div>
      {/* <!-- Card START --> */}
      {eventList && <EventList />}
      {eventDrafts && <EventDrafts />}
      {eventDetail && item && <EventDetails item={item} />}
      {requestSignup && item && <EventSignupReq item={item} />}
      {program && item && <EventProgramAdd item={item} />}
      {profile && <Profile />}
      {updatePersonal && <UpdatePersonal />}
      {updateAddress && <UpdateAddress />}
      {updateAvatar && <UpdateAvatar />}
      {updatePassword && <UpdatePassword />}
      {mySignups && <MySignups />}
      {mySponsorships && <MySponsorships />}
      {eventSignups && <EventSignups />}
      {/* {chatMessages && <ChatMessages />} */}
      {/* {chatMessages && <ChatHome />} */}
      {rsvpList && <EventRSVPList />}
      {eventCreate && <EventCreate />}
      {eventEdit && <EventEdit />}
      {signup && <EventSignup />}
      {eventPrint && <EventPrint />}
      {reportsMain && <ReportsMain />}
      {/* <!-- Card END --> */}
    </div>
  );
};

export default DashboardComponents;
