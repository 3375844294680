import React, { useState, useEffect } from "react";
import { getEventSponsors } from "../../../api/signupApis";
import { exportToCSV } from "../../utils/exportToCSV";
import { exportToPDF } from "../../utils/exportToPDF";

const SponsorshipList = ({ eventId }) => {
  const [eventSponsors, setEventSponsors] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [columns, setColumns] = useState([
    "first_name",
    "last_name",
    "email",
    "sponsorship_level",
    "sponsorship_amt",
  ]);
  const [headers, setHeaders] = useState([
    "First Name",
    "Last Name",
    "Email",
    "Sponsorship Level",
    "Sponsorship Amt",
  ]);

  // Function to fetch Signups from an API or database
  const fetchEventSponsors = async () => {
    let reqkey = {
      eventId: eventId,
    };
    setError(null);
    setMessage(null);
    if (!eventId) {
      setEventSponsors([]);
      setMessage("Please select an event to view Sponsorships");
      return;
    }
    try {
      const { data } = await getEventSponsors(reqkey);
      // console.log(data.eventSponsors);
      if (data.rowCount === 0 || !data.eventSponsors) {
        setEventSponsors([]);
        setMessage("No Sponsorships found for this event");
        return;
      }
      // console.log(data.eventSponsors);
      setEventSponsors(data.eventSponsors);
    } catch (error) {
      // console.log(error)
      setError(error);
    }
  };

  // Fetch users when the component mounts
  useEffect(() => {
    fetchEventSponsors();
  }, [eventId]);

  return (
    <div className="card">
      <div className="card-header d-flex">
        Sponsorship Report
        <div className="ms-auto">
          <i
            className="fa fa-file-pdf fa-lg app-icon-style me-3"
            title="Export List to pdf"
            aria-hidden="true"
            onClick={() =>
              exportToPDF(
                "Sponsorship List",
                columns,
                headers,
                eventSponsors,
                "Sponsorship_List"
              )
            }
          ></i>
          <i
            className="fa fa-file-csv fa-lg app-icon-style"
            title="Export List to csv"
            onClick={() =>
              exportToCSV(eventSponsors, columns, headers, "Sponsorship_List")
            }
            aria-hidden="true"
          ></i>
        </div>
      </div>
      <div className="card-body">
        <div>
          {eventSponsors && eventSponsors.length > 0 ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" className="row-text-sm">
                    Sponsor Name
                  </th>
                  <th scope="col" className="row-text-sm">
                    Email
                  </th>
                  <th scope="col" className="row-text-sm">
                    Sponsorship Level
                  </th>
                  <th scope="col" className="row-text-sm">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {eventSponsors.map((item) => (
                  <tr key={item.sponsorship_id} className="app-fs-8">
                    <td>{item.first_name + " " + item.last_name}</td>
                    <td>{item.email}</td>
                    <td>{item.sponsorship_level}</td>
                    <td>${item.sponsorship_amt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>{message}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SponsorshipList;
