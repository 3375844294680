import { configureStore } from '@reduxjs/toolkit';
// import authReducer from './slices/authSlice';
import rootReducer from './rootReducer';
// The rootReducer is typically used when creating the Redux store. 
// It's passed to the configureStore function to set up the store with all the combined reducers.

const store = configureStore({
  // reducer: {
  //   auth: authReducer,
  // },
  reducer: rootReducer
});

export default store;
