import { Link } from "react-router-dom";
import { formatDate, formatTime } from "../helpers/formatDateTime";
import CopyToClipboardLink from "../utils/copyToClipboardLink";

const EventListCard = ({ event, handleActionClick, eventTab }) => {
  const {
    id,
    event_name,
    from_date,
    to_date,
    from_time,
    to_time,
    pic_caption,
    organized_by,
    location,
    pic_folder,
    pic_filename,
    category_name,
    is_archived,
    has_started,
    is_private,
    status,
  } = event;

  return (
    <div key={id} className="row border-0 shadow p-1 mb-1">
      <div className="col-md-4">
        <div className="position-relative">
          <img
            src={
              pic_folder
                ? `${pic_folder}/${pic_filename}`
                : `../assets/images/events/defaults/Default-${category_name}.jpg`
            }
            alt="Thumbnail"
            className="img-thumbnail"
          />
        </div>
      </div>
      <div className="col-md-8">
        <div className="row mt-1">
          <Link
            className="card-subheader app-link-fs-10"
            onClick={() => handleActionClick(event, "eventDetail")}
          >
            <span>{event_name}</span>
          </Link>
          <div>
            <span className="fw-bold app-fs-9 mr-2">Date & Time: </span>
            <span className="app-fs-9 mr-2">
              {from_date === to_date
                ? formatDate(from_date)
                : formatDate(from_date) + " - " + formatDate(to_date)}
            </span>
            &nbsp;{" "}
            <span className="app-fs-9">
              {formatTime(from_time) + " - " + formatTime(to_time)}
            </span>
          </div>
          <div>
            <span className="fw-bold app-fs-9 mr-2">Organized by: </span>
            <span className="app-fs-9">{organized_by} </span>
          </div>
          <div>
            <span className="fw-bold app-fs-9 mr-2">Location: </span>
            <span className="app-fs-9">{location} </span>
          </div>
          <div>
            <span className="card-text app-fs-9">{pic_caption} </span>
          </div>
          {status === "Cancelled" && (
            <div>
              <span className="app-fs-9 highlight">Cancelled Event</span>
            </div>
          )}
          {eventTab === "myEvents" &&
            !is_archived &&
            is_private &&
            status !== "Cancelled" && (
              <div>
                <CopyToClipboardLink
                  textToCopy={`${process.env.REACT_APP_EVENT_URL}${event.id}`}
                  alertMessage="Event link copied to clipboard! You can now share it with others."
                >
                  <span className="app-fs-9 highlight">
                    Private Event - Click to copy the link to share
                  </span>
                </CopyToClipboardLink>
              </div>
            )}
          {eventTab !== "archivedEvents" && (
            <div className="action-buttons my-2">
              {eventTab === "myEvents" && !is_archived && (
                <button
                  type="button"
                  title="Add Event Schedule"
                  className="btn app-icon-primary-xs me-1 pt-1"
                  onClick={() => handleActionClick(event, "program")}
                >
                  <i className="fa fa-list-alt fa-lg" aria-hidden="true"></i>
                </button>
              )}
              {eventTab === "myEvents" && !is_archived && (
                <button
                  type="button"
                  title="Create Signup Sheet"
                  className="btn app-icon-primary-xs me-1 pt-1"
                  onClick={() => handleActionClick(event, "requestSignup")}
                >
                  <i className="fa fa-user-plus fa-lg" aria-hidden="true"></i>
                </button>
              )}
              {!is_archived && (
                <button
                  type="button"
                  title="Signup to Help"
                  className="btn app-icon-primary-xs me-1 pt-1"
                  onClick={() => handleActionClick(event, "signup")}
                >
                  <i className="fa fa-hand-paper fa-lg" aria-hidden="true"></i>
                </button>
              )}
              {!is_archived && (
                <button
                  type="button"
                  title="Print Event Flyer"
                  className="btn app-icon-primary-xs me-1 pt-1"
                  onClick={() => handleActionClick(event, "eventPrint")}
                >
                  <i className="fa fa-qrcode fa-lg" aria-hidden="true"></i>
                </button>
              )}
              {eventTab === "myEvents" && !is_archived && (
                <button
                  hidden={has_started}
                  type="button"
                  title="Update Event Details"
                  className="btn app-icon-primary-xs me-1 pt-1"
                  onClick={() => handleActionClick(event, "eventEdit")}
                >
                  <i className="fa fa-pencil fa-lg" aria-hidden="true"></i>
                </button>
              )}
              {eventTab === "myEvents" && !is_archived && (
                <button
                  type="button"
                  title="View Signups for the Event"
                  className="btn app-icon-primary-xs me-1 pt-1"
                  onClick={() => handleActionClick(event, "eventSignups")}
                >
                  <i className="fa fa-handshake fa-lg" aria-hidden="true"></i>
                </button>
              )}
              {eventTab === "myEvents" && !is_archived && (
                <button
                  type="button"
                  title="Print RSVP List"
                  className="btn app-icon-primary-xs me-1 pt-1"
                  onClick={() => handleActionClick(event, "rsvpList")}
                >
                  <i className="fa fa-list fa-lg" aria-hidden="true"></i>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventListCard;
