import { useState, useEffect } from "react";
import { getSponsorshipReqs } from "../../../api/authApis";
import { useDispatch, useSelector } from "react-redux";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../../redux/slices/dashboardSlice";

const SponsorshipReqs = ({ event }) => {
  const eventId = event.id;
  const is_over = event.is_over;

  const dispatch = useDispatch();
  const [sponsorshipReqs, setSponsorshipReqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(is_over);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const activeComponentsDashboardNew = Object.keys(
    activeComponentsDashboard
  ).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});

  // Get all the Sponsorship requests for the event
  const fetchSponsorshipReqs = async () => {
    // const eventId = eventId;
    let reqkey = {
      eventId: eventId,
    };
    try {
      const { data } = await getSponsorshipReqs(reqkey);
      setSponsorshipReqs(data.sponsorshipReqs);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSponsorshipReqs().finally(() => setLoading(false));
  }, [eventId]);

  const handleActionClick = (item, component) => {
    dispatch(setItem(item));
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        [component]: true,
      })
    );
  };

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display the error message if there is an error
  if (error) {
    return (
      <div className="row g-4">
        <div className="col-sm-12 col-xl-12">
          <h6 className="text-danger">
            Oops! Something went wrong. We are working on fixing this!
          </h6>
        </div>
      </div>
    );
  }

  if (sponsorshipReqs?.length > 0) {
    return (
      <div className="mt-2">
        <div className="card-header d-flex justify-content-between align-items-center action-buttons">
          Sponsorship Requests
          {!show ? (
            <i
              className="fa fa-hand-paper-o fa-lg app-icon-style"
              title="Click here to Sponsor"
              aria-hidden="true"
              onClick={() => handleActionClick(event, "signup")}
            ></i>
          ) : (
            ""
          )}
        </div>
        <div className="px-1 mt-1">
          {sponsorshipReqs.map((req) => {
            return (
              <div key={req.id} className="row align-items-center mb-1">
                <div className="col">
                  <small>{req.sponsorship_level}</small>
                </div>
                <div className="col">
                  <small>$ {req.amount} </small>
                </div>
                <div className="col">
                  <small> {req.benefits} </small>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default SponsorshipReqs;
