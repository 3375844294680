import { Link, useNavigate } from "react-router-dom";

const ChatUserList = ({ chatUsers, setSelectedUser }) => {
  return (
    <div className="mt-4 h-100">
      <div className="chat-tab-list">
        <ul className="nav flex-column nav-pills nav-pills-soft" role="tablist">
          {chatUsers.map((user, index) => (
            <li key={user.id} data-bs-dismiss="offcanvas">
              <Link
                onClick={() => setSelectedUser(user)}
                to={`#chat-${user.username}`}
                className={`nav-link ${index === 0 ? "active" : ""} text-start`}
                id={`chat-${user.username}-tab`}
                data-bs-toggle="pill"
                role="tab"
                aria-selected={index === 0}
              >
                <div className="d-flex">
                  <div className="flex-shrink-0 avatar me-2">
                    <img
                      className="avatar-img rounded-circle"
                      src={user.profile}
                      alt=""
                    />
                  </div>
                  <div className="flex-grow-1 d-block">
                    <div className="mb-0 mt-1 app-fs-8">{user.username}</div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ChatSidenav = ({ chatUsers, error, setSelectedUser }) => {
  // console.log(chatUsers);
  // Add loading state
  if (!chatUsers) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Display error state
  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Oops! Something went wrong. We are working on fixing this!
      </div>
    );
  }

  return (
    <aside className="sidebar bg-light text-white">
      <div className="sidebar-sticky">
        {/* <!-- Advanced filter responsive toggler START --> */}
        <div className="d-flex align-items-center d-lg-none">
          <button
            className="border-0 bg-transparent"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasSideNavbar"
            aria-controls="offcanvasSideNavbar"
          >
            <span className="btn btn-primary">
              <i className="fa-solid fa-sliders-h"></i>
            </span>
          </button>
        </div>
        {/* <!-- Advanced filter responsive toggler END --> */}
        <nav className="navbar navbar-expand-lg mx-0">
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasSideNavbar"
            aria-labelledby="offcanvasSideNavbarLabel"
          >
            {/* <!-- Offcanvas header --> */}
            <div className="offcanvas-header mb-2">
              <button
                type="button"
                className="btn-close text-reset ms-auto"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- Offcanvas body --> */}
            <div className="offcanvas-body d-block px-2 px-lg-0">
              {/* <!-- Card START --> */}
              <div className="card overflow-hidden">
                {/* <!-- Cover image --> */}
                <div className="h-50px"></div>
                {/* <!-- Card body START --> */}
                <div className="card card-body border-bottom-0 rounded-bottom-0">
                  <div className=" d-flex justify-content-between align-items-center">
                    <h1 className="h5 mb-0">
                      Active chats{" "}
                      <span className="badge bg-success bg-opacity-10 text-success">
                        {chatUsers.length}
                      </span>
                    </h1>
                  </div>
                </div>
                {/* <!-- Offcanvas header --> */}
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close text-reset ms-auto"
                    data-bs-dismiss="offcanvas"
                  ></button>
                </div>
                {/* <!-- Offcanvas body --> */}
                <div className="offcanvas-body p-0">
                  <div className="card card-chat-list card-body border-bottom-0 rounded-end-lg-0 border-end-lg-0 rounded-top-0  rounded-bottom-0">
                    {chatUsers && chatUsers.length > 0 && (
                      <ChatUserList
                        chatUsers={chatUsers}
                        setSelectedUser={setSelectedUser}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default ChatSidenav;
