const ItemAutoComplete = ({
  showOptions,
  filteredOptions,
  handleSelectOption,
}) => {
  const dropDownClass =
    showOptions && filteredOptions.length > 0 ? "show" : null;
  return (
    <ul className={`dropdown-menu ${dropDownClass} autocomplete-drodown`}>
      {filteredOptions.map((item) => {
        return (
          <li
            key={item.id}
            className="dropdown-item"
            onClick={() => handleSelectOption(item.option)}
          >
            {item.option}
          </li>
        );
      })}
    </ul>
  );
};

export default ItemAutoComplete;
