import DOMPurify from "dompurify";

export const sanitizeText = (text) => {
  // 1. HTML Entity Encoding

  // Remove hyperlinks
  text = text.replace(/<a.*?>.*?<\/a>/gi, "");

  text = text.replace(/[<>&"']/g, function (char) {
    return "&#" + char.charCodeAt(0) + ";";
  });

  // 2. Regular Expressions
  // Remove HTML tags and attributes
  text = text.replace(/<[^>]*>/g, "");

  // Remove script tags
  text = text.replace(/<script.*?>.*?<\/script>/gi, "");

  // console.log(text);
  // Remove event handlers
  text = text.replace(/on[a-zA-Z]+\s*=\s*.*?/gi, "");

  // 3. Optional: Whitelisting (if needed)
  // You can add whitelisting logic here if you want to allow specific tags or attributes

  return text;
};

export const purifyHtml = ({ html }) => {
  const cleanHtml = DOMPurify.sanitize(html);
  return cleanHtml;
  // return <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
};
