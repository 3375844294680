import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSignupReqs } from "../../api/authApis";
import { useNavigate } from "react-router-dom";
import { formatDatemmdd } from "../helpers/formatDateTime";
import {
  setItem,
  setActiveComponentsDashboard,
} from "../../redux/slices/dashboardSlice";

const ReqRow = ({ req }) => (
  <div className="row align-items-center">
    <div className="col-3">
      <span className="font-responsive">{formatDatemmdd(req.event_date)}</span>
    </div>
    <div className="col-5">
      <span className="font-responsive">{req.item}</span>
    </div>
    <div className="col-4">
      <span className="font-responsive">
        {req.quantity} {req.unit_measure}
      </span>
    </div>
  </div>
);

const SignupCard = ({
  foodReqs,
  suppliesReqs,
  volunteerReqs,
  redirectToSignup,
  disableSignup,
}) => {
  return (
    <div className="card card-body shadow mt-2">
      <h5 className="card-title">
        <button
          className="btn btn-responsive w-100"
          onClick={redirectToSignup}
          disabled={disableSignup}
        >
          Signup Here
        </button>
      </h5>
      <div className="p-2">
        {foodReqs.length > 0 && (
          <div className="mt-1">
            <div className="h6 app-text-accent1">
              The organizer has requested for the following:
            </div>
            <div className="h6 fw-bold-600">Food</div>
            {foodReqs.map((req) => (
              <ReqRow key={req.id} req={req} />
            ))}
          </div>
        )}
        {suppliesReqs.length > 0 && (
          <div className="mt-1">
            <div className="h6">Supplies</div>
            {suppliesReqs.map((req) => (
              <ReqRow key={req.id} req={req} />
            ))}
          </div>
        )}
        {volunteerReqs.length > 0 && (
          <div className="mt-1">
            <div className="h6">Volunteers</div>
            {volunteerReqs.map((req) => (
              <ReqRow key={req.id} req={req} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const SignupReqs = ({ item, disableSignup }) => {
  const eventId = item.id;
  // const { disable_signup } = item.event_details;
  // console.log(item.event_details);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );
  const eventsTabActive = useSelector(
    (state) => state.eventsTab.eventsTabActive
  );

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign(
    {},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
      [k]: false,
    }))
  );

  const [signupReqs, setSignupReqs] = useState([]);
  const [foodReqs, setFoodReqs] = useState([]);
  const [suppliesReqs, setSuppliesReqs] = useState([]);
  const [volunteerReqs, setVolunteerReqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [signupReqMsg, setSignupReqMsg] = useState("");

  // Define your function outside the component or memoize it with useCallback
  const redirectToSignup = useCallback(() => {
    dispatch(setItem(item));
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        signup: true,
      })
    );
    navigate("/dashboard");
  }, []);

  // Get all the suppSignup requests for the event
  const fetchSignupReqs = async () => {
    const reqkey = { eventId: eventId };
    try {
      const { data } = await getSignupReqs(reqkey);
      setSignupReqs(data.signupReqs);
      data.signupReqs.length > 0
        ? setSignupReqMsg("")
        : setSignupReqMsg("There are no Sign Up requests for this event");

      setFoodReqs(
        data.signupReqs.filter((req) => {
          return req.category === "Food";
        })
      );

      setSuppliesReqs(
        data.signupReqs.filter((req) => {
          return req.category === "Supplies";
        })
      );

      setVolunteerReqs(
        data.signupReqs.filter((req) => {
          return req.category === "Volunteers";
        })
      );
    } catch (error) {
      console.log(error.message);
      setError(error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSignupReqs().finally(() => setLoading(false));
  }, [eventId]);

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return <div>Oops Something went wrong! We're on it.</div>;
  }

  if (signupReqs.length === 0) {
    return (
      <div className="card card-body mt-2">
        <div className="card-title">
          <h6 className="btn btn-responsive w-100">Signup Requests</h6>
        </div>
        <h6>{signupReqMsg}</h6>
      </div>
    );
  }

  return (
    <SignupCard
      foodReqs={foodReqs}
      suppliesReqs={suppliesReqs}
      volunteerReqs={volunteerReqs}
      redirectToSignup={redirectToSignup}
      disableSignup={disableSignup}
    />
  );
};

export default SignupReqs;
