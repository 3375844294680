import authHub from "./authHub";

export async function addSignupResponse(signupResponseData) {
  return await authHub.post("/signupRes", signupResponseData);
}

export async function updateSignupResponse(signupResponseData) {
  return await authHub.put("/signupRes", signupResponseData);
}

export async function cancelSignup(signupResponseData) {
  return await authHub.put("/cancelSignup", signupResponseData);
}

export async function getSignupReqsResByUser(reqkey) {
  return await authHub.get("/signupReqsResByUser", {
    params: reqkey,
  });
}

export async function getSponsorshipResp(reqkey) {
  return await authHub.get("/sponsorshipsByUser", {
    params: reqkey,
  });
}

export async function deleteSponsorshipResp(id) {
  return await authHub.delete(`/sponsorshipResp/${id}`);
}

export async function getMySignups() {
  return await authHub.get("/mySignups");
}

export async function getEventSignups(reqkey) {
  return await authHub.get("/eventSignups", { params: reqkey });
}

export async function getEventSignupSummary(reqkey) {
  return await authHub.get("/eventSignupSummary", { params: reqkey });
}

export async function getEventSponsors(reqkey) {
  return await authHub.get("/eventSponsors", { params: reqkey });
}

export async function getMySponsorships() {
  return await authHub.get("/mySponsorships");
}
