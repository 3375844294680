// Validate Event Form - Step 1
export const validateChatMessage = (values) => {
  const errors = validateMessage({}, values)
  return errors;
};


// validate event name
function validateMessage(errors = {}, values) {
  if (!values.message_text) {
    errors.message_text = 'Message cannot be blank';
  } else if (values.message_text.length > 254) {
    errors.message_text = 'Message has to be less than 255 characters';
  }
  return errors;
}