import { BsTrash, BsPencil } from "react-icons/bs";

const SignupRequestsTable = ({
  sectionHeading,
  columnHeaders,
  signupReqs,
  removeSignupReq,
  loadSignupReq,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <h6 className="card-subtitle">{sectionHeading}</h6>
        <div>
          <table className="table table-borderless table-hover mt-2">
            <thead>
              <tr>
                {columnHeaders.map((header, index) => (
                  <th key={index} scope="col" className="row-text-sm">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {signupReqs &&
                signupReqs.map((req) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={req.id}
                    className="table-row"
                  >
                    <th scope="row" className="align-middle app-fs-7">
                      {req.item}
                    </th>
                    <td className="align-middle app-fs-7">{req.quantity}</td>
                    <td>
                      <button
                        className="del-icon me-2"
                        onClick={() => removeSignupReq(req.id)}
                      >
                        <BsTrash />
                      </button>
                      <button
                        className="del-icon"
                        onClick={() => loadSignupReq(req.id)}
                      >
                        <BsPencil />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SignupRequestsTable;
