import { useCallback, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { getDraftEvents, deleteEvent } from "../../../api/authApis";
import {
  formatDate,
  formatDateyyyymmdd,
  convertTime,
} from "../../helpers/formatDateTime";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponentsDashboard } from "../../../redux/slices/dashboardSlice";
import {
  setCreateStep,
  setEventData,
} from "../../../redux/slices/eventCreateSlice";

import { setAuthenticated } from "../../../redux/slices/authSlice";

const EventDrafts = () => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const activeComponentsDashboard = useSelector(
    (state) => state.dashboard.activeComponentsDashboard
  );

  const activeComponentsDashboardNew = useMemo(() => {
    return Object.assign(
      {},
      ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({
        [k]: false,
      }))
    );
  }, [activeComponentsDashboard]);

  const setActiveComponent = (componentName) => {
    dispatch(
      setActiveComponentsDashboard({
        ...activeComponentsDashboardNew,
        [componentName]: true,
      })
    );
  };

  const handleReviewClick = (item) => {
    // console.log("handleReviewClick", item);
    const itemWithFormattedDate = {
      ...item,
      from_date: item.from_date ? item.from_date.toString().split("T")[0] : "",
      to_date: item.to_date ? item.to_date.toString().split("T")[0] : "",
    };
    dispatch(setEventData(itemWithFormattedDate));
    setActiveComponent("eventCreate");
  };

  const handleDeleteClick = async (item) => {
    // console.log("handleDeleteClick", item);
    try {
      const response = await deleteEvent(item.id);
      // console.log(response);

      if (response.status === 204) {
        // Check if the delete request was successful
        // Remove the event from the list
        setEvents(events.filter((event) => event.id !== item.id));
        dispatch(setEventData(null));
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  // Get all the events for the user
  const fetchEvents = async () => {
    // console.log("fetchEvents");
    try {
      const { data, status } = await getDraftEvents();
      // console.log(data);
      if (data && data.rowCount > 0) {
        setEvents(data.events);
      } else {
        setEvents([]);
      }
    } catch (error) {
      const status = error.response?.status;
      if (status === 401) {
        dispatch(setAuthenticated(false));
        localStorage.removeItem("isAuth");
      }
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchEvents().finally(() => setLoading(false));
  }, []);

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error state
  if (error) {
    return <div>Oops Something went wrong! We're on it.</div>;
  }

  return (
    <div className="row g-4">
      <div className="col-sm-12 col-xl-12">
        {/* <!-- Event item START --> */}
        <div className="card border-0 d-sm-flex">
          <div className="card-header justify-content-between align-items-center">
            Draft Events
          </div>
          {events && events.length > 0 ? (
            events.map((event) => {
              return (
                <div key={event?.id} className="card-body">
                  <div className="row border-0 shadow p-1">
                    <span>{event?.event_name}</span>
                    <div>
                      <span className="fw-bold app-fs-9 mr-2">
                        Date & Time:{" "}
                      </span>
                      <span className="app-fs-9 mr-2">
                        {event?.from_date === event?.to_date
                          ? formatDate(event?.from_date)
                          : formatDate(event?.from_date) +
                            " - " +
                            formatDate(event?.to_date)}
                      </span>
                      &nbsp;{" "}
                      <span className="app-fs-9">
                        {convertTime(event?.from_time) +
                          " - " +
                          convertTime(event?.to_time)}
                      </span>
                    </div>
                    <div>
                      <span className="fw-bold app-fs-9 mr-2">
                        Organized by:{" "}
                      </span>
                      <span className="app-fs-9">{event?.organized_by} </span>
                    </div>
                    <div>
                      <span className="fw-bold app-fs-9 mr-2">Location: </span>
                      <span className="app-fs-9">{event?.location} </span>
                    </div>
                    <div className="d-flex action-buttons my-2">
                      <button
                        type="button"
                        title="Review and Publish"
                        className="btn btn-responsive me-1 pt-1"
                        onClick={() => handleReviewClick(event)}
                      >
                        Review and Publish
                      </button>
                      <button
                        type="button"
                        title="Delete Draft"
                        className="btn btn-responsive me-1 pt-1"
                        onClick={() => handleDeleteClick(event)}
                      >
                        Delete
                      </button>
                    </div>
                    {/* <a href="action-link.html" className="btn btn-primary">Action</a> */}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="card-body">
              <div className="row border-0">
                <div className="col-sm-12 col-xl-12">
                  <div className="card-subheader py-3 alert alert-info w-75">
                    Looks like you don't have any drafts saved. Click on the
                    <span className="text-primary"> Create Event </span>
                    link on the left navigation to get started. The wizard will
                    guide you through the process. You will be able to save the
                    draft and come back to it later.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <!-- Event item END --> */}
      </div>
    </div>
  );
};

export default EventDrafts;
