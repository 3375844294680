import { useState, useEffect } from "react";
import { addChatMessage } from "../../api/chatApis";
import { validateChatMessage } from "../helpers/validateChatMessage";
import { sanitizeText } from "../helpers/sanitize";
import DOMPurify from "dompurify";

const ChatReply = ({ selectedUser, setRefreshMsgs }) => {
  const receiver_user_id = selectedUser.id;

  const initializeFormData = {
    receiver_user_id: receiver_user_id,
    message_text: "",
  };

  const [formData, setFormData] = useState(initializeFormData);
  const [error, setError] = useState(null); // error for form submission
  const [validationErrors, setValidationErrors] = useState({});

  // Update formData whenever selectedUser changes
  useEffect(() => {
    setFormData({
      receiver_user_id: selectedUser.id,
      message_text: "",
    });
  }, [selectedUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationErrors(null);
    const cleanText = DOMPurify.sanitize(sanitizeText(formData.message_text));
    setFormData((prevValues) => ({
      ...prevValues,
      message_text: cleanText,
    }));
    // console.log("formData", formData);
    try {
      const errors = validateChatMessage(formData);
      if (Object.keys(errors).length === 0) {
        const { data, status } = await addChatMessage(formData);
        if (status === 201) {
          setRefreshMsgs(true);
          setFormData(initializeFormData);
        }
      } else {
        setValidationErrors(errors);
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="row w-75">
      <form onSubmit={(e) => handleSubmit(e)} className="form-wrapper-gen">
        <div className="row">
          <label htmlFor="message_text" className="col-form-label fl-fs-8">
            Message:
          </label>
          <div className="col-md-12">
            <textarea
              rows="3"
              className="form-control form-input fc-fs-7"
              id="message_text"
              name="message_text"
              value={formData.message_text}
              onChange={handleInputChange}
            />
          </div>
          <div id="itemHelp" className="form-text help-text-xs">
            Maximum 255 characters.
          </div>
          {validationErrors?.message_text && (
            <div className="app-text-error-xs">
              {validationErrors.message_text}
            </div>
          )}
          <div className="d-flex col-md-12 mt-3 action-buttons">
            <button type="submit" className="btn btn-responsive mx-2">
              Message {selectedUser?.username}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatReply;
