import useImageResize from "../../hooks/useImageResize";
import { useState } from "react";

const EventCreateStep4 = ({
  values,
  onChange,
  handleContinueClick,
  handlePrevClick,
}) => {
  const {
    resizedImage,
    uploadError,
    resizeError,
    getResizedImage,
    uploadResizedImage,
  } = useImageResize();
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [sizeError, setSizeError] = useState("");

  const handleFileChange = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);

      if (file?.size > 4718592) {
        // alert('File size exceeds the maximum allowed limit (1 MB).');
        setSizeError("File size exceeds the maximum allowed limit (4 MB).");
        return;
      } else {
        setSizeError("");
      }

      let newFilename;
      if (
        values.pic_filename === "" ||
        values.pic_filename.includes("Default-")
      ) {
        // Generate a random 6-digit number
        const randomNumber = Math.floor(100000 + Math.random() * 900000);
        // Get the file extension
        const fileExtension = file.name.split(".").pop();
        // Create the new filename
        newFilename = `${randomNumber}.${fileExtension}`;
      } else {
        newFilename = values.pic_filename;
      }
      await getResizedImage(file, newFilename, 1344, 600);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    // console.log("step4 - imageURL", values.imageUrl);
    // console.log("step4 - resizedImage", resizedImage);

    let newFilename;
    if (
      values.pic_filename === "" ||
      values.pic_filename.includes("Default-")
    ) {
      // Generate a random 6-digit number
      const randomNumber = Math.floor(100000 + Math.random() * 900000);
      // Get the file extension
      const fileExtension = selectedFile.name.split(".").pop();
      // Create the new filename
      newFilename = `${randomNumber}.${fileExtension}`;
    } else {
      newFilename = values.pic_filename;
    }

    // Call the upload function with the selected file, desired filename, maxWidth, and optionally maxHeight
    const success = await uploadResizedImage(
      // selectedFile,
      resizedImage,
      newFilename,
      1344 /* maxHeight (optional) */
    );
    values.pic_filename = newFilename;
    values.defaultImage = false;
    return success;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (resizedImage) {
        const success = await handleImageUpload();
        if (success) {
          setError("Upload successful!");
        } else {
          setError(`Upload failed: ${uploadError}`);
          return;
        }
      }
      handleContinueClick(e);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card">
      <div className="card-header d-flex pb-2 justify-content-between align-items-center">
        <div>Create Event - Upload Picture</div>
        <div>Step 4 of 4</div>
      </div>

      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row form-wrapper-gen w-75">
            <div className="col-md-4 action-buttons">
              <label htmlFor="upload" className="btn app-btn-secondary fl-fs-9">
                Choose Image
              </label>
              <div className="mb-2">
                <input
                  type="file"
                  id="upload"
                  className="file-input"
                  onChange={handleFileChange}
                  accept="image/gif, image/jpeg, image/png"
                />
                <img
                  id="previewImage"
                  src={resizedImage || values.imageUrl}
                  className="img-thumbnail mt-2"
                  style={{ maxWidth: "150px" }}
                  alt="Uploaded"
                />
                <div
                  id="imgSizeHelp"
                  className="form-text"
                  style={{ color: "#ff9800", margin: "10px 0" }}
                >
                  {sizeError}
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="mb-2">
                <label htmlFor="pic_caption" className="form-label fl-fs-9">
                  Picture Caption:
                </label>
                <input
                  onChange={onChange}
                  type="text"
                  maxLength="200"
                  id="pic_caption"
                  name="pic_caption"
                  value={values.pic_caption}
                  className="form-control form-input app-fc-8"
                />
                <div id="picCaptionHelp" className="form-text help-text-xs">
                  Caption will overlay the image
                </div>
              </div>
            </div>

            <div className="col-md-12">
              {values?.defaultImage && (
                <div style={{ color: "gray", margin: "10px 0" }}>
                  If you don't upload your own image, a default image will be
                  displayed on the preview page. You can still see a preview of
                  your event on the next page before it is published.
                </div>
              )}
            </div>

            <div className="col-md-12">
              <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
            </div>

            <div className="col-md-12 d-flex pb-2 justify-content-between align-items-center action-buttons mt-3">
              <button
                type="button"
                onClick={handlePrevClick}
                className="btn btn-responsive me-3"
              >
                Prev
              </button>
              <button type="submit" className="btn btn-responsive">
                Save and Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventCreateStep4;
