import "../../assets/styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { getOrgList, registerUser } from "../../api/authApis";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setActiveComponentsLogin,
  setActiveComponentsRegister,
} from "../../redux/slices/authSlice";
import { validateRegistration } from "../helpers/validateRegistrationForm";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = useSelector((state) => state.auth.email);
  const activeComponentsRegister = useSelector(
    (state) => state.auth.activeComponentsRegister
  );
  const activeComponentsLogin = useSelector(
    (state) => state.auth.activeComponentsLogin
  );

  // Make all the components active = false
  let activeComponentsRegisterNew = Object.assign(
    {},
    ...Object.entries(activeComponentsRegister).map(([k, v]) => ({
      [k]: false,
    }))
  );

  let activeComponentsLoginNew = Object.assign(
    {},
    ...Object.entries(activeComponentsLogin).map(([k, v]) => ({ [k]: false }))
  );

  const initializeFormData = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    organization: "",
    password: "",
    confirmPassword: "",
    termsCheck: false,
  };

  const [values, setValues] = useState(initializeFormData);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [errors, setErrors] = useState({});

  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const showLogin = async (e) => {
    e.preventDefault();
    dispatch(
      setActiveComponentsLogin({ ...activeComponentsLoginNew, login: true })
    );
    navigate("/login");
  };

  const fetchOrgList = async () => {
    // console.log('fetchStatesList')
    try {
      const { data } = await getOrgList();
      setOrgList(data.orgList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOrgList();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef = useRef(null);

  const filteredOptions = orgList.filter(
    (org) =>
      org.org_name
        .toString()
        .toLowerCase()
        .startsWith(values.organization.toLowerCase())
    // values.organization.length >= 3 ? org.org_name.toString().toLowerCase().startsWith(values.organization.toLowerCase()) : null
  );

  const handleSelectOption = (orgName) => {
    setSelectedOption(orgName);
    Object.assign(values, { organization: orgName });
    setShowOptions(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      setValues({ ...values, [name]: formatPhoneNumber(value) });
    } else {
      setValues({ ...values, [name]: value });
    }
    if (name === "organization") {
      setSelectedOption(null); // Reset selected option on input change
      setShowOptions(value.length >= 3);
    }
  };

  const renderOrgDropdown = () => {
    const dropDownClass =
      showOptions && filteredOptions.length > 0 ? "show" : null;
    // console.log(showOptions, dropDownClass)
    return (
      <ul className={`dropdown-menu ${dropDownClass} autocomplete-drodown`}>
        {filteredOptions.map((org) => {
          return (
            <li
              key={org.id}
              className="dropdown-item"
              onClick={() => handleSelectOption(org.org_name)}
            >
              {org.org_name}
            </li>
          );
        })}
      </ul>
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    // console.log(values);
    try {
      const errors = validateRegistration(values);
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      if (values.organization === "") {
        values.organization = "Individual";
      }
      const { data, status } = await registerUser(values);
      // console.log(data, status);
      if (status === 201) {
        // console.log(data.emailSent);
        setStatus(
          data.emailSent
            ? "Please enter the OTP code sent to your email address to complete the registration process"
            : "There has been a problem sending the OTP code. Please click the resend button to receive the OTP code"
        );
        dispatch(setEmail(data.user.email));
        dispatch(
          setActiveComponentsRegister({
            ...activeComponentsRegisterNew,
            verifyOTP: true,
          })
        );
        setValues(initializeFormData);
        setError("");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.error);
        setStatus("");
      } else if (error.response && error.response.status === 500) {
        setError(
          error.response && error.response.data
            ? error.response.data.error
            : "Oops! Something went wrong. Please try again later."
        );
        setStatus("");
      }
    }
  };

  return (
    <div className="form-container">
      <div className="form-wrapper-register">
        <h4 className="text-center">Register</h4>
        <form onSubmit={(e) => onSubmit(e)} className="g-3">
          <div className="row g-2">
            <div className="col-md-6">
              <label htmlFor="first_name" className="form-label app-fs-9">
                First Name<span style={{ color: "red" }}>*</span>
              </label>
              <div className="input-group">
                <input
                  onChange={(e) => onChange(e)}
                  type="text"
                  className="form-control form-input"
                  id="first_name"
                  name="first_name"
                  value={values.first_name}
                  aria-describedby="first_name"
                  required
                />
                {errors?.first_name && (
                  <div className="app-text-error-xs">{errors?.first_name}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="last_name" className="form-label app-fs-9">
                Last Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="text"
                className="form-control form-input"
                id="last_name"
                name="last_name"
                value={values.last_name}
                required
              />
              {errors?.last_name && (
                <div className="app-text-error-xs">{errors?.last_name}</div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="email" className="form-label app-fs-9">
                Email Address<span style={{ color: "red" }}>*</span>
              </label>
              <div className="input-group">
                <input
                  onChange={(e) => onChange(e)}
                  type="email"
                  className="form-control form-input"
                  id="email"
                  name="email"
                  value={values.email}
                  aria-describedby="email"
                  required
                />
                {errors?.email && (
                  <div className="app-text-error-xs">{errors?.email}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label app-fs-9">
                Phone
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="tel"
                className="form-control form-input hide-placeholder"
                id="phone"
                name="phone"
                value={values.phone}
                placeholder="123-456-7890"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              />
            </div>
            <div className="col-md-12">
              <label htmlFor="organization" className="form-label app-fs-9">
                Organization
              </label>
              <div className="dropdown" ref={dropdownRef}>
                <input
                  onChange={(e) => onChange(e)}
                  type="text"
                  className="form-control form-input hide-placeholder"
                  id="organization"
                  name="organization"
                  value={values.organization}
                  placeholder="eg: Individual"
                />
                {renderOrgDropdown()}
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="password" className="form-label app-fs-9">
                Password<span style={{ color: "red" }}>*</span>
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="password"
                className="form-control form-input"
                id="password"
                name="password"
                value={values.password}
                required
              />
              {errors?.password && (
                <div className="app-text-error-xs">{errors?.password}</div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="confirmPassword" className="form-label app-fs-9">
                Confirm Password<span style={{ color: "red" }}>*</span>
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="password"
                className="form-control form-input"
                id="confirmPassword"
                name="confirmPassword"
                value={values.confirmPassword}
                required
              />
              {errors?.confirmPassword && (
                <div className="app-text-error-xs">
                  {errors?.confirmPassword}
                </div>
              )}
            </div>
            <div className="col-12">
              <div className="form-check">
                <input
                  onChange={(e) => onChange(e)}
                  className="form-check-input app-border-color"
                  type="checkbox"
                  value={values.termsCheck}
                  id="termsCheck"
                  required
                />
                <label className="form-check-label" htmlFor="termsCheck">
                  By signing up, you agree to the{" "}
                  <Link to="/terms">terms of service</Link>
                </label>
              </div>
            </div>
            <div className="col-12 action-buttons">
              <div className="app-fs-8 error-message">{error}</div>
              <div className="app-fs-8 status-message">{status}</div>
              <button className="btn app-btn-primary w-100" type="submit">
                Register
              </button>
            </div>
          </div>
        </form>
        <div className="text-center">
          <span className="link-btn">
            Already a Member? <Link onClick={showLogin}>Login Here</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Register;
