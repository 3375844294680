const FormInput = ({
  id,
  name,
  type,
  value,
  label,
  onChange,
  error,
  placeholder,
  maxLength,
  colSpan,
  required,
}) => {
  return (
    <div className={`${colSpan} mb-2`}>
      <label htmlFor={id} className="form-label fl-fs-9">
        {label}
        {required ? <span style={{ color: "red" }}>*</span> : null}
      </label>
      <input
        onChange={onChange}
        type={type}
        id={id}
        name={name}
        value={value}
        className="form-control form-input fc-fs-8"
        maxLength={maxLength}
        placeholder={placeholder}
        required={required}
      />
      {error && <div className="app-text-error-xs">{error}</div>}
    </div>
  );
};

export default FormInput;
