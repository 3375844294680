import { useState } from "react";
import FormInput from "../../formComponents/formInput";
import FormSelect from "../../formComponents/formSelect";
import { validateStep1 } from "../../helpers/validateEventForm";

const EventCreateStep1 = ({
  values,
  onChange,
  eventCategories,
  handleContinueClick,
  handleCancelClick,
}) => {
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    validationErrors = validateStep1(values);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log(validationErrors);
      return;
    }
    handleContinueClick(e);
  };

  return (
    <div className="card mb-4">
      <div className="card-header d-flex pb-2 justify-content-between align-items-center">
        <div>Create Event - Event Details</div>
        <div>Step 1 of 4</div>
      </div>

      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row form-wrapper-gen w-75">
            <FormInput
              id="event_name"
              name="event_name"
              label="Event Name"
              type="text"
              maxLength="50"
              value={values.event_name}
              onChange={onChange}
              error={errors?.event_name}
              placeholder="Event Name"
              colSpan="col-md-12"
              required
            />

            <FormInput
              id="organized_by"
              name="organized_by"
              label="Organized By"
              type="text"
              maxLength="100"
              value={values.organized_by}
              onChange={onChange}
              error={errors?.organized_by}
              colSpan="col-md-6"
              required
            />

            <FormSelect
              id="event_type"
              name="event_type"
              label="Event Type"
              value={values.event_type}
              onChange={onChange}
              options={[
                { id: "In person", name: "In person" },
                { id: "Virtual", name: "Virtual" },
              ]}
              error={errors?.event_type}
              colSpan="col-md-3"
              required
            />

            <FormSelect
              id="category_id"
              name="category_id"
              label="Category"
              value={values.category_id}
              onChange={onChange}
              options={eventCategories}
              error={errors?.category_id}
              colSpan="col-md-3"
              required
            />

            <FormInput
              id="from_date"
              name="from_date"
              label="From Date"
              type="date"
              value={values.from_date}
              onChange={onChange}
              error={errors?.from_date}
              colSpan="col-md-3"
              required
            />

            <FormInput
              id="from_time"
              name="from_time"
              label="From Time"
              type="time"
              value={values.from_time}
              onChange={onChange}
              error={errors?.from_time}
              colSpan="col-md-3"
              required
            />

            <FormInput
              id="to_date"
              name="to_date"
              label="To Date"
              type="date"
              value={values.to_date}
              onChange={onChange}
              error={errors?.to_date}
              colSpan="col-md-3"
              required
            />

            <FormInput
              id="to_time"
              name="to_time"
              label="To Time"
              type="time"
              value={values.to_time}
              onChange={onChange}
              error={errors?.to_time}
              colSpan="col-md-3"
              required
            />

            <div className="col-md-12 mt-2 d-flex">
              <label htmlFor="is_private" className="form-label fl-fs-9 me-2">
                Public or Private event?<span style={{ color: "red" }}>*</span>
              </label>
              <div className="form-check fc-fs-8 me-2 ">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_private"
                  id="public"
                  value="public"
                  checked={!values.is_private} // This will be true (checked) when is_private is false
                  onChange={(e) => onChange(e)}
                />
                <label className="form-check-label fl-fs-9" htmlFor="public">
                  Public
                </label>
              </div>
              <div className="form-check fc-fs-8">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_private"
                  id="private"
                  value="private"
                  checked={values.is_private} // This will be true (checked) when is_private is true
                  onChange={(e) => onChange(e)}
                />
                <label className="form-check-label fl-fs-9" htmlFor="private">
                  Private
                </label>
              </div>
            </div>
            <div>
              {" "}
              {values.is_private === "true" && (
                <span className="text-danger fl-fs-8" p>
                  Anyone with the link can still access the event.
                </span>
              )}
            </div>
            <div className="col-md-12">
              <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
            </div>
            <div className="col-md-12 d-flex pb-2 justify-content-between align-items-center action-buttons mt-3">
              <button
                type="button"
                className="btn btn-responsive me-3"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-responsive">
                Save and Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventCreateStep1;
