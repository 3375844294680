import '../../assets/styles/login.css';
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
// import { resetPasswordValidate } from "../helpers/validate";
import { passwordReset } from "../../api/authApis";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveComponentsDashboard } from "../../redux/slices/dashboardSlice";

const UpdatePassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const activeComponentsDashboard = useSelector((state) => state.dashboard.activeComponentsDashboard);

  const [values, setValues] = useState({
    passwordCurrent: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(false);

  // Make all the components active = false
  let activeComponentsDashboardNew = Object.assign({},
    ...Object.entries(activeComponentsDashboard).map(([k, v]) => ({ [k]: false }))
  );

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(values);
    try {
      // const errors = await resetPasswordValidate(values);
      // if (Object.keys(errors).length > 0) {
      //   setError(errors.password);
      //   return;
      // }
      const { data, status } = await passwordReset(values);
      // console.log(status)
      if (status === 204) {
        dispatch(setActiveComponentsDashboard({ ...activeComponentsDashboardNew, "profile": true }));
      }
    } catch (error) {
      console.log(error.response.data);
      setError(error.response.data.error);
    }
  };

  return (
    <div className="card">
      <div className="card-header justify-content-between align-items-center">
        {/* <h6 className="card-subtitle text-muted">Profile Details</h6> */}
        <span>Reset Password</span>
      </div>
      <form className="row g-3">
        <div className="card-body">
          <div className="row form-wrapper-gen w-50">
            <div className="mb-1 col-md-12 col-sm-12">
              <div className="mb-1">
                <label className="form-label fl-fs-9" htmlFor="first_name">New Password</label>
                <input type="password" onChange={(e) => onChange(e)} value={values.password} id="password" name="password" className="form-control form-input fc-fs-8" />
              </div>
            </div>
            <div className="mb-1 col-md-12 col-sm-12">
              <div className="mb-1">
                <label className="form-label fl-fs-9" htmlFor="first_name">Confirm Password</label>
                <input type="password" onChange={(e) => onChange(e)} value={values.confirmPassword} id="confirmPassword" name="confirmPassword" className="form-control form-input fc-fs-8" />
              </div>
            </div>
            <div className="my-3 col-md-12 col-sm-12 action-buttons">
              <div className="app-fs-7" style={{ color: "red" }}>{error}</div>
              <button type="button" onClick={(e) => onSubmit(e)} className="btn app-btn-primary-md">
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdatePassword;
