import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RestrictedRoutes = () => {

  const auth = useSelector((state) => state.auth);
  return <>{!auth.isAuthenticated ? <Outlet /> : <Navigate to="/dashboard" />} </>;
};

export default RestrictedRoutes;
