// Validate Login Form
export const validateLogin = (values) => {
  const errors = emailValidate({}, values);
  passwordValidate(errors, values);
  return errors;
};

// Validate Password
export const validatePassword = (values) => {
  const errors = passwordValidate({}, values);
  confPasswordValidate(errors, values);
  return errors;
};

// validate email
function emailValidate(error = {}, values) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!values.email) {
    error.email = "Email is required";
  } else if (values.email.includes(" ")) {
    error.email = "Invalid email";
  } else if (!emailPattern.test(values.email)) {
    error.email = "Email format is incorrect";
  }
  return error;
}

// validate Password
function passwordValidate(errors = {}, values) {
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6 || values.password.length > 15) {
    errors.password = "Password must be between 6 and 15 chars long";
  }
  return errors;
}

// validate First name
function confPasswordValidate(errors = {}, values) {
  // console.log(values);
  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }
  return errors;
}
